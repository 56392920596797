//Standard libraries
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledBox, StyledCheckbox, StyledGrid, StyledTypography } from "../../../hdm";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { cartSelector, isFetchingSelector, orderItemsSelector } from "../../../redux/selectors/order";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";

import { checkOutStepRdc } from "../../../redux/selectors/hdmData";
import { CONSTANTS } from "../../../constants/order-item-table";
import FormattedPriceDisplay from "../formatted-price-display";
import EventIcon from "@material-ui/icons/Event";
import { DeliveryProductInfo } from "./DeliveryProductInfo";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useMemo } from "react";
import { StyledWrapper } from "../../StyledUI";
import { Skeleton } from "@material-ui/lab";
/**
 * Address card display component
 * displays the details of a single address
 * @param props
 */

const mesesAbreviados = {
  ene: "01",
  feb: "02",
  mar: "03",
  abr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  ago: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dic: "12",
};

const mesesAbreviadosLetras = {
  "01": "ene",
  "02": "feb",
  "03": "mar",
  "04": "abr",
  "05": "may",
  "06": "jun",
  "07": "jul",
  "08": "ago",
  "09": "sep",
  "10": "oct",
  "11": "nov",
  "12": "dic",
};

const EventIconContainer = styled.div`
  ${({ theme }) => `
  .deliveryInfoCard--icon-eventIcon{
    width: 15px;
    height: auto;
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.gray[200]};
  }
  .deliveryInfoCard-container{
    display: flex;
    align-items: center;
  }
`}
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
  ${({ theme, open }) => `
  svg {
    color: ${open ? theme.palette.gray[500] : theme.palette.gray[200]};
    width: 15px;
  }
`}
`;

const DeliveryInfoCard: React.FC<any> = (props: any) => {
  const orderItems = useSelector(orderItemsSelector);
  const stepActiveSelector = useSelector(checkOutStepRdc);
  const isFetching = useSelector(isFetchingSelector);
  const stepActiveProps = props.stepActive ? props.stepActive : 0;
  const cart = useSelector(cartSelector);
  const stepActive =
    stepActiveProps !== 0 ? stepActiveProps : stepActiveSelector ? parseInt(stepActiveSelector.currentStep) : 0;
  const { t } = useTranslation();
  const readOnlyShipping = props.readOnlyShipping ? props.readOnlyShipping : false;
  const [manualDeliveryDate, setManualDeliveryDate] = useState<boolean>(false);
  const [multipleDates, setMultipleDates] = useState<boolean>(false);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(new Date());
  const [pickerOpen, setPickerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  let totalProductsBopis = 0;
  let totalProductsDelivery = 0;
  let totalProducts = 0;
  const variant = props.variant ? props.variant : "checkout";
  const deliveryMethod = props.deliveryMethod;
  const deliveryDates = props.deliveryDates;
  let homeDeliveryOrders: any = [];
  const manualShippingDate = props?.manualShippingDate;
  const setManualShippingDate = props.setManualShippingDate ? props.setManualShippingDate : undefined;
  const oneTimeDeliveryOfMixedCart = props.oneTimeDeliveryOfMixedCart ? props.oneTimeDeliveryOfMixedCart : undefined;
  const setOneTimeDeliveryOfMixedCart = props.setOneTimeDeliveryOfMixedCart
    ? props.setOneTimeDeliveryOfMixedCart
    : undefined;
  let homeDeliveryDates: any = [];
  let homeDeliveryDatesTMP: any = [];
  let pickUpDates: any = [];
  let pickUpDatesSplit: any = [];
  let installationPartNumbers: any = [];
  let showManualDeliveryDate;

  const handleMultipleShipmentChange = () => {
    if (setOneTimeDeliveryOfMixedCart !== undefined) {
      setOneTimeDeliveryOfMixedCart(!oneTimeDeliveryOfMixedCart);
    }
  };

  const parseStringDate = (date, slash = false) => {
    const dateArray = date?.split(slash ? " / " : " ");
    const dateTMP = dateArray && dateArray[2] + "-" + CONSTANTS.numberMonths[dateArray[1]] + "-" + dateArray[0];
    return dateTMP;
  };

  const setManualDateToString = (value) => {
    const dd = String(value.getDate()).padStart(2, "0");
    const mm = String(value.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = value.getFullYear();
    const stringDate = yyyy + "-" + mm + "-" + dd;
    setManualShippingDate(stringDate);
  };

  //oneDeliveryDate Format
  const rawOneDeliveryDate = deliveryDates && deliveryDates.oneDeliveryDate?.split("T").slice(0, 1);
  const shortOneDeliveryDate = rawOneDeliveryDate && rawOneDeliveryDate.join().split("-");
  const day = shortOneDeliveryDate && shortOneDeliveryDate[2];
  const month = shortOneDeliveryDate && mesesAbreviadosLetras[shortOneDeliveryDate[1]];
  const year = shortOneDeliveryDate && shortOneDeliveryDate[0];
  const oneDeliveryDate = `${day} ${month} ${year}`;

  const oneDeliveryPicker =
    shortOneDeliveryDate &&
    shortOneDeliveryDate[2] + " / " + mesesAbreviadosLetras[shortOneDeliveryDate[1]] + " / " + shortOneDeliveryDate[0];

  const deliveryDatesCalendar = useMemo(() => {
    if (setManualShippingDate !== undefined) {
      if (deliveryDates?.fleetOrderAvailableDates && deliveryDates.fleetOrderAvailableDates.length > 0) {
        const deliveryDatesTMP = deliveryDates.fleetOrderAvailableDates.split(",");
        const startIndex = rawOneDeliveryDate && deliveryDatesTMP.indexOf(rawOneDeliveryDate[0]);
        const slicedArray = startIndex && startIndex > 0 ? deliveryDatesTMP.slice(startIndex) : deliveryDatesTMP;

        const noSundays = slicedArray.filter((dateStr) => {
          const date = new Date(dateStr);
          const dayOfWeek = date.getDay();
          return dayOfWeek !== 6;
        });

        let dateformat = "";
        if (manualShippingDate === "" && manualShippingDate !== undefined) {
          dateformat =
            deliveryDates && deliveryDates?.oneDeliveryDate?.length > 0 && oneDeliveryPicker
              ? parseStringDate(oneDeliveryPicker, true)
              : "";
        } else {
          dateformat = manualShippingDate;
        }
        if (dateformat !== "") {
          setSelectedDeliveryDate(new Date(dateformat + "T00:00:00"));
          if (!multipleDates) {
            setManualShippingDate(dateformat);
          } else {
            setManualShippingDate("");
          }
        }
        return noSundays;
      } else {
        setManualShippingDate("");
        return new Date();
      }
    }
  }, [deliveryDates]);

  if (orderItems && orderItems.length > 0) {
    for (let i = 0; i < orderItems.length; i++) {
      if (orderItems[i].carrier === CONSTANTS.bopis) {
        totalProductsBopis += 1;
      }

      if (orderItems[i].carrier === CONSTANTS.delivery) {
        totalProductsDelivery += 1;
      }
      totalProducts += parseInt(orderItems[i].quantity);
    }
  }
  const handleSelectDeliveryDate = (value) => {
    setSelectedDeliveryDate(value);
    if (homeDeliveryDates.length === 1) {
      setManualDateToString(value);
    }
  };

  useEffect(() => {
    if (homeDeliveryDates.length > 1) {
      setMultipleDates(true);
      if (setManualShippingDate) {
        setManualShippingDate("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeDeliveryDates]);

  const shipping =
    !oneTimeDeliveryOfMixedCart && variant === "checkout"
      ? (deliveryDates?.fmtDeliveryRanges && deliveryDates?.fmtDeliveryRanges.length > 1
          ? parseFloat(cart?.x_multiDelFrieghtCharge ? cart.x_multiDelFrieghtCharge : 0)
          : parseFloat(cart?.x_allFrieghtCharge ? cart.x_allFrieghtCharge : 0)) +
        (isFetching === false
          ? deliveryDates?.fmtDeliveryRanges && deliveryDates?.fmtDeliveryRanges.length > 1 && stepActive < 2
            ? parseFloat(cart?.totalShippingTax) * 2
            : parseFloat(cart?.totalShippingTax)
          : 0)
      : parseFloat(cart?.totalShippingCharge) + parseFloat(cart?.totalShippingTax);

  const type = props.type;

  const deliveryLabel =
    type === "bopis"
      ? props.isDrawer
        ? t("Shipping.Labels.ShippingDateBopisDrawer")
        : t("Shipping.Labels.ShippingDateBopis")
      : props.isDrawer
      ? t("Shipping.Labels.ShippingDateDeliveryDrawer")
      : t("Shipping.Labels.ShippingDateDelivery");

  if (!orderItems || orderItems.length === 0 || !deliveryDates || !deliveryDates?.oiFmtDeliveryDetails) return null;
  const allOrderItems = JSON.parse(JSON.stringify(orderItems));
  const oiFmtDeliveryDetails = deliveryDates.oiFmtDeliveryDetails;

  const instalacionesOrders = allOrderItems.filter((order) => order.partNumber.includes("SI"));

  if (instalacionesOrders && instalacionesOrders.length !== 0) {
    installationPartNumbers = instalacionesOrders.map((product) => product.partNumber.split("_")[2]);
  }

  allOrderItems.map((item) => {
    if (item.partNumber.includes("SI")) {
      return;
    }

    if (installationPartNumbers.includes(item.partNumber)) {
      item.x_hasInstallation = true;
    }
    let splitDeliveryDate = oiFmtDeliveryDetails[item.partNumber];

    if (splitDeliveryDate) {
      splitDeliveryDate = splitDeliveryDate.split("/");
    } else {
      return;
    }

    const deliveryDate_ymd = `${splitDeliveryDate[2].trim().substring(0, 4)}-${
      mesesAbreviados[splitDeliveryDate[1].trim()]
    }-${splitDeliveryDate[0].trim()}`;
    const customDate = new Date(deliveryDate_ymd);
    customDate.setHours(0);
    const hdm_deliveryDateFormat = `${splitDeliveryDate[0].trim()} ${splitDeliveryDate[1].trim()} ${splitDeliveryDate[2]
      .trim()
      .substring(0, 4)}`;
    item.hdm_deliveryDateSeconds = Math.floor(customDate.getTime() / 1000);
    item.hdm_deliveryDateFormat = hdm_deliveryDateFormat;
  });

  allOrderItems.sort((a, b) => a.hdm_deliveryDateSeconds - b.hdm_deliveryDateSeconds);

  allOrderItems.map((item) => {
    //deliveryRange Format
    const rawEstimatedDeliveryDate =
      deliveryDates && deliveryDates?.oiFmtDeliveryDetails && deliveryDates?.oiFmtDeliveryDetails[item?.partNumber]
        ? deliveryDates?.oiFmtDeliveryDetails[item?.partNumber].replace(/\//g, "")
        : "";
    let EstimatedDeliveryDate =
      deliveryDates && deliveryDates?.oiFmtDeliveryDetails && deliveryDates?.oiFmtDeliveryDetails[item?.partNumber]
        ? deliveryDates?.oiFmtDeliveryDetails[item?.partNumber].replace(/\//g, "")
        : "";

    if (rawEstimatedDeliveryDate && /-/g.test(rawEstimatedDeliveryDate)) {
      const firstDate = rawEstimatedDeliveryDate
        .split("-")[0]
        .split(" ")
        .filter((n) => n);
      const SecondDate = rawEstimatedDeliveryDate
        .split("-")[1]
        .split(" ")
        .filter((n) => n);

      // If the dates are the same day, month and year
      if (firstDate[0] === SecondDate[0] && firstDate[1] === SecondDate[1] && firstDate[2] === SecondDate[2]) {
        EstimatedDeliveryDate = firstDate.join(" ");
      }
      // If the dates are in the same month and same year
      else if (firstDate[1] === SecondDate[1] && firstDate[2] === SecondDate[2]) {
        EstimatedDeliveryDate = `${firstDate.slice(0, 1)} - ${SecondDate.join(" ")}`;
      }
      // If the dates are in the different months and same year
      else if (firstDate[1] !== SecondDate[1] && firstDate[2] === SecondDate[2]) {
        EstimatedDeliveryDate = `${firstDate.slice(0, 2).join(" ")} - ${SecondDate.join(" ")}`;
      }
      // If the dates are different  years
      else if (firstDate[2] !== SecondDate[2]) {
        EstimatedDeliveryDate = `${firstDate.slice(0, 3).join(" ")} - ${SecondDate.join(" ")}`;
      } else {
        EstimatedDeliveryDate = `${firstDate.slice(0, 2).join(" ")} - ${SecondDate.join(" ")}`;
      }
    }

    showManualDeliveryDate =
      deliveryDates && deliveryDates?.manualDeliveryDateDetails.length > 1
        ? deliveryDates?.manualDeliveryDateDetails[0].replace(/\//g, "")
        : "";

    item.hdm_deliveryDateFormat = showManualDeliveryDate ? showManualDeliveryDate : EstimatedDeliveryDate;

    //xitem_shopCartItemdate_BOPIS Format
    let shopCartItemdate_BOPIS;

    if (item && item?.xitem_shopCartItemdate_BOPIS && item?.shipModeCode === "PickupInStore"){
      const bopisDate = item && item?.xitem_shopCartItemdate_BOPIS !== "" && item?.xitem_shopCartItemdate_BOPIS?.split('_').pop().replace(/-/g, " ");
      const [startYear, startMonth, startDay] = bopisDate?.split(" ");
      shopCartItemdate_BOPIS = `${startDay} ${mesesAbreviadosLetras[startMonth]} ${startYear}`;
    }

    item.xitem_shopCartItemdate_BOPIS = item?.xitem_shopCartItemdate_BOPIS !== undefined ? shopCartItemdate_BOPIS : "";

    if (item.shipModeCode === "Mail" && !item.partNumber.includes("SI")) {
      if (oneTimeDeliveryOfMixedCart === true) {
        homeDeliveryDates.push(oneDeliveryDate);
      } else {
        homeDeliveryDates.push(item.hdm_deliveryDateFormat);
      }
      homeDeliveryDatesTMP.push(item.hdm_deliveryDateFormat);
    }
    if (item.shipModeCode === "PickupInStore" && !item.partNumber.includes("SI")) {
      pickUpDates.push(item.xitem_shopCartItemdate_BOPIS);
      pickUpDatesSplit.push(item.xitem_shopCartItemdate_BOPIS);
    }
    return true;
  });

  homeDeliveryDates = homeDeliveryDates.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  homeDeliveryDatesTMP = homeDeliveryDatesTMP.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  pickUpDates = pickUpDates.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  pickUpDatesSplit = pickUpDatesSplit.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  const pickUpStoreOrders = allOrderItems.filter(
    (order) => order.shipModeCode === "PickupInStore" && !order.partNumber.includes("SI")
  );
  homeDeliveryOrders = allOrderItems.filter(
    (order) => order.shipModeCode === "Mail" && !order.partNumber.includes("SI")
  );

  let isSplitOrder = false;

  if (totalProductsBopis > 0 && totalProductsDelivery > 0) {
    isSplitOrder = true;
    pickUpDates = pickUpDatesSplit;
  }
 
  const disableWeekends = (date, minDate) => {
    if (deliveryDatesCalendar.length > 0) {
      const dateParsed = parseStringDate(minDate);
      if (date < new Date(dateParsed + "T00:00:00")) {
        return true;
      }
      if (manualDeliveryDate === false) {
        setManualDeliveryDate(true);
      }
      const currentCalendarDate =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart("2", "0") +
        "-" +
        date.getDate().toString().padStart("2", "0");
      if (deliveryDatesCalendar.includes(currentCalendarDate)) {
        return false;
      }
    }
    return true;
  };

  const DeliveryDisplay = (props) => {
    const { date } = props;
    console.log("dateTT", date);
    return (
      <EventIconContainer>
        <StyledGrid xs={12} className={"deliveryInfoCard-container"}>
          <EventIcon className={"deliveryInfoCard--icon-eventIcon"} />
          <StyledTypography variant="bodyBaseline" component={"span"}>
            {deliveryLabel}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" color="primary" weight="semibold" component={"span"}>
            {" "}
            {showManualDeliveryDate ? showManualDeliveryDate : date}
          </StyledTypography>
        </StyledGrid>
      </EventIconContainer>
    );
  };

  const DisplayCheckout = (props) => {
    const { date } = props;
    console.log("dateTT", date);
    console.log("deliveryLabel", deliveryLabel);
    return (
      <EventIconContainer>
        <StyledGrid xs={12} className={"deliveryInfoCard-container"}>
          <EventIcon className={"deliveryInfoCard--icon-eventIcon"} />
          <StyledTypography variant="bodyBaseline" component={"span"}>
            {deliveryLabel}
          </StyledTypography>
          <Divider orientation="vertical" flexItem className="horizontal-margin-1" />
          <StyledTypography variant="bodyBaseline" color="primary" weight="semibold" component={"span"}>
            {date}
          </StyledTypography>
        </StyledGrid>
      </EventIconContainer>
    );
  };

  const getBopisDateFormat = (bopisSplitDate) => {
    const bopisSplitDatefirst = bopisSplitDate[0].split("-");
    let bopisSplitDateFormat = "";
    if (bopisSplitDate.length === 1) {
      bopisSplitDateFormat =
        bopisSplitDatefirst[2] + " " + mesesAbreviadosLetras[bopisSplitDatefirst[1]] + " " + bopisSplitDatefirst[0];
    } else {
      const bopisSplitDateSecond = bopisSplitDate[1].split("-");
      bopisSplitDateFormat =
        bopisSplitDatefirst[2] +
        " - " +
        bopisSplitDateSecond[2] +
        " " +
        mesesAbreviadosLetras[bopisSplitDateSecond[1]] +
        " " +
        bopisSplitDateSecond[0];
    }
    return bopisSplitDateFormat;
  };

  return (
    <StyledWrapper xs={12}>
      {readOnlyShipping && (
        <>
          {type !== "bopis" && stepActive === 1 && instalacionesOrders && instalacionesOrders.length > 0 && (
            <StyledTypography variant="bodySubText" className={"shipping--typography-shippingGray"}>
              Los servicios de instalación se realizarán en la dirección seleccionada. (EspotNombrePendiente)
            </StyledTypography>
          )}
          {type === "delivery" && (
            <>
              <StyledGrid xs={12} className={"top-margin-2 bottom-margin-2 display-inline"}>
                <StyledTypography weight="semibold" variant="bodyBaseline" component={"span"}>
                  {t("Shipping.Labels.ShippingCharge") + " "}
                </StyledTypography>
                {isFetching === false ? (
                  <>
                    {shipping === 0 ? (
                      <StyledTypography
                        variant="bodyBaseline"
                        component={"span"}
                        className={"deliveryInfoCard-styledTypography-highlight"}>
                        <>{t("Shipping.Labels.FreeShippingCharge")}</>
                      </StyledTypography>
                    ) : (
                      <StyledTypography variant="bodyBaseline" component={"span"}>
                        <FormattedPriceDisplay min={shipping} currency={cart?.totalShippingChargeCurrency} />
                      </StyledTypography>
                    )}
                  </>
                ) : (
                  <Skeleton width="50px" />
                )}
              </StyledGrid>
              {variant === "checkout" && stepActive === 1 && (
                <>
                  {deliveryDates?.oneDeliveryDate && homeDeliveryDatesTMP.length > 1 && (
                    <StyledGrid item xs={12} className={"bottom-margin-2 deliveryInfoCard-styledGrid-checkboxDelivery"}>
                      <StyledCheckbox
                        checked={oneTimeDeliveryOfMixedCart}
                        onChange={() => handleMultipleShipmentChange()}
                        color={"primary"}
                      />

                      <StyledTypography variant="bodyBaseline" color="secondary">
                        {t("Shipping.Labels.MultipleShippingSwitch") + " "}{" "}
                        {deliveryDates?.oneDeliveryDate && oneDeliveryDate}
                      </StyledTypography>
                    </StyledGrid>
                  )}
                </>
              )}
            </>
          )}
          {variant === "checkout" && (stepActive === 2 || stepActiveSelector.completedStep[1]) && (
            <>
              <Divider className="bottom-margin-2" />
              <StyledGrid className="bottom-margin-2">
                {type === "delivery" &&
                  homeDeliveryDates &&
                  homeDeliveryDates.map((delivery) => <DisplayCheckout date={delivery} />)}
                {type === "bopis" && pickUpDates && pickUpDates.map((pickUp) => <DisplayCheckout date={pickUp} />)}
              </StyledGrid>
            </>
          )}
          {variant === "orderResume" && type === "bopis" && (
            <StyledGrid xs={12} className={"deliveryInfoCard--styledGrid-flex bottom-margin-2 top-margin-2"}>
              <>
                {!isSplitOrder && pickUpDates.map((delivery) => <DeliveryDisplay date={delivery} />)}
                {isSplitOrder &&
                  pickUpDatesSplit &&
                  pickUpDatesSplit.length > 0 &&
                  pickUpDatesSplit.map((delivery) => <DeliveryDisplay date={delivery} />)}
              </>
            </StyledGrid>
          )}
          {variant === "orderResume" && type === "delivery" && (
            <StyledGrid xs={12} className={"deliveryInfoCard--styledGrid-flex bottom-margin-2 top-margin-2"}>
              {deliveryDates?.manualDeliveryDateDetails && deliveryDates.manualDeliveryDateDetails.length > 0 ? (
                <DeliveryDisplay date={oneDeliveryDate} />
              ) : (
                <>
                  {deliveryDates?.fmtOneDeliveryDate &&
                  deliveryDates?.fmtOneDeliveryDate !== "" &&
                  oneTimeDeliveryOfMixedCart === true ? (
                    <>
                      <DeliveryDisplay date={oneDeliveryDate} />
                    </>
                  ) : (
                    <>
                      {homeDeliveryDates.map((delivery) => (
                        <DeliveryDisplay date={delivery} />
                      ))}
                    </>
                  )}
                </>
              )}
            </StyledGrid>
          )}
          {((variant === "checkout" && stepActive === 1) || variant === "payment") && (
            <>
              {pickUpDates.length !== 0 && type === "bopis" ? (
                <>
                  {pickUpDates.map((date) => {
                    let productosDate = pickUpStoreOrders.filter((item) => item.xitem_shopCartItemdate_BOPIS === date);
                    if (isSplitOrder) {
                      productosDate = pickUpStoreOrders.filter((item) => item.xitem_shopCartItemdate_BOPIS === date);
                    }
                    let bopisSplitDateFormat = "";
                    if (productosDate && productosDate.length > 0 && isSplitOrder) {
                      const bopisSplitDate = productosDate[0]?.xitem_shopCartItemdate_BOPIS
                        ? productosDate[0].xitem_shopCartItemdate_BOPIS.split("_")
                        : [];
                      if (bopisSplitDate && bopisSplitDate.length > 0) {
                        bopisSplitDateFormat = bopisSplitDate;
                      }
                    }
                    return (
                      <StyledGrid xs={12} key={date}>
                        {console.log("dateTT", date)}
                        {console.log("isSplitOrder", isSplitOrder)}
                        {console.log("bopisSplitDateFormat", bopisSplitDateFormat)}
                        <StyledGrid>
                          <Divider />
                        </StyledGrid>
                        <StyledGrid xs={12} className={"deliveryInfoCard--styledGrid-flex top-margin-2"}>
                          <EventIcon className={"deliveryInfoCard--icon-eventIcon"} />
                          <StyledGrid xs={7} md={6}>
                            <StyledTypography variant="bodyBaseline" component={"span"}>
                              {deliveryLabel}
                            </StyledTypography>
                          </StyledGrid>
                          <Divider orientation="vertical" flexItem />
                          <StyledGrid xs={5} md={6} className={"left-padding-2"}>
                            <StyledTypography
                              variant="bodyBaseline"
                              color="primary"
                              weight="semibold"
                              component={"span"}>
                              {isSplitOrder ? bopisSplitDateFormat : date}
                            </StyledTypography>
                          </StyledGrid>
                        </StyledGrid>
                        <StyledGrid xs={12} className="vertical-padding-2">
                          <Divider />
                        </StyledGrid>

                        {productosDate &&
                          productosDate.map((product, index) => {
                            const homeDeliveryProduct = JSON.parse(JSON.stringify(product));
                            homeDeliveryProduct.isTYP = true;

                            let hasInstallation: any = null;
                            if (homeDeliveryProduct?.x_hasInstallation) {
                              hasInstallation = instalacionesOrders.filter(
                                (instalacion) => instalacion.partNumber.split("_")[2] === homeDeliveryProduct.partNumber
                              )[0];
                            }

                            return (
                              <DeliveryProductInfo
                                key={homeDeliveryProduct.id}
                                order={homeDeliveryProduct}
                                hasInstallation={hasInstallation}
                                index={index}
                              />
                            );
                          })}
                      </StyledGrid>
                    );
                  })}
                </>
              ) : null}
              {homeDeliveryDates.length !== 0 && type === "delivery" ? (
                <>
                  {homeDeliveryDates.map((date) => {
                    let productosDate: any = [];
                    if (oneTimeDeliveryOfMixedCart) {
                      productosDate = homeDeliveryOrders;
                    } else {
                      productosDate = homeDeliveryOrders.filter((item) => item.hdm_deliveryDateFormat === date);
                    }
                    return (
                      <StyledGrid xs={12} key={date}>
                        {deliveryDates?.fleetOrderAvailableDates &&
                        !oneTimeDeliveryOfMixedCart &&
                        !multipleDates &&
                        homeDeliveryDatesTMP &&
                        homeDeliveryDatesTMP.length === 1 ? (
                          <>
                            <StyledGrid xs={12} className={"vertical-margin-2"}>
                              <StyledTypography variant="bodyBaseline" component={"span"}>
                                {t("Shipping.Labels.ShippingManualDateDelivery")}
                              </StyledTypography>
                            </StyledGrid>
                            <StyledGrid xs={12} md={5} className={"vertical-margin-2"}>
                              <StyledDatePicker
                                disableToolbar
                                disablePast
                                format="dd/MM/yyyy"
                                variant="inline"
                                margin="normal"
                                id="datePicker--component"
                                label="Fecha de entrega"
                                value={selectedDeliveryDate}
                                onChange={handleSelectDeliveryDate}
                                shouldDisableDate={(dateSent) => disableWeekends(dateSent, date)}
                                onClick={() => setPickerOpen(true)}
                                onClose={() => setPickerOpen(false)}
                                open={pickerOpen}
                                PopoverProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  PaperProps: {
                                    style: {
                                      width: isMobile ? "100%" : "",
                                      display: isMobile ?  "flex" : "",
                                      borderRadius: "3px",
                                    },
                                  },
                                }}
                              />
                            </StyledGrid>
                          </>
                        ) : (
                          <StyledGrid xs={12} className={"deliveryInfoCard--styledGrid-flex bottom-margin-2"}>
                            <EventIcon className={"deliveryInfoCard--icon-eventIcon"} />
                            {console.log("dateTT", date)}
                            {console.log("deliveryLabel", deliveryLabel)}
                            <StyledGrid xs={7} md={6}>
                              <StyledTypography variant="bodyBaseline" component={"span"}>
                                {deliveryLabel}
                              </StyledTypography>
                            </StyledGrid>
                            <Divider orientation="vertical" flexItem />
                            <StyledGrid xs={5} md={6} className={"left-padding-2"}>
                              <StyledTypography
                                variant="bodyBaseline"
                                color="primary"
                                weight="semibold"
                                component={"span"}>
                                {date}
                              </StyledTypography>
                            </StyledGrid>
                          </StyledGrid>
                        )}
                        <StyledBox className="vertical-padding-2">
                          <Divider />
                        </StyledBox>
                        <StyledGrid style={{paddingBottom: "7px"}} className="products-container">
                        {productosDate &&
                          productosDate.map((product, index) => {
                            const homeDeliveryProduct = JSON.parse(JSON.stringify(product));
                            homeDeliveryProduct.isTYP = true;

                            let hasInstallation: any = null;
                            if (homeDeliveryProduct?.x_hasInstallation) {
                              hasInstallation = instalacionesOrders.filter(
                                (instalacion) => instalacion.partNumber.split("_")[2] === homeDeliveryProduct.partNumber
                              )[0];
                            }
                            return (
                              <DeliveryProductInfo
                                key={homeDeliveryProduct.id}
                                order={homeDeliveryProduct}
                                hasInstallation={hasInstallation}
                                index={index}
                              />
                            );
                          })}
                        </StyledGrid>
                      </StyledGrid>
                    );
                  })}
                </>
              ) : null}
            </>
          )}
        </>
      )}
    </StyledWrapper>
  );
};
export { DeliveryInfoCard };
