import axios, { AxiosResponse } from "axios";
import { formatAssociatedAccounts } from "../../utils/proUtlis";
import { AssociatedAccounts, GetAssociatedAccountsInputType } from "../../interfaces/ProLoginInterfaces";

export const getAssociatedAccounts = async (
    { InputEmail, storeID }: GetAssociatedAccountsInputType
  ): Promise<AssociatedAccounts[] | string | undefined> => {

const url = `/wcs/resources/store/${storeID}/hdmpro/login/sec2`;
const params = {
    usrEmailId: InputEmail,
    proLoginPage: true
};

try {
    const response: AxiosResponse = await axios.get(url, { params }); 

    if(response?.data?.errorCode === "-1000"){
        return "emailError";
    }

    const formattedRespose = formatAssociatedAccounts(response?.data);
    if(formattedRespose?.length === 0){
        return "emailError";
    }
    return formattedRespose as any;
} catch (error) {
    console.log("Pro login error",error);
}
};