import styled from "styled-components";
import { StyledGrid } from "../../../hdm";

export const StyledCartRowPaintContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
  
  .styledCartRowPaint--styledGrid-paintContainer{
    display: flex;
    align-items: center;
  }

  .styledCartRowPaint--styledGrid-paintCodeNameContainer{
    display: flex;
  }
  
  .styledCartRowPaint--styledGrid-colorDisplay{
    height: 10px;
    width: 100%;
  }

  .styledCartRowPaint--styledGrid-colorDisplayContainer{
    width: 100%;
    margin-left: ${theme.spacing(2)}px;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .styledCartRowPaint--styledGrid-colorDisplayContainerSimple{
    width: 100%;
    margin-bottom: ${theme.spacing(2)}px;
  }

  .styledCartRowPaint--img-deletePaintTrashSvg{
    color: ${theme.palette.gray[300]};
    width: 9px;
    height: 12px;
    cursor:pointer;
  }

  .styledCartRowPaint--img-deletePaintTrashContainer{
    margin-bottom: ${theme.spacing(1)}px;
    padding-top: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(2)}px;
    padding-left: ${theme.spacing(2)}px;
    width: 35px;
  }
  
  .styledCartRowPaint--styledGrid-paintBaseAddColor{
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .styledCartRowPaint--styledGrid-addDirectionIcon{
    width: 17px;
    height: 17px;
    margin-left: -${theme.spacing(1)}px;
    margin-right: ${theme.spacing(1)}px;
  }

  .styledCartRowPaint--styledGrid-addDirectionIconMiniCart{
    width: 17px;
    height: 17px;
    margin-left: -${theme.spacing(1)}px;
    margin-right: ${theme.spacing(1)}px;
  }

  .styledCartRowPaint--styledGrid-paintBaseContainer{
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    border: 1px solid  ${theme.palette.gray[50]};
    white-space: nowrap;
  }
  
  .styledCartRowPaint--styledGrid-paintBaseDivider {
    background-color: ${theme.palette.gray[100]};
  }

  .styledCartRowPaint--styledGrid-paintCodeName{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    ${theme.breakpoints.down("sm")} {
      white-space: pre-line;
    }
  }
  
  .styledCartRowPaint--styledGrid-paintBaseNoWarp{
    white-space: nowrap;
  }

  .styledCartRowPaint--styledGrid-paintBaseNoWarp{
    white-space: nowrap;
  }

  .styledCartRowPaint--styledGrid-backgroundColor100{
    background-color: ${theme.palette.gray[100]};
  }
`}
`;
