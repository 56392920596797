import styled from "styled-components";
import { JsonLP } from "../../components/json-lp";
import { StyledBox, StyledGrid, StyledPDPContainer } from "../../elements";
import { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import { OFF_SET_HEIGHT_80 } from "../../../constants/common";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { tabletBreakpoint } from "../../functions/utils";
import QuantityHeader from "../../components/pdp-quantity-header/QuantityHeader";
import { ContainerPdP } from "./container-pdp";
import ProductSummaryCardHDM from "../../../components/custom-components/styled-product-summary-card/ProductSummaryCardHDM";
import ProductBadgedPromotion from "../../elements/product-card/Styled-badged-promotion";
import CarouselHDM from "../../../components/custom-components/styled-carousel-hdm/CarouselHDM";
import { useDispatch, useSelector } from "react-redux";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import CarouselHDMDesktop from "../../../components/custom-components/styled-carousel-hdm/CarouselHDMDesktop";
import PdpCardPrecio from "../../components/pdp-card-precio/CardPrecio";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import RoomvoVisualSupport from "../../../components/custom-components/styled-visual-support/RoomvoVisualSupport";
import useScriptInHead from "../../../components/custom-components/styled-carousel-hdm/useScriptInHead";
import { InView } from "react-intersection-observer";
import ShopperActionsPDPHDM from "../../../components/custom-components/styled-pdp-shopper-actions/ShopperActionsPDPHDM";
import ViewContentEvent from "../../../components/custom-components/conversion-api/ViewContentEvent";
import PdpDisponibilidad from "../../components/pdp-disponibilidad/Disponibilidad";
import AcercaDeEsteProducto from "../../components/pdp-acerca-de-este-producto/AcercaDeEsteProducto";
import SuperSkuContainer from "../../components/pdp-super-sku/SuperSkuContainer";
import ComprarColor from "../../components/pdp-comparar-color/BuyByColor";
import { orderItemsSelector } from "../../../redux/selectors/order";
import ProductInstalationHDM from "../../../components/custom-components/styled-product-instalation-card/ProductInstalationHDM";
import InformacionAdicional from "../../components/pdp-informacion-adicional/InformacionAdicional";
import { GtmLoaded } from "../../../components/custom-components/gtm-events/Gtm-loaded";
import { CustomTableKits } from "../kit/kit-custom-table";
import KitAvailability from "../../components/pdp-disponibilidad/KitAvailability";
import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";

const ShoperActionheader = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, start, end }) => `

  height: 70px;
  position: fixed;
  z-index: 999;
  top: -70px;
  background-color:${theme.palette.background.paper};

  &.header-shoperaction-show {
    animation: translateHeaderShow 0.3s linear forwards; /* Added 'forwards' */
  }

  &.header-shoperaction-hide {
    animation: translateHeaderHide 0.3s linear forwards; /* Added 'forwards' */
  }

  @keyframes translateHeaderShow {
    0% {
      transform: translateY(0); /* Start position */
    }
    100% {
      transform: translateY(70px); /* End position */
    }
  }

  @keyframes translateHeaderHide {
    0% {
      transform: translateY(70px); /* Start position */
    }
    100% {
      transform: translateY(0px); /* End position */
    }
  }

  `}
`;

export const ProductDetailsContainer = (props: any) => {
  const {
    displayName,
    displayShortDesc,
    catentryData,
    kitAvailabilityData,
    partNumber,
    displayOfferPrice,
    currentSelection,
    nominalQuantity,
    marketInventory,
    currentStoreId,
    promociones,
    quantityMeasure,
    loginStatus,
    brand,
    name,
    proPriceReady,
    model,
    detectedBrowser,
    customBrandExclude,
    urlRibbons,
    aRIdentifier,
    fullImage,
    attachedImages,
    product,
    attributes,
    shortDescription,
    productId,
    weightMeasure,
    orderRibbons,
    roomvoIdentifier,
    bahiaData,
    leadTimeProduct,
    tiendaNombre,
    locationName,
    allEspecificacionesTec,
    displayPartNumber,
    longDescription,
    isParentSSKU,
    hasProductBaseColor,
    legalDescription,
    videos,
    tableData,
    isGTMLoaded,
    setIsGTMLoaded,
    partNumberBT,
    categoryBT,
    categoryBT2,
    categoryBT3,
    addComboToCart,
    isB2B,
    isPdp,
    isKit,
    hasBreadcrumb
  } = props;

  const pdpSlotOneRef = useRef<HTMLDivElement | null>(null);
  const pdpSlotTwoRef = useRef<HTMLDivElement | null>(null);
  const summaryGalleryRef = useRef<HTMLDivElement | null>(null);
  const shoperActionRef = useRef<HTMLDivElement | null>(null);
  const priceShoperActionRef = useRef<HTMLDivElement | null>(null);
  const helperRef = useRef<HTMLDivElement | null>(null);
  
  const [summaryGalleryHeight, setSummaryGalleryHeight] = useState<any>(0);
  const [pdpSlotTwoHeight, setPdpSlotTwoHeight] = useState<any>(0);
  const [priceShoperActionHeight, setPriceShoperActionHeight] = useState<any>(0);

  const [showCarritoHeader, setShowCarritoHeader] = useState<boolean>(false);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
  const is1440 = useMediaQuery(theme.breakpoints.down(1440));
  const storeConfData = useSelector(onlineStoreRdc);
  const hdmOrderItemsSelector = useSelector(orderItemsSelector);
  const breadcrumbsValue = useSelector(breadcrumbsSelector);
  const dispatch = useDispatch<Dispatch<any>>();

  const pdpQty: any | number = useSelector<any>((state) =>
    state.hdmDataReducer.pdpQuantity ? state.hdmDataReducer.pdpQuantity : 1
  );

  /*==== Ar api key =====*/
  const arApiKey = storeConfData?.AR_APITOKENKEY;
  const isArEnabled = storeConfData?.ENABLE_AR;
  const isVisualSupportEnabled = aRIdentifier ? aRIdentifier : "";

  /*==== ROOMVO changes =====*/
  const isRoomvoEnabled = storeConfData?.ENABLE_ROOMVO;
  const isRoomvoVisualSupportEnabled = roomvoIdentifier ? roomvoIdentifier : "";

  const espotUXcomponent = isB2B ? "PRO_PDP-UXcomponent" : "PDP-UXcomponent";
  const espotCrossSell = isB2B ? "PRO_PDP-CrossSell" : "PDP-CrossSell";
  
  useScriptInHead(
    isRoomvoEnabled && (isRoomvoVisualSupportEnabled === "2" || isRoomvoVisualSupportEnabled === "3")
      ? "https://cdn.roomvo.com/static/scripts/b2b/homedepot_mexico.js"
      : "",
    ""
  );

  useEffect(() => {
    const resizeObserverSummaryGallery= new ResizeObserver(entries => {
      for (const entry of entries) {
        if(entry?.contentRect?.height){
          const height: any = entry?.contentRect?.height
          setSummaryGalleryHeight(parseInt(height))
        }
      }
    });

    const resizeObserverSlotTwo = new ResizeObserver(entries => {
      for (const entry of entries) {
        if(entry?.contentRect?.height){
          const height: any = entry?.contentRect?.height
          setPdpSlotTwoHeight(parseInt(height))
        }
      }
    });

    const resizeObserverPriceShoperAction = new ResizeObserver(entries => {
      for (const entry of entries) {
        if(entry?.contentRect?.height){
          const height: any = entry?.contentRect?.height
          setPriceShoperActionHeight(parseInt(height))
        }
      }
    });

    if (summaryGalleryRef.current) {
      resizeObserverSummaryGallery.observe(summaryGalleryRef.current);
    }

    if (pdpSlotTwoRef.current) {
      resizeObserverSlotTwo.observe(pdpSlotTwoRef.current);
    }
    if (priceShoperActionRef.current) {
      resizeObserverPriceShoperAction.observe(priceShoperActionRef.current);
    }

    const homeMainContainer = document.getElementById("home--footer-container");
    if(homeMainContainer){
      homeMainContainer.classList.remove('footer-main-container');
    }

    return () => {
      resizeObserverSummaryGallery.disconnect();
      resizeObserverSlotTwo.disconnect();
    };
  }, [])
  

  useEffect(() => {
    if (catentryData && catentryData?.partNumber) {
      sendEmarsysTags();
    }
    // if (catentryData && catentryData?.partNumber && currentSelection && currentSelection?.sku && breadcrumbsValue.length > 0) {
    // }
  }, [catentryData, breadcrumbsValue, currentSelection]);

  const sendEmarsysTags = async () => {
    let categories = "";
    const arrayCategories = breadcrumbsValue.filter((e) => e.type_ === "FACET_ENTRY_CATEGORY");
    await arrayCategories.map((tag) => {
      categories += tag.label + ">";
    });
    categories = categories.substring(0, categories.length - 1);
    const query = {
      url: "",
      data: {
        view: ["view", catentryData?.partNumber.replace("P", "")],
        exclude: ["exclude", "category", "has", categories],
      },
      option: "EMARSYS_PDP_TAG_DATA",
      fetch: false,
    };
    dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
  };

  const handleChangeInView = (data) => {
    if (data.vista === "ShopperActionsPDPHDM") {
      if (data.entry.isIntersecting) {
        setShowCarritoHeader(false);
      } else {
        setShowCarritoHeader(true);
      }
    }
  };

  const handleGoInformacionAdicional = () => {
    const anchor: any = document.getElementById("pdp-informacion-adicional")?.offsetTop;
    if (isTablet) {
      window.scrollTo({ top: anchor - 110, behavior: "smooth" });
    } else {
      window.scrollTo({ top: anchor - 110, behavior: "smooth" });
    }
  };

  const handleGoEspecTecnicas = () => {
    const anchor: any = document.getElementById("pdp-spec-tecnicas")?.offsetTop;
    window.scrollTo({ top: anchor - 110, behavior: "smooth" });
  };

  const merchandisingAssociations =
    catentryData?.merchandisingAssociations !== undefined ? catentryData.merchandisingAssociations : [];
  let services: Array<any> = [];
  const servicesInCart: Array<any> = [];
  if (merchandisingAssociations && merchandisingAssociations !== undefined) {
    services = merchandisingAssociations.filter(
      (merchandisingAssociation) =>
        merchandisingAssociation.associationType && merchandisingAssociation.associationType === "TechnicalVisit"
    );
    // If we dont found TechnicaVisit, so we will check Installation
    if (services.length === 0) {
      services = merchandisingAssociations.filter(
        (merchandisingAssociation) =>
          merchandisingAssociation.associationType && merchandisingAssociation.associationType === "Installation"
      );
    }
  }

  services.forEach((service) => {
    if (service.associationType && service.associationType === "TechnicalVisit") {
      servicesInCart.push(service.partNumber.split("_")[0]); //get service Id
    }
  });
  const isServiceChecked = hdmOrderItemsSelector.find((orderItem) =>
    servicesInCart.includes(orderItem?.partNumber.split("_")[0])
  );

  //*json

  const currentMarket =
    marketInventory && marketInventory?.find((market) => market?.physicalStoreName === currentStoreId);
  const field2 = currentMarket && currentMarket?.x_customField2 ? parseInt(currentMarket?.x_customField2) : 0;
  const availableQuantityInMarket =
    currentMarket && currentMarket?.x_AvailableQuantityInMarket
      ? parseInt(currentMarket?.x_AvailableQuantityInMarket)
      : 0;
  const mktInventory = marketInventory && availableQuantityInMarket ? availableQuantityInMarket : field2 && field2;

  let schemaPartNumber = "";
  if (currentSelection?.sku && currentSelection?.sku?.length !== 0 && currentSelection?.sku[0]?.partNumber) {
    schemaPartNumber = currentSelection?.sku[0].partNumber.replace("P", "");
  }

  const availability = isPdp ? mktInventory : kitAvailabilityData?.kitMarketAvailability

  const producPrice = product?.price?.find((p) => p.usage === "Offer").value;
  const calculateProductPrice =
    producPrice !== "0.0" ? producPrice : catentryData?.price?.find((p) => p.usage === "Offer").value;
  const CDN_URL = "https://cdn.homedepot.com.mx/";
  const jsonLPPayload = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: name,
    "@id": `${window.location.origin}${window.location.pathname}`,
    image: [product?.items && product.items.length > 0 && CDN_URL + product?.items[0]?.fullImageRaw],
    description: displayShortDesc,
    sku: partNumber,
    mpn: partNumber,
    brand: {
      "@type": "Thing",
      name: product?.manufacturer ?? "",
    },
    offers: {
      "@type": "Offer",
      url: `${window.location.origin}${window.location.pathname}`,
      priceCurrency: "MXN",
      price: calculateProductPrice,
      price_sqm: quantityMeasure === "m2" ? parseFloat(catentryData?.price[1].field1?.OMP).toFixed(2) : "",
      priceValidUntil: "",
      availability: `${availability === 0 ? "https://schema.org/OutOfStock" : "https://schema.org/InStock"}`,
    },
  };

  //*calculate sticky summary and gallery move
  const start: any = shoperActionRef?.current?.offsetTop;
  
  let to = 0;
  const slotOneHeight: any = pdpSlotOneRef?.current?.clientHeight;
  const slotOffsetTop: any = pdpSlotOneRef?.current?.offsetTop;
  // const slotTwoHeight: any = slotOneHeight;
  // let slotTwoHeight: any = summaryGalleryHeight;
  let slotTwoHeight: any = `height: ${summaryGalleryHeight}px`;

  const desfase: any = parseFloat(start) - parseFloat(slotOffsetTop);
  const sumaDesfase: any = parseFloat(summaryGalleryHeight) + parseFloat(desfase);
  const result: any = parseFloat(sumaDesfase) + OFF_SET_HEIGHT_80;

  let disableSticky = false
  const HeightDiff = parseInt(pdpSlotTwoHeight) - parseInt(summaryGalleryHeight)
  if((parseInt(summaryGalleryHeight) > parseInt(pdpSlotTwoHeight)) && HeightDiff < 70   ){
    disableSticky = true
  }

  if(parseInt(summaryGalleryHeight) <= parseInt(pdpSlotTwoHeight) ){
    // slotTwoHeight = pdpSlotTwoHeight
    slotTwoHeight = `height: ${pdpSlotTwoHeight}px`
  }
  
  if (parseFloat(slotOneHeight) > result) {
    to = slotOneHeight - result;
    // if(detectedBrowser === CHROME && to > OFF_SET_HEIGHT_80){
    //   to = OFF_SET_HEIGHT_80
    // }
    if (to > 90) {
      to = 90;
    }
  }

  const MemoizedProductPriceCard = useMemo(() => {
    return (
      <>
        <PdpCardPrecio
          catentryData={catentryData}
          brand={brand}
          proPriceReady={proPriceReady}
          attributes={attributes}
          partNumber={partNumber}
          shortDescription={shortDescription}
          nominalQuantity={nominalQuantity}
          product={product}
          productId={productId}
          quantityMeasure={quantityMeasure}
          weightMeasure={weightMeasure}
          promociones={promociones}
          urlRibbons={urlRibbons}
          orderRibbons={orderRibbons}
          isB2B={isB2B}
          loginStatus={loginStatus}
        />
      </>
    );
  }, [
    catentryData,
    brand,
    attributes,
    partNumber,
    shortDescription,
    shortDescription,
    nominalQuantity,
    product,
    productId,
    quantityMeasure,
    weightMeasure,
    promociones,
    urlRibbons,
    orderRibbons,
    isB2B,
    loginStatus,
  ]);

  const endShoperHeader: any = start + OFF_SET_HEIGHT_80;
  const endSummary: any = start + to;
  const endGallery: any = start + to;

  return (
    <>
      <JsonLP payload={jsonLPPayload} />
      {catentryData && (
        <ShoperActionheader 
        className={!isTablet && showCarritoHeader ? "header-shoperaction-show" : "header-shoperaction-hide"}
        style={{ 
          width: is1440 ? "100%" : "1440px", 
        }}
         start={start} end={endShoperHeader}>
            <QuantityHeader
              partNumber={partNumber}
              productPrice={displayOfferPrice}
              currentSelection={currentSelection}
              nominalQuantity={nominalQuantity}
              marketInventory={marketInventory}
              kitAvailabilityData={kitAvailabilityData}
              catentryData={catentryData}
              currentStoreId={currentStoreId}
              showCarritoHeader={showCarritoHeader}
              isTablet={isTablet}
              promociones={promociones}
              quantityMeasure={quantityMeasure}
              addComboToCart={addComboToCart}
              isB2B={isB2B}
              loginStatus={loginStatus}
              isPdp={isPdp}
            />
        </ShoperActionheader>
      )}
      <ContainerPdP className="pdp-container" slotTwoHeight={isTablet ? "" : slotTwoHeight}>
        <StyledBox className="pdp-slot1" id="pdp-left-content" ref={pdpSlotOneRef} >
          {/* <StickyListener shoperActionRef={shoperActionRef} 
          helperRef={helperRef} 
          showCarritoHeader={showCarritoHeader} 
          summaryGalleryHeight={summaryGalleryHeight} 
          pdpSlotTwoHeight={pdpSlotTwoHeight}
          priceShoperActionHeight={priceShoperActionHeight}
          /> */}

          <StyledBox className={isTablet ? "" : "sticky-summary-gallery"} style={{height: `${summaryGalleryHeight}px`}}>

              <StyledBox id="summaryGalleryRef" ref={summaryGalleryRef}>
                <ProductSummaryCardHDM
                  brand={brand}
                  productName={name}
                  partNumber={partNumber}
                  model={model}
                  customBrandExclude={customBrandExclude}
                  detectedBrowser={detectedBrowser}
                  showCarritoHeader={showCarritoHeader}
                  start={start}
                  end={endSummary}
                  to={to}
                />
                <StyledPDPContainer
                  className={isTablet ? "pdp-container-mobile" : "pdp-container-desktop"}
                  start={start}
                  end={endGallery}
                  to={to}>
                  <ProductBadgedPromotion promociones={promociones} badge="" isPdp={true} brand={brand}/>
                  <StyledGrid container>
                    <StyledGrid container spacing={2} item xs={12}>
                      <>
                        {isTablet && (
                          <StyledGrid item xs={12} md={12} className="product-gallery-card">
                            <CarouselHDM
                              ArApiKey={arApiKey}
                              fullImage={fullImage}
                              carouselImages={attachedImages}
                              isVisualSupportEnabled={isVisualSupportEnabled}
                              SKU={partNumber}
                              promociones={promociones}
                              urlRibbons={urlRibbons}
                              isArEnabled={isArEnabled}
                            />
                          </StyledGrid>
                        )}
                        {!isTablet && (
                          <StyledGrid item xs={12} md={12} className="product-gallery-card">
                            <CarouselHDMDesktop
                              ArApiKey={arApiKey}
                              fullImage={fullImage}
                              carouselImages={attachedImages}
                              activeSlide={carouselIndex}
                              setActiveSlide={setCarouselIndex}
                              isVisualSupportEnabled={isVisualSupportEnabled}
                              SKU={partNumber}
                              promociones={promociones}
                              urlRibbons={urlRibbons}
                              isArEnabled={isArEnabled}
                            />
                          </StyledGrid>
                        )}
                      </>
                      {/* <StyledGrid item xs={12} md={6}>
                        <div>{product && product?.type !== "bundle" && <></>}</div>
                      </StyledGrid> */}
                    </StyledGrid>
                  </StyledGrid>
                </StyledPDPContainer>
              </StyledBox>
          </StyledBox>
        </StyledBox>

        <StyledBox className="pdp-slot2">
          <StyledBox ref={pdpSlotTwoRef} >
            <StyledBox ref={priceShoperActionRef}>

              <StyledBox mx={isTablet ? 2 : 0} mt={isTablet ? 2 : 0}>
                {MemoizedProductPriceCard}
              </StyledBox>

              {hasBreadcrumb && <EspotHdm espotName={espotUXcomponent} />}

              <StyledBox mx={isTablet ? 2 : 0}>
                <RoomvoVisualSupport
                  isRoomvoVisualSupportEnabled={isRoomvoVisualSupportEnabled}
                  SKU={partNumber}
                  isRoomvoEnabled={isRoomvoEnabled}
                />
              </StyledBox>

              <InView
                as="div"
                threshold={0}
                onChange={(inView, entry) =>
                  entry ? handleChangeInView({ entry: entry, vista: "ShopperActionsPDPHDM" }) : null
                }>
                <StyledBox id="shoper-action-ref" ref={shoperActionRef} style={{ minHeight: 82 }} className={showCarritoHeader ? "" : ""}>
                  <ShopperActionsPDPHDM
                    partNumber={partNumber}
                    productPrice={displayOfferPrice}
                    currentSelection={currentSelection}
                    marketInventory={marketInventory}
                    kitAvailabilityData={kitAvailabilityData}
                    catentryData={catentryData}
                    // updateProductQuantity={updateProductQuantity}
                    nominalQuantity={nominalQuantity}
                    currentStoreId={currentStoreId}
                    showCarritoHeader={showCarritoHeader}
                    promociones={promociones}
                    quantityMeasure={quantityMeasure}
                    loginStatus={loginStatus}
                    addComboToCart={addComboToCart}
                    isB2B={isB2B}
                    isNotZero={true}
                    isMainShoperAction={true}
                    isPdp={isPdp}
                  />
                  {catentryData && (
                    <ViewContentEvent
                      catentryDtls={catentryData}
                    />
                  )}
                </StyledBox>
              </InView>

            </StyledBox>

            {hasBreadcrumb && <EspotHdm espotName={espotCrossSell} />}

            <StyledBox mx={isTablet ? 2 : 0}>
              {isPdp ? (
                <PdpDisponibilidad
                  isB2B={isB2B}
                  bahiaData={bahiaData}
                  leadTime={leadTimeProduct}
                  currentStoreId={currentStoreId}
                  productId={productId}
                  partNumber={partNumber}
                  marketInventory={marketInventory}
                  tiendaNombre={tiendaNombre}
                  locationName={locationName}
                  orderRibbons={orderRibbons}
                  attributes={attributes}
                />
              ) : (
                <KitAvailability
                  productData={catentryData}
                  orderRibbons={orderRibbons}
                  currentStoreId={currentStoreId}
                  tiendaNombre={tiendaNombre}
                  locationName={locationName}
                  marketInventory={marketInventory}
                  leadTime={leadTimeProduct}
                  kitAvailabilityData={kitAvailabilityData}
                  isB2B={isB2B}
                />
              )}
            </StyledBox>

            <StyledBox mx={isTablet ? 2 : 0}>
              <AcercaDeEsteProducto
                handleGoInformacionAdicional={handleGoInformacionAdicional}
                handleGoEspecTecnicas={handleGoEspecTecnicas}
                isTablet={isTablet}
                shortDescription={shortDescription}
                allEspecificacionesTec={allEspecificacionesTec}
                isKit={isKit}
              />
            </StyledBox>

            {!isPdp ? (
              <StyledBox mx={isTablet ? 2 : 0} className={"bottom-margin-2"}>
                <CustomTableKits tableData={tableData} />
              </StyledBox>
            ) : null}

            {isParentSSKU && catentryData && (
              <StyledBox mx={isTablet ? 2 : 0}>
                <SuperSkuContainer product={product} currentPartNumber={displayPartNumber} isTablet={isTablet} currentMarket={currentMarket} />
              </StyledBox>
            )}

            {isParentSSKU && hasProductBaseColor ? (
              <StyledBox mx={isTablet ? 2 : 0}>
                <ComprarColor
                  product={product}
                  hasProductBaseColor={hasProductBaseColor}
                  brand={brand}
                  partNumber={partNumber}
                />
              </StyledBox>
            ) : null}

            {services && services.length > 0 && (
              <>
                {services.map((service, key) => (
                  <ProductInstalationHDM
                    installationIsChecked={isServiceChecked}
                    servicesInCart={""}
                    isCart={false}
                    cartQty={pdpQty}
                    merchandisingAssociations={service}
                  />
                ))}
              </>
            )}

            {isTablet ? (
              <StyledBox mx={isTablet ? 2 : 0}>
                <InformacionAdicional
                  partNumber={partNumber}
                  longDescription={longDescription}
                  model={model}
                  legalDescription={legalDescription}
                  videos={videos}
                />
              </StyledBox>
            ) : null}
          </StyledBox>
        </StyledBox>
      </ContainerPdP>

      <GtmLoaded
        isGTMLoaded={isGTMLoaded}
        setIsGTMLoaded={setIsGTMLoaded}
        isGTMPDP={true}
        partNumberBT={partNumberBT}
        categoryBT={categoryBT}
        categoryBT2={categoryBT2}
        categoryBT3={categoryBT3}
      />
    </>
  );
};
