/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios, { Canceler } from "axios";
import { withStyles } from "@material-ui/core/styles";
import { StyledTooltip } from "../../../../src/hdm/elements/tooltip";
import styled from "styled-components";
import { Card, Radio, Tooltip, InputAdornment, TooltipProps } from "@material-ui/core";
import { StyledButton } from "../button";
import { StyledLink } from "../link";
import { StyledGrid } from "../grid";
import { StyledTypography } from "../typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import { StyledBox } from "../box";
import { StyledFormControlLabel } from "../form";
import getDisplayName from "react-display-name";
import HelpIcon from "../../../assets/homedepot/images/HelpIcon";
import { Divider } from "@material-ui/core";
/* Utils */
import { useTheme } from "@material-ui/core/styles";
import { StyledIconButton } from "../../../hdm";
import { useTranslation } from "react-i18next";
import { StyledDialog, StyledDialogContent, StyledDialogContentText, StyledDialogTitle } from "../dialog";
import { StyledAddressCardActionPopUp, StyledCardContainer } from "./styled-card-styles";
import { ModalSimpleDelete } from "../../components";
import personContactService from "../../../_foundation/apis/transaction/personContact.service";
import { ADDRESS_COUNTRY } from "../../../constants/common";
import { GET_ADDRESS_DETAIL_ACTION } from "../../../redux/actions/account";
import { addressDetailsSelector } from "../../../redux/selectors/account";
import { palette } from "../../../hdm/themes";

import { useSite } from "../../../_foundation/hooks/useSite";
import { CheckoutProfileApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import personService from "../../../_foundation/apis/transaction/person.service";
import storeUtil from "../../../utils/storeUtil";
import { PAYMENT } from "../../../constants/order";
import { get } from "lodash-es";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { CURRENT_USER } from "../../../_foundation/constants/common";
import { site } from "../../../_foundation/constants/site";
import { checkoutProfileSelector } from "../../../redux/selectors/checkout-profile";
import addressUtil from "../../../utils/addressUtil";
import withSkeletonLoader from "../../../components/custom-components/skeleton-loader/hoc/withSkeletonLoader";
import { StyledSkeleton } from "../../../components/custom-components/skeleton-loader/components/StyledSkeleton";
import { loaders } from "../../../components/custom-components/skeleton-loader/components";
import { backdropLoaderStateAndSrcSelector } from "../../../redux/selectors/backdrop-loader";
import { LoaderActions } from "../../../components/custom-components/skeleton-loader/actions/sl-actions";
import { StyledPromp } from "../../../components/custom-components/styled-promp";

const StyledCardWrapper = styled(({ ...props }) => <Card {...props} />)`
  ${({ theme }) => `
    border: 1px solid  ${theme.palette.grey[200]};

    &.product-card {
      .MuiTypography-body2 {
        width: 66%;
        margin: 0 auto;
      }
    }

    &.address-card, &.payment-card {
      position: relative;
      border: 1px solid  ${theme.palette.divider};

      a {
        font-weight: 400;
      }
    }

    &.selected {
      border-radius: ${theme.shape.borderRadius}px;
      border: 2px solid ${theme.palette.primary.main};
    }

    &.error-card-selected {
      border-radius: ${theme.shape.borderRadius}px;
      border: 2px solid ${theme.palette.text.alert};
    }
  `}
`;

const StyledConfirmOverlay = styled.div`
  ${({ theme }) => `
      background-color: ${theme.palette.background.paper}${theme.palette.background.transparency};
      position: absolute;
      bottom:0;
      height: 70%;
      width: 100%;
  `}
`;

const StyledSelectedAddressContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme, fromMyAccount }) => `
    ${fromMyAccount
      ? `display: flex;
      & svg{
        font-size: 15px;
        color: ${theme.palette.gray["300"]};
        margin-right: ${theme.spacing(1)}px;
        margin-top: 1px;
      }
      `
      : ""
    }
    
  `}
`;

interface StyledParameterizedCardProps {
  testId: string;
  headerProps?: any;
  contentComponent?: any;
  cardActions?: any;
  confirmLabel?: string;
  cancelLabel?: string;
  className?: string;
  cardFooter?: any;
  nickName?: string;
  variant?: string;
  isPrimaryAddress?: boolean;
  fromMyAccount?: boolean;
  zipCodeInBlocklist?:boolean;
  existBlockZipCodeInList?:boolean;
}

const StyledCard: React.FC<StyledParameterizedCardProps> = (props: any) => {
  const {
    headerProps,
    contentComponent,
    cardActions,
    cardFooter,
    confirmLabel,
    cancelLabel,
    className,
    testId,
    nickName,
    isPrimaryAddress,
    variant,
    fromMyAccount,
    zipCodeInBlocklist,
    existBlockZipCodeInList
  } = props;
  const { logonId } = useSelector(addressDetailsSelector);
  const { t } = useTranslation();
  const widgetName = getDisplayName("AddressCard");
  const [confirmState, setConfirmState] = useState<boolean>(false);
  const [confirmActionIndex, setConfirmActionIndex] = useState<number>(0);
  const [openCardActionsPopUp, setOpenCardActionsPopUp] = useState({
    editButton: false,
    deleteButton: false,
  });
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const dispatch = useDispatch();
  const isMainAddress = nickName === logonId;
  const theme = useTheme();
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  //profile workaround
  const RF_JSON = "json";
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const [profileList, setProfileList] = useState<any[]>([]);
  const fromState = useSelector(checkoutProfileSelector);
  const currentUser = localStorageUtil.get(CURRENT_USER);
  const mainAddressLabel = isB2B ? t("Account.Labels.MainAddressPro") : t("Account.Labels.MainAddress");

  const loaderState = useSelector(backdropLoaderStateAndSrcSelector)

  const { editButton, deleteButton } = openCardActionsPopUp;
  const toggleConfirm = (index: number) => {
    setConfirmState(!confirmState);
    setConfirmActionIndex(index);
  };

  const handleConfirmButtonClick = () => {
    cardActions[confirmActionIndex]?.handleClick();
    toggleConfirm(0);
  };

  const handleCancelButtonClick = () => {
    toggleConfirm(0);
  };

  const handeActionButtonClick = (v: any, index: number) => {
    if (v.enableConfirmation) {
      toggleConfirm(index);
    } else {
      v.handleClick();
    }
  };

  const reversedCardActions = [...cardActions].reverse();

  const handleCloseEliminar = () => {
    setOpenCardActionsPopUp((prevState) => ({ ...prevState, editButton: false }));
  };

  const handleChangePrimaryAddres = async () => {
    // let {0: calle, 1: numeroExt, 2: colonia } = data?.addressLine
    // calle = calle ? calle : ""
    // numeroExt = numeroExt ? numeroExt : ""
    // colonia = colonia ? colonia : ""
    const body = {
      // firstName: data?.firstName ? data?.firstName : "Daniel",
      // lastName: data?.lastName ? data?.lastName : "Rincon",
      // email1: data?.email1 ? data?.email1 : "daniel35@solemti.mx",
      // phone1: data?.phone1 ? data?.phone1 : "6699948312",
      // zipCode: data?.zipCode ? data?.zipCode : "",
      // country: data?.country ? data?.country : ADDRESS_COUNTRY,
      // city: data?.city ? data?.city : "",
      // addressType: "ShippingAndBilling",
      // state: data?.state ? data?.state : "",
      // addressLine1: calle,
      // addressLine2: numeroExt,
      // addressLine3: colonia,
      // addressLine: [calle, numeroExt, colonia],
      primary: "true",
      // field3: xaddr_addressField3,
      // xaddr_addressField3: xaddr_addressField3,
      // nickName: contactFormData?.nickName ? contactFormData?.nickName : "",
    };

    const parameters: any = {
      nickName: nickName,
      body: body,
      ...payloadBase,
    };

    await personContactService
      .updatePersonContact(parameters)
      .then((res) => res.data)
      .then(async (addressData) => {
        if (addressData.addressId) {
          const addRes = await personService.findPersonBySelf(payloadBase);
          if (addRes.data) {
            const contactMap = {};
            const contactList = addRes.data.contact;
            if (contactList) {
              contactList.forEach((address: any) => {
                if (address && address.addressId) {
                  contactMap[address.addressId] = address;
                }
              });

              const { contact, ...person } = addRes.data;
              const newPerson = {
                ...person,
                contactMap,
                contactList,
              };
              updateCheckoutProfile(newPerson);
            } else {
              const newPerson = {
                ...addRes.data,
              };
              updateCheckoutProfile(newPerson);
            }
          }
          dispatch(GET_ADDRESS_DETAIL_ACTION(payloadBase));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getObjWithPrefix = useCallback((list, v, prefix) => {
    const acceptedKeys = [
      "addressLine",
      "addressId",
      "city",
      "country",
      "zipCode",
      "state",
      "firstName",
      "lastName",
      "nickName",
    ];
    const obj = list.find(({ nickName }) => nickName === v);
    const acceptedMap = storeUtil.toMap(acceptedKeys);
    const rc = {};
    Object.keys(obj)
      .filter((k) => acceptedMap[k])
      .forEach((k) => (rc[`${prefix}${k}`] = obj[k]));
    return rc;
  }, []);

  const updateCheckoutProfile = async (newPerson) => {
    const addresses: any[] = [];
    if (newPerson?.contactList) {
      newPerson.contactList.forEach((address) => addresses.push(address));
    }
    if (newPerson?.addressLine) {
      addresses.push(addressUtil.getRegisteredInitialAddress(newPerson));
    }

    const p = profileList.map((profile) => {
      if (!profile.isValid) {
        return Promise.resolve();
      }
      const body: any = {};

      const shipData = getObjWithPrefix(addresses, profile.shipping_nickName, "shipping_");
      const billData = getObjWithPrefix(addresses, profile.billing_nickName, "billing_");
      const pm = get(profile, "billingData.payment_method.value");
      const isCreditCard = PAYMENT.paymentMethodName[pm];

      Object.assign(body, {
        profileName: profile.xchkout_ProfileName,
        ...shipData,
        ...billData,
        shipping_modeId: profile.shipping_modeId,
        URL: "noURL",
        pay_payment_method: pm,
      });

      if (currentUser) {
        const { userId } = currentUser;
        Object.assign(body, { userId });
      }

      if (isCreditCard) {
        Object.assign(body, {
          pay_account: get(profile, "billingData.account.value"),
          pay_expire_month: get(profile, "billingData.expire_month.value"),
          pay_expire_year: get(profile, "billingData.expire_year.value"),
          pay_cc_brand: pm,
        });
      }

      const checkoutProfileApi = new CheckoutProfileApi(undefined, site.transactionContext);
      return checkoutProfileApi.checkoutProfileUpdateCheckoutProfileById(
        mySite.storeID,
        profile.xchkout_ProfileId,
        RF_JSON,
        body
      );
    });

    try {
      await Promise.all(p);
    } catch (e) {
      console.log("Could not update the checkout profile for address", e);
    }
  };

  //to transform the checkout profile details from json object to profileList array
  const getCheckoutProfileDetails = useMemo(
    () => (response) => {
      const p = response.curUserProfiles
        .filter((p) => p.xchkout_ProfileId && p.xchkout_ProfileName)
        .map((p) => {
          const rc = {
            ...p,
            billingData: storeUtil.toMap(get(p, "protocolData", []), "name"),
          };
          return rc;
        });
      return p;
    },
    []
  );

  useEffect(
    () => {
      const rc = getCheckoutProfileDetails(fromState);
      setProfileList(rc);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromState]
  );

  return (
    <>
      {/*headerProps && (
          <>
            <StyledGrid item xs={false} className="full-width">
              <StyledCardContent className="horizontal-padding-2 vertical-padding-2">{headerProps}</StyledCardContent>
            </StyledGrid>
            {(contentComponent || cardActions?.length > 0) && <Divider />}
          </>
        )*/}

      {contentComponent && variant !== "MyAccount" ? (
        <>
          <StyledGrid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            className="full-height bottom-margin-2">
            <StyledGrid container className={"styledCard--styledGrid-deliveryContainer"}>
              <StyledSelectedAddressContainer
                xs={10}
                fromMyAccount={fromMyAccount}
                className={`styledCard--styledGrid-flex`}>
                <LocationOnIcon className={"shipping--styledIcon-locationIcon"} />
                <StyledGrid>{contentComponent}</StyledGrid>
              </StyledSelectedAddressContainer>
              {
                withSkeletonLoader(
                  loaders.checkout.stepTwo.editLink,
                  loaderState.src === LoaderActions.CHECKOUT_SAVE_AND_CONFIRM_STEP_2,
                  <StyledGrid item className={"shipping--styledGrid-editSelectedAddress"}>
                    {cardActions?.length > 0 && (
                      <>
                        {cardActions.map((v: any, index: number) =>
                          v.link ? (
                            <StyledLink to={v.link} key={v.text + "_" + index}>
                              {v.text}
                            </StyledLink>
                          ) : (
                            (!isMainAddress && isB2B || !isB2B) ?
                            <StyledLinkButton
                              variant="bodyBaseline"
                              key={v.text + "_" + index}
                              onClick={() => handeActionButtonClick(v, index)}>
                              {v.text}
                            </StyledLinkButton>
                            : null
                          )
                        )}
                      </>
                    )}
                  </StyledGrid>,
                  {
                    omitTimeout:true
                  }

                )
              }

            </StyledGrid>
            {/*cardActions?.length > 0 && <Divider />*/}
            {cardFooter && (
              <StyledGrid item xs={false}>
                {cardFooter}
              </StyledGrid>
            )}
          </StyledGrid>
        </>
      ) : (
        // My account address card
        <StyledCardContainer container className="myaccount-addresscard" maxHeight={existBlockZipCodeInList} >
          <StyledBox className="content">
            <StyledGrid xs={12} display="flex">
              <StyledTypography variant="headingFeatured" className="alias-title bottom-margin-2">
                {isMainAddress ? mainAddressLabel.toUpperCase() : nickName}
              </StyledTypography>
              {isMainAddress && (
                <StyledTypography
                  variant="bodyBaseline"
                  className="alias-title bottom-margin-2"
                  style={{ color: palette.gray["300"] }}>
                  {t("AddressCard.Default")}
                </StyledTypography>
              )}
            </StyledGrid>
            <StyledBox xs={12} className="content-container" display="flex">
              <LocationOnIcon className="location-icon" />
              <StyledGrid className="left-padding-1">{contentComponent}</StyledGrid>
            </StyledBox>
          </StyledBox>
          {zipCodeInBlocklist && (
          <StyledBox xs={12} display="flex">
                     <StyledPromp variant="error" message={t("DeliveryZone.ChooseDeliveryzoneCheckout.NoAvailableZone")} />
          </StyledBox>
                )}
          <StyledBox>
            <StyledFormControlLabel
              className="address-card-radiobutton"
              value="female"
              checked={isPrimaryAddress}
              control={<Radio color={zipCodeInBlocklist ? "secondary" : "primary"} />}
              label={
                <StyledTypography component="span" className={`labelDesign ${zipCodeInBlocklist ? 'disabled' : ''}`} variant="bodyBaseline" >
                  {isPrimaryAddress ? t("AddressCard.Favorite") : t("AddressCard.SelectFavorite")}
                  {!zipCodeInBlocklist && (
                  <StyledTooltip title={t("AddressCard.ToolTip")} placement="bottom">
                    <StyledIconButton className={"addressIcon"}>
                      <HelpIcon style={{ color: theme.palette.primary.main, width: "14px", height: "14px" }} />
                    </StyledIconButton>
                  </StyledTooltip>
                  )}
                </StyledTypography>
              }
              disabled={zipCodeInBlocklist}
              onChange={isPrimaryAddress ? null : () => handleChangePrimaryAddres()}
            />
              {((isB2B && cardActions?.length === 2) || (!isB2B && cardActions?.length > 0)) && (
              <>
                {isB2B &&
                  <StyledBox className="vertical-padding-2">
                    <Divider />
                  </StyledBox>
                }
                <StyledBox className={isB2B ? "account-card-links" : "divider"}>
                  <>
                    {reversedCardActions.map((v: any, index: number) =>
                      v.link ? (
                        <StyledGrid
                          item
                          xs={cardActions?.length === 1 ? 12 : 6}
                          className={"shipping--styledGrid-editSelectedAddress text-center"}>
                          <StyledLink to={v.link} key={v.text + "_" + index}>
                            {v.text}
                          </StyledLink>
                        </StyledGrid>
                      ) : (
                        <>
                          <StyledGrid
                            item
                            xs={cardActions?.length === 1 ? 12 : 6}
                            className={"shipping--styledGrid-editSelectedAddress text-center rigth-divider"}>
                            <StyledLinkButton
                              variant="bodyBaseline"
                              key={v.text + "_" + index}
                              // onClick={() => handeActionButtonClick(v, 1 )}
                              onClick={() =>
                                setOpenCardActionsPopUp((prevState) => ({ ...prevState, editButton: true }))
                              }>
                              {v.text}
                            </StyledLinkButton>
                          </StyledGrid>
                          <ModalSimpleDelete
                            openElinarDialog={editButton}
                            handleCloseEliminar={handleCloseEliminar}
                            handleAEliminarAction={v.handleClick}
                            data={""}
                            title={t("AddressBook.DeleteAddressPopUpMsgs.Title")}
                            subtitle={t("AddressBook.DeleteAddressPopUpMsgs.Body")}
                            buttonTitle={t("AddressBook.DeleteAddressPopUpMsgs.ConfirmButton")}
                          />
                        </>
                      )
                    )}
                  </>
                </StyledBox>
              </>
              )}
          </StyledBox>
        </StyledCardContainer>
      )}

      {/*cardActions?.length > 0 && (
                <StyledGrid item xs={false}>
                  <StyledCardActions >
                    {cardActions.map((v: any, index: number) =>
                      v.link ? (
                        <StyledLink to={v.link} key={v.text + "_" + index}>
                          {v.text}
                        </StyledLink>
                      ) : (
                        <StyledButton
                          testId={`StyledCard_${testId}_${v.text}_${index}`}
                          variant="text"
                          key={v.text + "_" + index}
                          onClick={() => handeActionButtonClick(v, index)}>
                          <StyledTypography variant="body1">{v.text}</StyledTypography>
                        </StyledButton>
                      )
                    )}
                  </StyledCardActions>
      
                  {confirmState && (
                    <StyledConfirmOverlay>
                      <StyledGrid
                        container
                        direction="column"
                        justifyContent="flex-end"
                        className="full-height horizontal-padding-2"
                        spacing={2}>
                        <StyledGrid item>
                          <StyledButton
                            testId={`StyledCard_${testId}_confirm`}
                            className="confirm-action-button"
                            variant="outlined"
                            fullWidth
                            onClick={handleConfirmButtonClick}>
                            {confirmLabel}
                          </StyledButton>
                        </StyledGrid>
                        <StyledGrid item>
                          <StyledButton
                            testId={`StyledCard_${testId}_cancel`}
                            className="cancel-action-button"
                            variant="outlined"
                            fullWidth
                            onClick={handleCancelButtonClick}>
                            {cancelLabel}
                          </StyledButton>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledConfirmOverlay>
                  )}
                </StyledGrid>
                  )*/}

      {/*cardActions?.length > 0 && (
          <StyledGrid item xs={false}>
            <StyledCardActions >
              {cardActions.map((v: any, index: number) =>
                v.link ? (
                  <StyledLink to={v.link} key={v.text + "_" + index}>
                    {v.text}
                  </StyledLink>
                ) : (
                  <StyledButton
                    testId={`StyledCard_${testId}_${v.text}_${index}`}
                    variant="text"
                    key={v.text + "_" + index}
                    onClick={() => handeActionButtonClick(v, index)}>
                    <StyledTypography variant="body1">{v.text}</StyledTypography>
                  </StyledButton>
                )
              )}
            </StyledCardActions>

            {confirmState && (
              <StyledConfirmOverlay>
                <StyledGrid
                  container
                  direction="column"
                  justifyContent="flex-end"
                  className="full-height horizontal-padding-2"
                  spacing={2}>
                  <StyledGrid item>
                    <StyledButton
                      testId={`StyledCard_${testId}_confirm`}
                      className="confirm-action-button"
                      variant="outlined"
                      fullWidth
                      onClick={handleConfirmButtonClick}>
                      {confirmLabel}
                    </StyledButton>
                  </StyledGrid>
                  <StyledGrid item>
                    <StyledButton
                      testId={`StyledCard_${testId}_cancel`}
                      className="cancel-action-button"
                      variant="outlined"
                      fullWidth
                      onClick={handleCancelButtonClick}>
                      {cancelLabel}
                    </StyledButton>
                  </StyledGrid>
                </StyledGrid>
              </StyledConfirmOverlay>
            )}
          </StyledGrid>
            )*/}
    </>
  );
};

export { StyledCard };
