/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
import Axios from "axios";
//Foundation Libraries

//UI
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import outgoingMailSvg from "../../../assets/homedepot/images/outgoingMail.svg";
import outgoingMailSvgGray from "../../../assets/homedepot/images/outgoingMail-gray.svg";
import {
  StyledBox,
  StyledButton,
  StyledCardEmpty,
  StyledContainer,
  StyledGrid,
  StyledIcon,
  StyledPaper,
  StyledTypography,
} from "../../elements";
import { Trans, useTranslation } from "react-i18next";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import { Navigate, useNavigate } from "react-router-dom";
import * as ROUTES from "../../../../src/constants/routes";
import { Divider, Hidden } from "@material-ui/core";
import { DownloableTYPContainer, OrderConfirmationContainer } from "./OrderConfirmationContainer";
import PhoneIcon from "@material-ui/icons/Phone";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MailIcon from "@material-ui/icons/Mail";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EventIcon from "@material-ui/icons/Event";
import RoomIcon from "@material-ui/icons/Room";
import { BopisAddressCard } from "../../../components/widgets/bopis-address-card";
import { OrderItemTable } from "../../../components/widgets/order-item-table";
import { StyledCartContainer } from "../../../components/widgets/cart/StyledCartContainer";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";
import { OrderTotalSummary } from "../../../components/widgets/order-total-summary";
import { PAYMENT } from "../../../constants/order";
import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";
import { useSite } from "../../../_foundation/hooks/useSite";
import { useDispatch, useSelector } from "react-redux";
import { guestStatusSelector, loginStatusSelector } from "../../../redux/selectors/user";
import { DeliveryAddressCard } from "../../../components/widgets/delivery-address-card";
import { CONSTANTS } from "../../../constants/order-item-table";
import { addressDetailsSelector } from "../../../redux/selectors/account";
import { onlineStoreRdc, guestUserDataSelector } from "../../../redux/selectors/hdmData";
import { GiftCard } from "./paymentMethod/giftCard";
import { OpenPay } from "./paymentMethod/openPay";
import { Cod } from "./paymentMethod/cod";
import { Paypal } from "./paymentMethod/paypal";
import { OxxoPay } from "./paymentMethod/oxxoPay";
import { Cybersource } from "./paymentMethod/cybersource";
import ProductoMiOrden from "../../../components/pages/_sapphire/order/ProductoMiOrden";
import { palette } from "../../themes";
import ProductosMiOrdenCard from "../../../components/pages/_sapphire/order/ProductosMiOrdenCard";
import TitleCard from "../../../components/custom-components/styled-title-card/TitleCard";
import PdfDownloader from "../../../components/custom-components/shared/generic-pdf-downloader/PdfDownloader";
import DownloadTYP from "./DownloadTYP";
import useScript from "../../../components/custom-components/styled-carousel-hdm/useScript";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { LOGOUT_REQUESTED_ACTION } from "../../../redux/actions/user";
import { SLThankYouLayout } from "../../../components/custom-components/skeleton-loader/components/elements/ThankYou";
import { logEvent } from "../../functions/utils";
import { EMPTY_STRING } from "../../../constants/common";
import CorporateFare from "../../../assets/homedepot/images/corporate-fare";

const mesesAbreviados = {
  ene: "01",
  feb: "02",
  mar: "03",
  abr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  ago: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dic: "12",
};

const mesesAbreviadosLetras = {
  "01": "ene",
  "02": "feb",
  "03": "mar",
  "04": "abr",
  "05": "may",
  "06": "jun",
  "07": "jul",
  "08": "ago",
  "09": "sep",
  "10": "oct",
  "11": "nov",
  "12": "dic",
};
interface OrderConfirmationProps {
  isOrderSubmitted: boolean;
  isOrderApproved: boolean;
  isOrderPending: boolean;
  orderData: any;
  ORDERCONFIRMATION_TITLE: string;
  ORDER_CONFIRMATION_HEADING: string;
  ORDER_CONFIRMATION_PENDING_MSG: string;
  ORDER_NUMBER_MSG: string;
  EMAIL_CONFIMRATION_MSG: string;
  PENDING_DETAILS_MSG: string;
  THANK_YOU_MSG: string;
}

/**
 * Order confirmation widget.
 * For props definition, @see {@link OrderConfirmationProps}.
 * @param props The props for order confirmation widget.
 */
export const OrderConfirmationWidget: React.FC<any> = (props: OrderConfirmationProps) => {
  const {
    isOrderSubmitted,
    isOrderApproved,
    orderData,
    isOrderPending,
    ORDERCONFIRMATION_TITLE,
    ORDER_CONFIRMATION_HEADING,
    ORDER_CONFIRMATION_PENDING_MSG,
    ORDER_NUMBER_MSG,
    EMAIL_CONFIMRATION_MSG,
    PENDING_DETAILS_MSG,
    THANK_YOU_MSG,
  } = props;

  const navigate = useNavigate();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const storeConfData = useSelector(onlineStoreRdc);
  const addressDetails = useSelector(addressDetailsSelector);
  const guestUserData = useSelector(guestUserDataSelector);
  // const orderDataTYP = window.orderItemsTYP ? window.orderItemsTYP : [];
  const orderDataTYP = orderData?.orderItem;
  const cartTYP = window.cartTYP;
  const payMethod = orderData?.paymentInstruction?.[0]?.payMethodId;
  const isCreditAllowed = isB2B && !!orderData?.paymentInstruction?.[0]?.protocolData?.find(
    (data) => data?.name == "isCreditAllowed" && data?.value == "true"
  );
  const addressNickName = orderData?.orderItem && orderData.orderItem.length > 0 ? orderData.orderItem[0].nickName : "";
  const { t } = useTranslation();

  const oxxoDrawerTitle = isCreditAllowed
    ? t("OrderConfirmation.OrderComplete.CreditAr.DrawerTitle")
    : t("OrderConfirmation.OrderComplete.Oxxo.DrawerTitle");
  const [isOpen, setIsOpen] = useState<any>(false);
  const userLoggedIn = useSelector(loginStatusSelector);
  const [deliveryDates, setDeliveryDates] = useState<any>({});
  const [productData, setProductData] = useState<any>({});
  const [ordenDataTYP, setOrdenDataTYP] = useState<any>(null);
  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [oiFmtDeliveryDetails, setOiFmtDeliveryDetails] = useState<any>(null);
  const [deliveryAddres, setDeliveryAddres] = useState<any>("");

  const isGuest = useSelector(guestStatusSelector);
  const dispatch = useDispatch();

  useScript(!window?.$BV?.SI ? `https:${storeConfData.BAZAARVOICE_URL}` : "", "")

  const [isBTLoaded, setIsBTLoaded] = useState<boolean>(false);
  if (userLoggedIn) {
    const cookieName = "WC_confirmationPageTypeValue";
    const cookieVal = "orderShippingBillingConfirmation";
    document.cookie = `${cookieName}=${cookieVal};expires=Session;path=/;`;
  }

  let isCreditAR

  if(payMethod === "PayInStore" && orderData?.paymentInstruction?.[0]?.protocolData){
    isCreditAR = orderData?.paymentInstruction?.[0]?.protocolData.find(protocol => protocol.name === "isCreditAllowed")
  }

  let homeDeliveryDates: any = [];
  let pickUpDates: any = [];
  let installationPartNumbers: any = [];
  // console.log("orderData", orderData, payMethod);
  // console.log("addressDetails", userLoggedIn);

  let deliveryMethod = "";
  deliveryMethod = CONSTANTS.delivery; //delivery||bopis||split
  //console.log('order-confirmation-widget - deliveryMethod: ', deliveryMethod);
  if (orderDataTYP?.length > 0) {
    let selectDelivery = "";
    let both = false;
    for (let i = 0; i < orderDataTYP.length; i++) {
      if (selectDelivery === "") {
        selectDelivery = orderDataTYP[i].carrier;
      } else {
        if (selectDelivery !== orderDataTYP[i].carrier) {
          both = true;
        }
      }
      if (!both) {
        deliveryMethod = selectDelivery;
      } else {
        deliveryMethod = CONSTANTS.split;
      }
    }
  }

  useEffect(() => {
    if (orderData && orderData?.orderItem) {
      getArticulos();
      logEvent("orderCompleted");
    }
  }, [orderData]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    if (orderDataTYP?.length === 0) {
      //handleHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ordenDataTYP?.orderItem && storeConfData?.BAZAARVOICE_ANALYTICS === "true") {
      console.log("entro");

      sendBVTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordenDataTYP, storeConfData]);

  useEffect(() => {
    if (orderData?.orderId) {
      Axios.get(
        `/wcs/resources/store/${mySite.storeID}/hdm/order/${orderData?.orderId}/deliveryDetails?responseFormat=json&checkoutPage=false&typSort=true&itemDeliveryType=Fleet&confirmationPage=true`
      )
        .then((res) => {
          if (res.status === 200 && res?.data?.fmtDeliveryRanges) {
            if (res.data.fmtDeliveryRanges.length !== 0) {
              setOiFmtDeliveryDetails(res.data.oiFmtDeliveryDetails);
            }
          }
          setDeliveryDates(res.data);

          if (isGuest) {
            dispatch(LOGOUT_REQUESTED_ACTION({}));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  // useEffect(() => {

  //   return () => {
  //     if (isGuest) {
  //       dispatch(LOGOUT_REQUESTED_ACTION({}));
  //     }
  //   }
  // }, [])

  const sendBVTag = () => {
    if (ordenDataTYP?.orderItem && ordenDataTYP?.orderItem.length > 0) {
      const orderItems = ordenDataTYP.orderItem;
      const items: any = [];
      let taxRate: any = "";
      for (let i = 0; i < orderItems.length; i++) {
        if (!orderItems[i].partNumber.includes("SI") && !orderItems[i].partNumber.includes("TV")) {
          items.push({
            quantity: parseInt(orderItems[i].quantity).toString(),
            price: parseFloat(orderItems[i].unitPrice).toFixed(2),
            name: orderItems[i].name,
            sku: orderItems[i].partNumber,
            category: orderItems[i]?.categoryName,
          });
          if (orderItems[i].xitem_taxRate != null && orderItems[i].xitem_taxRate != "" && taxRate === "") {
            taxRate = orderItems[i].xitem_taxRate;
          }
          console.log("");
        }
      }
      const totalTaxes = parseFloat(ordenDataTYP.totalSalesTax) + parseFloat(ordenDataTYP.totalShippingTax);

      let bazzarEmail = "";
      let nickname = "";
      if (ordenDataTYP?.orderItem[0]?.firstName) {
        nickname = ordenDataTYP?.orderItem[0]?.firstName;
      }
      if (nickname && nickname !== "") {
        nickname = nickname + " " + ordenDataTYP?.orderItem[0]?.lastName;
      } else {
        nickname = ordenDataTYP?.orderItem[0]?.lastName;
      }
      if (nickname === "") {
        nickname = ordenDataTYP.x_pickUpPerson;
      }
      if (
        orderData &&
        orderData?.paymentInstruction &&
        orderData?.paymentInstruction?.length !== 0 &&
        orderData?.paymentInstruction[0]?.email1 &&
        orderData?.paymentInstruction[0]?.email1 !== ""
      ) {
        bazzarEmail = orderData?.paymentInstruction[0]?.email1;
      } else if (
        ordenDataTYP &&
        ordenDataTYP?.orderItem?.length !== 0 &&
        ordenDataTYP?.orderItem[0]?.email1 &&
        ordenDataTYP?.orderItem[0]?.email1 !== ""
      ) {
        bazzarEmail = ordenDataTYP?.orderItem[0]?.email1;
      } else if (guestUserData && guestUserData?.email) {
        bazzarEmail = guestUserData?.email;
      }
      const totalDiscounts = ordenDataTYP.totalAdjustment ? parseFloat(ordenDataTYP.totalAdjustment) : parseFloat("0");
      const totalDiscountsWithTax = ordenDataTYP.totalAdjustment
        ? (parseFloat(ordenDataTYP.totalAdjustment) +
            parseFloat(ordenDataTYP.totalAdjustment) * (parseFloat(taxRate) / 100)) *
          -1
        : parseFloat("0");
      const totalProductPrice = parseFloat(ordenDataTYP.totalProductPrice) + totalDiscounts;
      const totalShippingCharge = parseFloat(ordenDataTYP?.totalShippingCharge).toFixed(2);
      const totalTaxesTwoDecimals = totalTaxes.toFixed(2);
      const bvBody = {
        orderId: ordenDataTYP.orderId,
        tax: totalTaxesTwoDecimals,
        shipping: totalShippingCharge,
        total: totalProductPrice.toFixed(2),
        discount: totalDiscountsWithTax.toFixed(2),
        state: ordenDataTYP.orderItem[0].state,
        city: ordenDataTYP.orderItem[0].city,
        country: "México",
        currency: "MXN",
        items: items,
        userId: ordenDataTYP.buyerId,
        email: bazzarEmail,
        nickname: nickname,
        locale: "es_MX",
        deploymentZone: "main_site",
      };
      if (window?.$BV?.SI) {
        window.$BV.SI.trackTransactionPageView(bvBody);
      }
    }
  };

  const getArticulos = async () => {
    if (orderData?.orderItem && orderData.orderItem.length !== 0) {
      const { 0: calle, 1: numeroExt, 2: colonia } = orderData.orderItem[0].addressLine;
      const addres = `${calle} #${numeroExt}, ${colonia}, ${orderData.orderItem[0].city}, ${orderData.orderItem[0].state}, C.P. ${orderData.orderItem[0].zipCode} `;
      setDeliveryAddres(addres);
    }

    const articulosIds: any = [];
    orderData.orderItem.map((item) => {
      articulosIds.push(item.productId);
    });

    let stringIds = "?";
    articulosIds.map((art) => {
      stringIds = `${stringIds}id=${art}&`;
    });

    orderData.hasAdicional = false;
    // if(orderData?.x_firstName && orderData?.x_lastName){
    const primaryPerson = `${orderData?.paymentInstruction[0].firstName}${orderData?.paymentInstruction[0].lastName}`;
    const primaryPhone = orderData?.paymentInstruction[0].phone1;
    // const contacto = `${orderData.x_firstName.replace(/ /g,'')}${orderData.x_lastName.replace(/ /g,'')}`
    const adicional = orderData?.x_pickUpPerson.replace(/ /g, "");
    const adicionalPhone = orderData?.x_pickUpContact.replace(/ /g, "");
    // console.log('contacto', contacto)
    if ((primaryPerson.trim() === adicional.trim()) && (primaryPhone.trim() === adicionalPhone.trim())) {
      orderData.hasAdicional = false;
    } else {
      orderData.hasAdicional = true;
    }
    // }
    await Axios.get<any>(`/search/resources/store/${mySite.storeID}/productview/byIds${stringIds}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const responseItems = response.data?.catalogEntryView;
          setProductData(responseItems);
          const orderDataItems = JSON.parse(JSON.stringify(orderData));

          const productItems: any = [];
          orderDataItems.orderItem.map((item) => {
            const responseItem = responseItems.filter((resItem) => resItem.partNumber === item.partNumber)[0];

            productItems.push({
              item: "",
              price: "",
              quantity: "",
            });

            if (responseItem?.parentCatalogGroupID) {
              const arrayCategory: any = [];
              for (let i = 0; i < responseItem.parentCatalogGroupID.length; i++) {
                const splitCatName = responseItem.parentCatalogGroupID[i].split("_");
                if (splitCatName.length > 0) {
                  arrayCategory.push("category." + splitCatName[0] + "-" + splitCatName[1] + ".name");
                }
              }
              for (let i = 0; i < arrayCategory.length; i++) {
                if (responseItem[arrayCategory[i]] !== undefined) {
                  item.categoryName = responseItem[arrayCategory[i]];
                  break;
                }
              }
            }
            if (item.partNumber.includes("SI")) {
              item.thumbnail = "";
              item.manufacturer = "";
              item.name = responseItem.name;
              item.shortDescription = responseItem.shortDescription;
              item.shortDescription = responseItem.shortDescription;
            } else {
              item.thumbnail = `${storeConfData?.IMAGE_SERVER_HOSTNAME}productos/${item.partNumber}/${item.partNumber}-m.jpg`;
              item.manufacturer = responseItem.manufacturer;
              item.name = responseItem.name;
              item.isInstalacion = responseItem.isInstalacion;
            }
          });

          setOrdenDataTYP(orderDataItems);
        }
      })
      .catch(function (e) {
        console.log("another error happened:" + e.message);
      });
  };

  const handleHome = () => {
    navigate(ROUTES.HOME);
  };
  const handleCart = () => {
    navigate(ROUTES.CART);
  };

  const handleOrderHistory = () => {
    if(isMobileApp) {
      logEvent("goToOrderHistory");
    }else {
      if(isB2B) {
        return window.open(ROUTES.ORDER_HISTORY.substring(1), "_blank", "rel=noopener noreferrer");
      }
      navigate(ROUTES.ORDER_HISTORY);
    }
  };
  const checkout = () => {
    console.log("none");
  };

  const canContinue = () => {
    // console.log("none");
  };

  //console.log("order-confirmation-widget : EstimatedDeliveryDate: ", EstimatedDeliveryDate);

  if (!oiFmtDeliveryDetails || !ordenDataTYP) return <SLThankYouLayout />;

  const items = orderData.orderItem;

  const allOrderItems = JSON.parse(JSON.stringify(ordenDataTYP.orderItem));

  const instalacionesOrders = allOrderItems.filter((order) => order.partNumber.includes("SI"));

  if (instalacionesOrders && instalacionesOrders.length !== 0) {
    installationPartNumbers = instalacionesOrders.map((product) => product.partNumber.split("_")[2]);
  }

  allOrderItems.map((item) => {
    if (item.partNumber.includes("SI")) {
      return;
    }

    if (installationPartNumbers.includes(item.partNumber)) {
      item.x_hasInstallation = true;
    }
    let splitDeliveryDate = oiFmtDeliveryDetails[item.partNumber];

    if (splitDeliveryDate) {
      splitDeliveryDate = splitDeliveryDate.split("/");
    } else {
      return;
    }

    const deliveryDate_ymd = `${splitDeliveryDate[2].trim().substring(0, 4)}-${
      mesesAbreviados[splitDeliveryDate[1].trim()]
    }-${splitDeliveryDate[0].trim()}`;
    const customDate = new Date(deliveryDate_ymd);
    customDate.setHours(0);
    const hdm_deliveryDateFormat = `${splitDeliveryDate[0].trim()} ${splitDeliveryDate[1].trim()} ${splitDeliveryDate[2]
      .trim()
      .substring(0, 4)}`;
    item.hdm_deliveryDateSeconds = Math.floor(customDate.getTime() / 1000);
    item.hdm_deliveryDateFormat = hdm_deliveryDateFormat;
  });

  allOrderItems.sort((a, b) => a.hdm_deliveryDateSeconds - b.hdm_deliveryDateSeconds);

  allOrderItems.map((item) => {
    // const datesRange = deliveryDates && item?.partNumber ? deliveryDates?.oiFmtDeliveryDetails[item?.partNumber]?.split(" - ") : "";
    // const datesRangeFormat = datesRange && datesRange[0] === datesRange[1] ? datesRange[0] : deliveryDates?.oiFmtDeliveryDetails[item?.partNumber]
    const rawEstimatedDeliveryDate =
      deliveryDates && deliveryDates?.oiFmtDeliveryDetails && deliveryDates?.oiFmtDeliveryDetails[item?.partNumber]
        ? deliveryDates?.oiFmtDeliveryDetails[item?.partNumber].replace(/\//g, "")
        : "";
    let EstimatedDeliveryDate =
      deliveryDates && deliveryDates?.oiFmtDeliveryDetails && deliveryDates?.oiFmtDeliveryDetails[item?.partNumber]
        ? deliveryDates?.oiFmtDeliveryDetails[item?.partNumber].replace(/\//g, "")
        : "";

    if (rawEstimatedDeliveryDate && /-/g.test(rawEstimatedDeliveryDate)) {
      const firstDate = rawEstimatedDeliveryDate
        .split("-")[0]
        .split(" ")
        .filter((n) => n);
      const SecondDate = rawEstimatedDeliveryDate
        .split("-")[1]
        .split(" ")
        .filter((n) => n);

      // If the dates are the same day, month and year
      if (firstDate[0] === SecondDate[0] && firstDate[1] === SecondDate[1] && firstDate[2] === SecondDate[2]) {
        EstimatedDeliveryDate = firstDate.join(" ");
      }
      // If the dates are in the same month and same year
      else if (firstDate[1] === SecondDate[1] && firstDate[2] === SecondDate[2]) {
        EstimatedDeliveryDate = `${firstDate.slice(0, 1)} - ${SecondDate.join(" ")}`;
      }
      // If the dates are in the different months and same year
      else if (firstDate[1] !== SecondDate[1] && firstDate[2] === SecondDate[2]) {
        EstimatedDeliveryDate = `${firstDate.slice(0, 2).join(" ")} - ${SecondDate.join(" ")}`;
      }
      // If the dates are different  years
      else if (firstDate[2] !== SecondDate[2]) {
        EstimatedDeliveryDate = `${firstDate.slice(0, 3).join(" ")} - ${SecondDate.join(" ")}`;
      } else {
        EstimatedDeliveryDate = `${firstDate.slice(0, 2).join(" ")} - ${SecondDate.join(" ")}`;
      }
    }

    item.hdm_deliveryDateFormat = EstimatedDeliveryDate;

    //xitem_shopCartItemdate_BOPIS Format
    let shopCartItemdate_BOPIS;

    if (item && item?.xitem_shopCartItemdate_BOPIS && item?.shipModeCode === "PickupInStore"){
      const bopisDate = item && item?.xitem_shopCartItemdate_BOPIS !== "" && item?.xitem_shopCartItemdate_BOPIS?.split('_').pop().replace(/-/g, " ");
      const [startYear, startMonth, startDay] = bopisDate?.split(" ");
      shopCartItemdate_BOPIS = `${startDay} ${mesesAbreviadosLetras[startMonth]} ${startYear}`;
      item.xitem_shopCartItemdate_BOPIS = shopCartItemdate_BOPIS;
    }

    if (item.shipModeCode === "Mail" && !item.partNumber.includes("SI")) {
      homeDeliveryDates.push(item.hdm_deliveryDateFormat);
    }
    if (item.shipModeCode === "PickupInStore" && !item.partNumber.includes("SI")) {
      pickUpDates.push(item.xitem_shopCartItemdate_BOPIS);
      // pickUpDates.push(item.hdm_deliveryDateFormat);
    }
    return true;
  });

  homeDeliveryDates = homeDeliveryDates.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  pickUpDates = pickUpDates.filter(function (elem, index, self) {
    return index === self.indexOf(elem);
  });

  const pickUpStoreOrders = allOrderItems.filter(
    (order) => order.shipModeCode === "PickupInStore" && !order.partNumber.includes("SI")
  );
  const homeDeliveryOrders = allOrderItems.filter(
    (order) => order.shipModeCode === "Mail" && !order.partNumber.includes("SI")
  );

  // console.log("homeDeliveryOrders", homeDeliveryOrders);
  // console.log("homeDeliveryDates", homeDeliveryDates);
  // console.log("pickUpStoreOrders", pickUpStoreOrders);
  // console.log("instalacionesOrdersx", instalacionesOrders);
  // console.log("pickUpDates", pickUpDates);

  const handleContentDrawerOxxo = (): string => {
    let espot = "";
    if(payMethod === PAYMENT.paymentMethodName.oxxo) {
      espot = "ThankYouPage_Oxxo-Drawer-Content";
    } else if(payMethod === PAYMENT.paymentMethodName.cod) {
      espot = "ThankYouPage_PayAtStore-Drawer-Content";
    } else if(payMethod === PAYMENT.paymentMethodName.openPay) {
      espot = "ThankYouPage_PayByTransfer-Drawer-Content";
    } 
    
    if(isCreditAllowed) {
      espot = "ThankYouPage_AR-Drawer-Content";
    }

    if(isB2B) {
      espot = "PRO_" + espot;
    }

    return espot;
  }

  const drawerOxxo = (
    <StyledSwipeableDrawerHDM
      isOpen={isOpen}
      setOpenDrawer={handleIsOpen}
      variant={isCreditAllowed ? "instructions-creditAr" : "instructions-oxxo"}
      title={oxxoDrawerTitle}>
      <>
        <EspotHdm espotName={handleContentDrawerOxxo()} />
        {payMethod === "Oxxo" || payMethod === "PayInStore" || payMethod === "OpenPay" ? (
          <PdfDownloader isOpen={isOpen} downloadFileName="order-instructions" rootElementId="typ-download-pdf" />
        ) : null}
      </>
    </StyledSwipeableDrawerHDM>
  );

  const paymentMethod = (
    <>
      <StyledPaper id={"typ--payment-container"} className="vertical-padding-2 horizontal-padding-2 top-margin-4">
        <StyledTypography variant="textSegment" align="center" gutterBottom>
          {t("OrderConfirmation.PaymentMethod.title")}
        </StyledTypography>
        <StyledGrid className={isB2B && !isCreditAR ? "vertical-padding-2" : EMPTY_STRING}>
          <Divider className={isB2B && !isCreditAR ? EMPTY_STRING : "vertical-margin-2"}/>
        </StyledGrid>
        {orderData && orderData?.paymentInstruction?.length > 0 && (
          <>
            {orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.oxxo && (
              <OxxoPay orderData={orderData} orderDataTYP={orderDataTYP} storeConfData={storeConfData} />
            )}
            {orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.giftCard && (
              <GiftCard orderData={orderData} orderDataTYP={orderDataTYP} storeConfData={storeConfData} />
            )}
            {orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.openPay && (
              <OpenPay orderData={orderData} orderDataTYP={orderDataTYP} storeConfData={storeConfData} />
            )}
            {orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.cod && (
              <Cod orderData={orderData} orderDataTYP={orderDataTYP} storeConfData={storeConfData} />
            )}
            {orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.paypal && (
              <Paypal orderData={orderData} orderDataTYP={orderDataTYP} storeConfData={storeConfData} />
            )}
            {(orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.visa ||
              orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.master ||
              orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.carnet ||
              orderData?.paymentInstruction[0]?.payMethodId === PAYMENT.paymentMethodName.amex) && (
              <Cybersource orderData={orderData} orderDataTYP={orderDataTYP} storeConfData={storeConfData} />
            )}
          </>
        )}
      </StyledPaper>
    </>
  );

  const productsOrder = (
    <>
      <StyledPaper className="vertical-padding-2 horizontal-padding-2">
        <StyledTypography variant="textSegment" align="center" gutterBottom>
          {t("OrderConfirmation.OrderProducts.title")}
        </StyledTypography>
        <Divider className="vertical-margin-2" />
        {(deliveryMethod === CONSTANTS.bopis || deliveryMethod === CONSTANTS.split) && (
          <>
            <StyledBox display="flex" alignItems="center">
              <StyledTypography variant="bodyBaseline" component={"span"} className="top-margin-4">
                {t("OrderConfirmation.OrderProducts.PickUp")}
              </StyledTypography>
              <Divider orientation="vertical" style={{ height: "15px" }} className="horizontal-margin-2" />
              {deliveryDates?.fmtDeliveryRanges && deliveryDates?.fmtDeliveryRanges.length > 0 && (
                <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
                  {deliveryDates.fmtDeliveryRanges[0].replace(/\//g, "")} - 17:00 hrs
                </StyledTypography>
              )}
            </StyledBox>
            <Divider className="top-margin-2" />
            <StyledCartContainer>
              <OrderItemTable data={orderDataTYP} readOnly={true} variant={"TYP"} deliveryMethod={CONSTANTS.bopis} />
            </StyledCartContainer>
          </>
        )}
        {(deliveryMethod === CONSTANTS.delivery || deliveryMethod === CONSTANTS.split) && (
          <>
            <StyledBox display="flex" alignItems="center" className={"top-margin-2"}>
              <StyledTypography variant="bodyBaseline" component={"span"}>
                {t("OrderConfirmation.OrderProducts.HomeDeliveryTYP")}
              </StyledTypography>
              <Divider orientation="vertical" style={{ height: "15px" }} className="horizontal-margin-2" />
              {deliveryDates?.fmtDeliveryRanges && deliveryDates?.fmtDeliveryRanges.length > 0 && (
                <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
                  {deliveryDates.fmtDeliveryRanges[0].replace(/\//g, "")}
                </StyledTypography>
              )}
            </StyledBox>
            <Divider className="top-margin-2" />
            <StyledCartContainer>
              <OrderItemTable data={orderDataTYP} readOnly={true} variant={"TYP"} deliveryMethod={CONSTANTS.delivery} />
            </StyledCartContainer>
          </>
        )}
      </StyledPaper>
      <StyledPaper className="vertical-padding-2 horizontal-padding-2 top-margin-2">
        <OrderTotalSummary
          order={cartTYP}
          canContinue={canContinue}
          checkout={checkout}
          isTYP={true}
          hideTotals={true}
        />
      </StyledPaper>
    </>
  );
  const orderDetailDownload = (
    <StyledCardEmpty className={"top-margin-4 vertical-padding-2 horizontal-padding-2"}>
      <StyledGrid container direction="column" alignItems="center" className="">
        {/*(isOrderSubmitted || isOrderApproved) && (
                  <StyledGrid item>
                    <StyledIcon icon={<CheckCircleIcon style={{ fontSize: 75 }} />} iconSize={40} backgroundSize={40} />
                  </StyledGrid>
                )*/}
        <StyledGrid item>
          <StyledTypography variant="headingFeatured" align="center">
            {t("OrderConfirmation.OrderComplete.Default.OrderComplete")}
          </StyledTypography>
        </StyledGrid>
        <StyledBox className="vertical-margin-2 flexAlignItems">
          <MailIcon color="primary" className={"orderConfirmation--mailIcon"} />
          <StyledBox>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.YouWillRecive") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.Notification") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.By") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.Mail") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.With") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.DeliveryDate") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Default.Products")}
            </StyledTypography>
          </StyledBox>
        </StyledBox>
        {/* <StyledButton color="primary" variant="outlined" className="top-margin-1 styledButton--margin-none">
          {t("OrderConfirmation.OrderComplete.Default.DownloadOrderDetail")}
        </StyledButton> */}

        {payMethod === "PayPal" ||
        payMethod === "VISA" ||
        payMethod === "AMEX" ||
        payMethod === "Master Card" ||
        payMethod === "mastercard" ||
        payMethod === "GiftCard" && isB2B ||
        payMethod === "master card" ? (
          <PdfDownloader
            isOpen={""}
            downloadFileName="order-instructions"
            rootElementId="typ-download-pdf"
            buttonText={t("OrderConfirmation.OrderComplete.Default.DownloadOrderDetail")}
          />
        ) : null}
      </StyledGrid>
    </StyledCardEmpty>
  );

  const orderDetailInstructions = (
    <StyledCardEmpty
      id={"typ--instructions-container"}
      className={"top-margin-4 vertical-padding-2 horizontal-padding-2"}>
      <StyledGrid container direction="column" alignItems="center" className="">
        <StyledGrid item>
          <StyledTypography variant="headingFeatured" align="center">
            {isCreditAR ?
            t("OrderConfirmation.OrderComplete.CreditAR.OrderForApprove")
            :
            t("OrderConfirmation.OrderComplete.Oxxo.CompleteYourOrder")
            }
          </StyledTypography>
        </StyledGrid>
        <StyledBox display={"flex"} className={`vertical-margin-2 ${isCreditAR ? "bottom-margin-3" : ""} `} alignItems={"center"}>
          <TimerOutlinedIcon color="primary" className={"orderConfirmation--timerOutlinedIcon"} />
          <StyledBox>
          {isCreditAR ?
          <>
            <StyledTypography variant="bodyBaseline" component={"span"}>
            {t("OrderConfirmation.OrderComplete.CreditAR.El")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
            {" " + t("OrderConfirmation.OrderComplete.CreditAR.MainContact")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component={"span"}>
            {" " + t("OrderConfirmation.OrderComplete.CreditAR.AccountHas")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
            {" " + t("OrderConfirmation.OrderComplete.CreditAR.Hours")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component={"span"}>
            {" " + t("OrderConfirmation.OrderComplete.CreditAR.ApproveOrder")}
            </StyledTypography>
          </>
          :
          <>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              {t("OrderConfirmation.OrderComplete.Oxxo.CompleteYourOrderPeriod") + " "}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight="semibold" component={"span"}>
              {storeConfData?.oxxoPaymentExpiryHrs + " " + t("OrderConfirmation.OrderComplete.Oxxo.hrs")}
            </StyledTypography>
          </>
          }
          </StyledBox>
        </StyledBox>
        <StyledButton
          id="instructions-button"
          onClick={() => handleIsOpen()}
          color="primary"
          variant="outlined"
          className="top-margin-3 styledButton--margin-none">
          {t("OrderConfirmation.OrderComplete.Oxxo.showInstructions")}
        </StyledButton>
      </StyledGrid>
    </StyledCardEmpty>
  );

  const deliveryMethodContent = (
    <StyledCardEmpty
      id={"typ--deliveryMethod-container"}
      className="top-margin-4 vertical-padding-2 horizontal-padding-2">
      <StyledTypography variant="textSegment" align="center" gutterBottom>
        {t("OrderConfirmation.DeliveryMethod.title")}
      </StyledTypography>
      <StyledBox className="vertical-padding-2">
        <Divider />
      </StyledBox>
      <StyledGrid xs={12}>
        <StyledTypography variant="bodyBaseline" weight="semibold">
          {t("OrderConfirmation.DeliveryMethod.DeliveryContact")}
        </StyledTypography>
        {orderData?.paymentInstruction && (
          <>
            <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
              <AccountCircleIcon
                className={isB2B ? "orderConfirmation--icon-mediumGray" : "orderConfirmation--icon-lightGray"}
              />
              <StyledTypography variant="bodyBaseline">
                {addressDetails?.firstName
                  ? addressDetails.firstName + " " + addressDetails?.lastName
                  : orderData?.paymentInstruction[0]?.firstName
                  ? orderData?.paymentInstruction[0]?.firstName + " " + orderData?.paymentInstruction[0]?.lastName
                  : orderData?.x_pickUpPerson}
              </StyledTypography>
            </StyledGrid>
            <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
              <PhoneIcon
                className={isB2B ? "orderConfirmation--icon-mediumGray" : "orderConfirmation--icon-lightGray"}
              />

              <StyledTypography variant="bodyBaseline">
                {addressDetails?.phone1
                  ? addressDetails?.phone1
                  : orderData?.paymentInstruction[0]?.phone1
                  ? orderData?.paymentInstruction[0]?.phone1
                  : orderData?.x_pickUpContact}
              </StyledTypography>
            </StyledGrid>
          </>
        )}

        {userLoggedIn &&
        ordenDataTYP?.hasAdicional &&
        (addressDetails || isB2B) &&
        orderData &&
        (addressDetails?.firstName + " " + addressDetails?.lastName !== orderData?.x_pickUpPerson || isB2B) ? (
          <>
            <StyledTypography variant="bodyBaseline" weight="semibold" className={"top-margin-2"}>
              {t("OrderConfirmation.DeliveryMethod.DeliveryContactSecondary")}
            </StyledTypography>
            <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
              <AccountCircleIcon
                className={isB2B ? "orderConfirmation--icon-mediumGray" : "orderConfirmation--icon-lightGray"}
              />
              <StyledTypography variant="bodyBaseline">{orderData?.x_pickUpPerson}</StyledTypography>
            </StyledGrid>
            <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
              <PhoneIcon
                className={isB2B ? "orderConfirmation--icon-mediumGray" : "orderConfirmation--icon-lightGray"}
              />
              <StyledTypography variant="bodyBaseline">{orderData?.x_pickUpContact}</StyledTypography>
            </StyledGrid>
          </>
        ) : null}
        {/* {deliveryMethod === CONSTANTS.split && (
          <StyledBox className="vertical-padding-2">
            <Divider />
          </StyledBox>
        )} */}
        {((deliveryMethod === CONSTANTS.bopis && pickUpDates.length !== 0) ||
          (pickUpDates.length !== 0 && deliveryMethod === CONSTANTS.split)) && (
          <>
            <StyledBox className="vertical-padding-2">
              <Divider />
            </StyledBox>
            <StyledTypography variant="bodyBaseline" weight="semibold" className="top-margin-2">
              {t("OrderConfirmation.DeliveryMethod.PickUp")}
            </StyledTypography>
            <StyledBox
              display="flex"
              className={`top-margin-2 orderConfirmation--styledGrid-contactInfoContainer ${
                isB2B ? "orderConfirmation--styledGrid-contactInfoContainer-B2B" : ""
              }`}>
              <StyledGrid>
                <EventIcon color="primary" className={"orderConfirmation--icon-general"} />
              </StyledGrid>
              <StyledGrid>
                {pickUpDates.length !== 0 &&
                  pickUpDates.map((date) => {
                    return (
                      <StyledGrid className="flexRow">
                        <StyledTypography variant="bodyBaseline" component="span">
                          {t("OrderConfirmation.DeliveryMethod.From") + " "}
                        </StyledTypography>
                        <StyledTypography
                          variant="bodyBaseline"
                          weight="semibold"
                          component="span"
                          className="orderConfirmation-eta">
                          {date}{" "}
                        </StyledTypography>
                      </StyledGrid>
                    );
                  })}
              </StyledGrid>
            </StyledBox>
            <StyledBox className="top-margin-1">
              <BopisAddressCard
                divider={false}
                readOnlyShipping={true}
                variant={"typ"}
                stepActive={3}
                deliveryDates={deliveryDates}
              />
            </StyledBox>
          </>
        )}
        {((deliveryMethod === CONSTANTS.delivery && homeDeliveryDates.length !== 0) ||
          (deliveryMethod === CONSTANTS.split && homeDeliveryDates.length !== 0)) && (
          <>
            <StyledBox className="vertical-padding-2">
              <Divider />
            </StyledBox>
            <StyledTypography variant="bodyBaseline" weight="semibold" className="top-margin-2">
              {t("OrderConfirmation.DeliveryMethod.HomeDelivery")}
            </StyledTypography>
            <StyledBox display="flex" className={`top-margin-2 orderConfirmation--styledGrid-contactInfoContainer ${
                isB2B ? "orderConfirmation--styledGrid-contactInfoContainer-B2B" : ""
              }`}>
              <StyledGrid className="align-start">
                <EventIcon color="primary" className={"orderConfirmation--icon-general"} />
              </StyledGrid>
              <StyledGrid>
                {homeDeliveryDates.length !== 0 &&
                  homeDeliveryDates.map((date) => {
                    return (
                      <StyledGrid className="flexRow">
                        <StyledTypography variant="bodyBaseline" component="span">
                          {t(
                            `OrderConfirmation.DeliveryMethod.Delivery${
                              date.includes("-") ? "Date" : EMPTY_STRING
                            }`
                          ) + " "}
                        </StyledTypography>
                        <StyledTypography
                          variant="bodyBaseline"
                          weight="semibold"
                          component="span"
                          className="orderConfirmation-eta">
                          {date}
                        </StyledTypography>
                      </StyledGrid>
                    );
                  })}
              </StyledGrid>
            </StyledBox>
            <StyledBox className="top-margin-2 bottom-margin-2 styledBox--homeDeliveryAddres-container">
              <RoomIcon className={"orderConfirmation--icon-darkGray"} />
              <div className="bottom-margin-2 styledBox--homeDeliveryAddres-Location">
                {userLoggedIn ? <StyledTypography variant="headingFeatured">{addressNickName}</StyledTypography> : null}
                <StyledTypography variant="bodyBaseline" component={"span"}>
                  {deliveryAddres}
                </StyledTypography>
              </div>
              {/* <DeliveryAddressCard
                divider={false}
                readOnlyShipping={true}
                variant={"typ"}
                stepActive={3}
                orderData={ordenDataTYP}
                deliveryDates={deliveryDates}
              /> */}
            </StyledBox>
          </>
        )}
      </StyledGrid>
      {/* <Divider className="bottom-margin-2" /> */}
      <StyledBox>
        {userLoggedIn ? (
          <>
            <StyledBox className="vertical-padding-2">
              <Divider />
            </StyledBox>
            <StyledLinkButton onClick={() => handleOrderHistory()}>
              {t("OrderConfirmation.DeliveryMethod.OrderHistory")}
            </StyledLinkButton>
          </>
        ) : (
          <>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              {t("OrderConfirmation.DeliveryMethod.CustomerSupport")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" component={"span"}>
              <a href={"tel:" + t("OrderConfirmation.DeliveryMethod.CustomerSupportPhone")}>
                {" "}
                {t("OrderConfirmation.DeliveryMethod.CustomerSupportPhone")}
              </a>
            </StyledTypography>
          </>
        )}
      </StyledBox>
    </StyledCardEmpty>
  );

  // console.log("testOxxo", orderDataTYP);

  const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

  return (
    <>
      <DownloadTYP orderData={orderData} addressDetails={addressDetails} />
      <OrderConfirmationContainer id="typ-pdf">
        {drawerOxxo}
        <StyledGrid container spacing={2} className={"orderConfirmation-styledGrid-container"}>
          <StyledGrid id={"typ--title-container"} item xs={12}>
            <StyledBox display={isMobileApp ? "none" : "flex"} alignItems={"center"}>
              <ChevronLeftIcon color="secondary" className={"orderConfirmation--icon-chevronLeft"} />
              <StyledLinkButton onClick={() => handleHome()}>{t("OrderConfirmation.KeepShopping")}</StyledLinkButton>
            </StyledBox>
            {orderData?.paymentInstruction && (
              <StyledTypography tabIndex="0" variant="headingHeadline" textAlign="center" className="top-margin-4">
                {t(`OrderConfirmation.ThanksTitle${isB2B ? "B2B" : EMPTY_STRING}`, {
                  firstName: addressDetails?.firstName
                    ? addressDetails?.firstName
                    : orderData?.paymentInstruction[0]?.firstName
                    ? orderData?.paymentInstruction[0]?.firstName
                    : orderData?.x_pickUpPerson?.split(" ")?.slice(0, 1),
                })}
              </StyledTypography>
            )}
          </StyledGrid>
          <StyledGrid item xs={12}>
            {/*Header Desktop*/}
            <StyledBox>
              <StyledBox
                display={isMobile ? "none" : "flex"}
                alignItems="center"
                justifyContent="center"
                className="top-margin-4">
                <StyledBox display="flex" alignItems="center">
                  <StyledBox>
                    <StyledTypography tabIndex="0" variant="bodyBaseline" component={"span"}>
                      {t(`OrderConfirmation.OrderNumber${isB2B ? "B2B" : EMPTY_STRING}`)}
                    </StyledTypography>
                  </StyledBox>
                  <StyledBox>
                    <StyledTypography
                      id={!isMobile ? "orderId" : ""}
                      tabIndex="0"
                      variant={isB2B ? "bodyBaseline" : "headingFeatured"}
                      weight={isB2B ? "semibold" : EMPTY_STRING}
                      // textAlign="center"
                      className={"left-margin-2"}
                      component="span">
                      {orderData?.orderId}
                    </StyledTypography>
                  </StyledBox>
                </StyledBox>

                {isB2B && orderData?.paymentInstruction[0]?.firstName && (
                  <>
                    <StyledBox className={"horizontal-margin-2"}>
                      <Divider orientation="vertical" className="divider-gray100 h-15"/>
                    </StyledBox>

                    <StyledBox>
                      <AccountCircleIcon className={`orderConfirmation--icon-mediumGray orderConfirmation--icon-size`} />
                    </StyledBox>
                    <StyledBox>
                      <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                        {`${orderData?.paymentInstruction[0]?.firstName} ${orderData?.paymentInstruction[0]?.lastName}`}
                      </StyledTypography>
                    </StyledBox>
                  </>
                )}

                <StyledBox className={isB2B ? "horizontal-margin-2" : "horizontal-padding-2"}>
                  <Divider orientation="vertical" style={{ backgroundColor: "#CCCCCC", height: "15px" }} />
                </StyledBox>

                {orderData?.paymentInstruction && (
                  <StyledBox display="flex" alignItems="center">
                    <StyledBox>
                    <img
                      className={`lazyload orderConfirmation--icon-size ${
                        isB2B ? "right-margin-1" : "orderConfirmation--svg-lightGray"
                      }`}
                      src={isB2B ? outgoingMailSvgGray : outgoingMailSvg}
                    />
                    </StyledBox>
                    <StyledBox>
                      <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                        {addressDetails?.email1
                          ? addressDetails?.email1
                          : orderData?.paymentInstruction[0]?.email1
                          ? orderData?.paymentInstruction[0]?.email1
                          : guestUserData?.email}
                      </StyledTypography>
                    </StyledBox>
                  </StyledBox>
                )}
                <StyledBox className={isB2B ? "horizontal-margin-2" : "horizontal-padding-2"}>
                  <Divider orientation="vertical" style={{ backgroundColor: "#CCCCCC", height: "15px" }} />
                </StyledBox>

                {orderData?.paymentInstruction && (
                  <StyledBox display="flex" alignItems="center">
                    <StyledBox>
                      <PhoneIcon className={`${
                        isB2B ? "orderConfirmation--icon-mediumGray" : "orderConfirmation--icon-lightGray"
                      } orderConfirmation--icon-size`} />
                    </StyledBox>
                    <StyledBox>
                      <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                        {addressDetails?.phone1
                          ? addressDetails.phone1
                          : orderData?.paymentInstruction[0]?.phone1
                          ? orderData?.paymentInstruction[0]?.phone1
                          : orderData?.x_pickUpContact}
                      </StyledTypography>
                    </StyledBox>
                  </StyledBox>
                )}

                {isB2B && orderData?.orgDistinguishedName && (
                  <>
                    <StyledBox className={"horizontal-margin-2"}>
                      <Divider orientation="vertical" className="divider-gray100 h-15"/>
                    </StyledBox>

                    <StyledBox display="flex" alignItems="center">
                      <StyledBox>
                        <CorporateFare className={"orderConfirmation--icon-mediumGray orderConfirmation--icon-size"} />
                      </StyledBox>
                      <StyledBox>
                        <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                          {orderData?.orgDistinguishedName?.split(",")[0]?.replace("o=", "")} -{" "}
                          {orderData?.orgDistinguishedName?.split(",")[1]?.replace("o=", "")}
                        </StyledTypography>
                      </StyledBox>
                    </StyledBox>
                  </>
                )}
              </StyledBox>
            </StyledBox>
            {/*Header Mobile*/}
            <StyledBox display={isMobile ? "" : "none"}>
              <StyledBox className="top-margin-4">
                <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                  {t(`OrderConfirmation.OrderNumber${isB2B ? "B2B" : EMPTY_STRING}`)}
                </StyledTypography>
                <StyledTypography
                  id={isMobile ? "orderId" : ""}
                  tabIndex="0"
                  variant={isB2B ? "bodyBaseline" : "headingFeatured"}
                  weight={isB2B ? "semibold" : EMPTY_STRING}
                  textAlign="center"
                  className={"left-margin-2"}
                  component={"span"}>
                  {orderData?.orderId}
                </StyledTypography>
              </StyledBox>

              <StyledBox className={isB2B ? "vertical-margin-2" : "vertical-padding-2"}>
                <Divider className={isB2B ? "divider-gray50" : "divider-gray100"} />
              </StyledBox>

              {isB2B && orderData?.paymentInstruction[0]?.firstName && (
                <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
                  <AccountCircleIcon className={`orderConfirmation--icon-mediumGray orderConfirmation--icon-size`} />
                  <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                    {`${orderData?.paymentInstruction[0]?.firstName} ${orderData?.paymentInstruction[0]?.lastName}`}
                  </StyledTypography>
                </StyledGrid>
              )}

              {orderData?.paymentInstruction && (
                <>
                  <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
                    <img
                      className={`lazyload orderConfirmation--icon-size ${
                        isB2B ? "right-margin-1" : "orderConfirmation--svg-lightGray"
                      }`}
                      src={isB2B ? outgoingMailSvgGray : outgoingMailSvg}
                    />
                    <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                      {addressDetails?.email1
                        ? addressDetails?.email1
                        : orderData?.paymentInstruction[0]?.email1
                        ? orderData?.paymentInstruction[0]?.email1
                        : guestUserData?.email}
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}

              {orderData?.paymentInstruction && (
                <>
                  <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
                    <PhoneIcon
                      className={`${
                        isB2B ? "orderConfirmation--icon-mediumGray" : "orderConfirmation--icon-lightGray"
                      } orderConfirmation--icon-size`}
                    />
                    <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                      {addressDetails?.phone1
                        ? addressDetails?.phone1
                        : orderData?.paymentInstruction[0]?.phone1
                        ? orderData?.paymentInstruction[0]?.phone1
                        : orderData?.x_pickUpContact}
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}

              {isB2B && orderData?.orgDistinguishedName && (
                <>
                  <StyledGrid className={"orderConfirmation--styledGrid-contactInfoContainer"}>
                    <CorporateFare className={"orderConfirmation--icon-mediumGray orderConfirmation--icon-size"} />
                    <StyledTypography tabIndex="0" variant="bodyBaseline" textAlign="center" component={"span"}>
                      {orderData?.orgDistinguishedName?.split(",")[0]?.replace("o=", "")} -{" "}
                      {orderData?.orgDistinguishedName?.split(",")[1]?.replace("o=", "")}
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
            </StyledBox>
          </StyledGrid>
          <Hidden smDown>
            <StyledGrid xs={12} className={"vertical-padding-2"}>
              <Divider style={{ backgroundColor: "#CCCCCC" }} />
            </StyledGrid>
          </Hidden>

          <StyledGrid item xs={12} md={6} className={isB2B && !isMobile ? "right-padding-2" : EMPTY_STRING}>
            {orderData?.paymentInstruction &&
            orderData?.paymentInstruction?.length > 0 &&
            (orderData.paymentInstruction[0].payMethodId === PAYMENT.paymentMethodName.oxxo ||
              orderData.paymentInstruction[0].payMethodId === PAYMENT.paymentMethodName.openPay ||
              orderData.paymentInstruction[0].payMethodId === PAYMENT.paymentMethodName.cod) ? (
              <>{orderDetailInstructions}</>
            ) : (
              orderDetailDownload
            )}

            <StyledBox mt={0}>
              <StyledCardEmpty
                id={"typ--orderItemTable-container"}
                className="vertical-padding-2 horizontal-padding-2 top-margin-4">
                <TitleCard title="Productos en tu orden" alinear="center" />
                {pickUpDates.lenght !== 0 ? (
                  <>
                    {pickUpDates.map((date) => {
                      const productosDate = pickUpStoreOrders.filter(
                        // (item) => item.xitem_shopCartItemdate_BOPIS === date
                        (item) => item?.xitem_shopCartItemdate_BOPIS === date
                      );
                      return (
                        <StyledBox key={date}>
                          <StyledBox display="flex" mb={2}>
                            <StyledTypography variant="bodyBaseline">
                              {t("OrderConfirmation.OrderProducts.PickUpTYP")}
                            </StyledTypography>
                            <StyledBox px={2}>
                              <Divider
                                orientation="vertical"
                                style={{ backgroundColor: palette.gray["50"], width: "1px" }}
                              />
                            </StyledBox>
                            <StyledTypography variant="bodyBaseline" weight="semibold">
                              {date}
                            </StyledTypography>
                          </StyledBox>

                          {productosDate &&
                            productosDate.map((product, index) => {
                              const pickupProduct = JSON.parse(JSON.stringify(product));
                              pickupProduct.isTYP = true;

                              let hasInstallation: any = null;
                              if (pickupProduct?.x_hasInstallation) {
                                hasInstallation = instalacionesOrders.filter(
                                  (instalacion) => instalacion.partNumber.split("_")[2] === pickupProduct.partNumber
                                )[0];
                              }

                              const prodData = productData.filter((el) => el.partNumber === pickupProduct.partNumber);

                              return (
                                <ProductoMiOrden
                                  index={index}
                                  key={pickupProduct.id}
                                  order={pickupProduct}
                                  hasInstallation={hasInstallation}
                                  productData={prodData}
                                />
                              );
                            })}
                          <StyledBox mb={2}>
                            {/* <Divider style={{ backgroundColor: palette.gray["50"] }} /> */}
                          </StyledBox>
                        </StyledBox>
                      );
                    })}
                  </>
                ) : null}

                {homeDeliveryDates.lenght !== 0 ? (
                  <>
                    {homeDeliveryDates.map((date) => {
                      const productosDate = homeDeliveryOrders.filter((item) => item.hdm_deliveryDateFormat === date);
                      return (
                        <StyledBox key={date}>
                          <StyledBox display="flex" mb={2}>
                            <StyledTypography variant="bodyBaseline">
                              {t("OrderConfirmation.OrderProducts.HomeDeliveryTYP")}
                            </StyledTypography>
                            <StyledBox px={2}>
                              <Divider
                                orientation="vertical"
                                style={{ backgroundColor: palette.gray["50"], width: "1px" }}
                              />
                            </StyledBox>
                            <StyledTypography variant="bodyBaseline" weight="semibold" className="no-white-spaces">
                              {date}
                            </StyledTypography>
                          </StyledBox>

                          {productosDate &&
                            productosDate.map((product, index) => {
                              const homeDeliveryProduct = JSON.parse(JSON.stringify(product));
                              homeDeliveryProduct.isTYP = true;

                              let hasInstallation: any = null;
                              if (homeDeliveryProduct?.x_hasInstallation) {
                                hasInstallation = instalacionesOrders.filter(
                                  (instalacion) =>
                                    instalacion.partNumber.split("_")[2] === homeDeliveryProduct.partNumber
                                )[0];
                              }

                              const prodData = productData.filter(
                                (el) => el.partNumber === homeDeliveryProduct.partNumber
                              );

                              return (
                                <ProductoMiOrden
                                  index={index}
                                  key={homeDeliveryProduct.id}
                                  order={homeDeliveryProduct}
                                  hasInstallation={hasInstallation}
                                  productData={prodData}
                                />
                              );
                            })}
                          <StyledBox mb={2}>
                            {/* <Divider style={{ backgroundColor: palette.gray["50"] }} /> */}
                          </StyledBox>
                        </StyledBox>
                      );
                    })}
                  </>
                ) : null}

                {/* {instalacionesOrders.length !== 0 ?
            <>
            <StyledBox display="flex" mb={2}>
                <StyledTypography variant="bodyBaseline">
                    Instalaciones
                </StyledTypography>
                <StyledBox px={2}>
                <Divider orientation="vertical" style={{backgroundColor: palette.gray["50"], width: '1px'}} />
                </StyledBox>
                <StyledTypography variant="bodyBaseline" weight="semibold">
                Te llamaremos para agendar la fecha
                </StyledTypography>
            </StyledBox>

            {instalacionesOrders && instalacionesOrders.map(order => {

                const orderInstalacion = JSON.parse(JSON.stringify(order))
                orderInstalacion.isInstalacion = true
                orderInstalacion.isTYP = true
                return (
                  <ProductoMiOrden key={order.id} order={orderInstalacion} />
                )
            })}

            </>
            :
            null
            } */}
              </StyledCardEmpty>
            </StyledBox>

            <StyledCardEmpty
              id={"typ--orderTotalSummary-container"}
              className="top-margin-1 vertical-padding-2 horizontal-padding-2">
              <OrderTotalSummary
                order={orderData}
                canContinue={canContinue}
                checkout={checkout}
                isTYP={true}
                hideTotals={true}
                deliveryMethod={orderData?.x_split_shippingMethod === "1" ? CONSTANTS.bopis : ""}
              />
            </StyledCardEmpty>

            {/* {productsOrder} */}
          </StyledGrid>
          <StyledGrid item xs={12} md={6} className={isB2B && !isMobile ? "left-padding-0" : EMPTY_STRING}>
            {deliveryMethodContent}
            {paymentMethod}
          </StyledGrid>
        </StyledGrid>
      </OrderConfirmationContainer>
    </>
  );
};
