/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { call, put, select, all } from "redux-saga/effects";
import { AnyAction } from "redux";
//Foundation libraries
import cartService from "../../../_foundation/apis/transaction/cart.service";
import orderService from "../../../_foundation/apis/transaction/order.service";
import shippingInfoService from "../../../_foundation/apis/transaction/shippingInfo.service";
//Custom libraries
import { ORDER_CONFIGS, MINICART_CONFIGS } from "../../../configs/order";
import { CommerceEnvironment, EMPTY_STRING, RF_JSON, SUCCESS_MSG_PREFIX } from "../../../constants/common";
//Redux
import * as ACTIONS from "../../action-types/order";
import * as ACTIONSDRAWER from "../../action-types/drawer";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import { CART } from "../../../constants/routes";
import {
  COPY_CART_SUCCESS_ACTION,
  COPY_CART_ERROR_ACTION,
  FETCH_ORDERS_SUCCESS_ACTION,
  FETCH_ORDERS_ERROR_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION,
  FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION,
  FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_S_ACTION,
  FETCH_ALLOWABLE_PAYMETHODS_F_ACTION,
} from "../../actions/order";
import { fetchOrderItemDetailsByIds } from "./orderDetails";
import { CartApi, ShippingInfoApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { site } from "../../../_foundation/constants/site";
import { getSite } from "../../../_foundation/hooks/useSite";
import { localStorageUtil, storageSessionHandler } from "../../../_foundation/utils/storageUtil";
import { LOCALE, SELLER_PARAM } from "../../../_foundation/constants/common";
import { activeInprogressOrderSelector } from "../../selectors/order";
import { get } from "lodash-es";
import { N_ITEMS_ADDED } from "../../../constants/order";
import { useSelector } from "react-redux";
import store from "../../store";
import { addSearchTag } from "../../../_foundation/emarsys/addSearchTag";
import { currentStoreSelector } from "../../selectors/current-store";
import { loginStatusSelector, userIdSelector } from "../../selectors/user";
import { proCustomerLevelSelector } from "../../selectors/organization";
import { siteSelector } from "../../selectors/site";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import * as CryptoJS from 'crypto-js';
import { logEvent } from "../../../hdm/functions/utils";
const sha256value = (userIdVal) => {
  return CryptoJS.SHA256(userIdVal).toString(CryptoJS.enc.Hex);
};
const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");
export function* copyCart(action: AnyAction) {
  try {
    const { fromOrderId } = action.payload;
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    const payload = action.payload;
    const params = {
      body: {
        fromOrderId_1: fromOrderId,
        toOrderId: activeInprogressOrder ? activeInprogressOrder.orderId : ".**.",
        copyOrderItemId_1: "*",
      },
    };
    if (payload.widget) {
      params["widget"] = payload.widget;
    }
    const response = yield call(cartService.copyOrder, params);
    yield put(
      COPY_CART_SUCCESS_ACTION({
        response,
      })
    );
    if(isMobileApp){
      logEvent("copyOrder")
    }else {
    const b2b = getSite()?.isB2B;
    const link = !b2b ? { url: CART, textKey: `${SUCCESS_MSG_PREFIX}ViewCart` } : undefined;
    const successMessage = {
      key: SUCCESS_MSG_PREFIX + COPY_CART_SUCCESS_ACTION.type,
      link,
    };
    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
    }
  } catch (e) {
    yield put(COPY_CART_ERROR_ACTION(e));
  }
}

/**
 * Saga worker to invoke add item API
 */
export function* addItem(action: any) {
  try {
    const payload = action.payload;
    const cartPayload = {
      contractId: payload.contractId,
    };

    const _orderItems: any[] = [];
    let catentryIds: string[] = [];
    let partnumbers: string[] = [];
    let quantities: any[] = [];
    if (payload.partnumber) {
      partnumbers = payload.partnumber instanceof Array ? payload.partnumber : [payload.partnumber];
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity];
    } else if (payload.catentryId) {
      catentryIds = payload.catentryId instanceof Array ? payload.catentryId : [payload.catentryId];
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity];
    }

    for (const i in partnumbers) {
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId: payload.contractId,
      };
    }
    for (const i in catentryIds) {
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: payload.contractId,
      };
    }
    let body = {};
    if (payload.widget) {
      body["widget"] = payload.widget;
      cartPayload["widget"] = payload.widget;
    }
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    let response: any = {};

    if (!activeInprogressOrder) {
      if (payload?.colorCode) {
        if(typeof payload?.colorCode === "string"){
          body = {
            body: {
              orderId: ".",
              x_calculateOrder: ORDER_CONFIGS.calculateOrder,
              orderItem: _orderItems,
              x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
              //x_calculationUsage: ORDER_CONFIGS.calculationUsage, HDM is not sending this parameter
              x_deliverType: ORDER_CONFIGS.x_deliverType,
              x_colorCode: payload?.colorCode,
              x_mfbt: payload.mfbt ? payload.mfbt : "",
              x_fbc: payload.fbc ? payload.fbc : "",
              x_fbp: payload.fbp ? payload.fbp : "",
              x_pstore: payload.pstore ? payload.pstore: "",
              x_userAgent: payload.userAgent ? payload.userAgent : "",
            },
          };
          response = yield call(cartService.addOrderItem, body);

        }
        if(typeof payload?.colorCode === "object"){
          try {
            const responses = yield all(
              payload?.colorCode.map((id, index) => {
                const _orderItem = {
                  quantity: quantities[index].toString(),
                  partNumber: partnumbers[index],
                  contractId: payload.contractId,
                };

                body = {
                  body: {
                    orderId: ".",
                    x_calculateOrder: ORDER_CONFIGS.calculateOrder,
                    orderItem: _orderItem,
                    x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
                    //x_calculationUsage: ORDER_CONFIGS.calculationUsage, HDM is not sending this parameter
                    x_deliverType: ORDER_CONFIGS.x_deliverType,
                    x_mfbt: payload.mfbt ? payload.mfbt : "",
                    x_fbc: payload.fbc ? payload.fbc : "",
                    x_fbp: payload.fbp ? payload.fbp : "",
                    x_pstore: payload.pstore ? payload.pstore: "",
                    x_userAgent: payload.userAgent ? payload.userAgent : "",
                  },
    
                }
                if(payload?.colorCode[index]){
                  body["body"]["x_colorCode"] = payload?.colorCode[index];
                }
                return call(cartService.addOrderItem, body)
              })
            );
          } catch (error) {
            console.error("Error adding items:", error);
          }
        }
      } else {
        body = {
          body: {
            orderId: ".",
            x_calculateOrder: ORDER_CONFIGS.calculateOrder,
            orderItem: _orderItems,
            x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
            //x_calculationUsage: ORDER_CONFIGS.calculationUsage, HDM is not sending this parameter
            x_deliverType: ORDER_CONFIGS.x_deliverType,
            x_mfbt: payload.mfbt ? payload.mfbt : "",
            x_fbc: payload.fbc ? payload.fbc : "",
            x_fbp: payload.fbp ? payload.fbp : "",
            x_pstore: payload.pstore ? payload.pstore: "",
            x_userAgent: payload.userAgent ? payload.userAgent : "",
          },
        };
        response = yield call(cartService.addOrderItem, body);
      }
    } else {
      const orderItems = {};
      for (const i in partnumbers) {
        Object.assign(orderItems, {
          [`quantity_${i}`]: quantities[i].toString(),
          [`partNumber_${i}`]: partnumbers[i],
          [`contractId_${i}`]: payload.contractId,
        });
      }
      for (const i in catentryIds) {
        Object.assign(orderItems, {
          [`quantity_${i}`]: quantities[i].toString(),
          [`catEntryId_${i}`]: catentryIds[i],
          [`contractId_${i}`]: payload.contractId,
        });
      }
      Object.assign(orderItems, {
        orderId: activeInprogressOrder.orderId,
        calculateOrder: ORDER_CONFIGS.calculateOrder,
        inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      });
      body = {
        body: orderItems,
        ...body,
      };
      response = yield call(cartService.addPreConfigurationOrderItem, body);
    }

    /*yield put({
      type: ACTIONS.ITEM_ADD_SUCCESS,
      response: response.data,
      payload: payload,
    });*/

    const cartAction = { ...action, payload: cartPayload };
    yield call(fetchCart, cartAction);

    const b2b = getSite()?.isB2B;
    const link = !b2b && partnumbers.length === 1 ? { url: CART, textKey: `${SUCCESS_MSG_PREFIX}ViewCart` } : undefined;
    const msg = {
      key: SUCCESS_MSG_PREFIX + (partnumbers.length > 1 ? N_ITEMS_ADDED : ACTIONS.ITEM_ADD_SUCCESS),
      messageParameters: partnumbers.length > 1 ? { v: String(partnumbers.length) } : undefined,
      link,
    };
    //yield put({ type: ACTIONSDRAWER.OPEN_CART_DRAWER });
    //yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_ADD_ERROR, error });
  }
}

/**
 * Saga worker to invoke remove item API
 */
export function* removeItem(action: any) {
  try {
    const payload = action.payload;
    const { widget, orderItemId } = payload;
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    const orderId = activeInprogressOrder ? activeInprogressOrder.orderId : undefined;
    const body = {
      orderId,
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      orderItemId,
    };

    const response = yield call(cartService.deleteOrderItem, { body, widget });
    yield put({ type: ACTIONS.ITEM_REMOVE_SUCCESS, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_REMOVE_ERROR, error });
  }
}

/**
 * Saga worker to invoke update item API
 */
export function* updateItem(action: any) {
  try {
    const payload = action.payload;
    const { orderItemId, quantity, widget } = payload;
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    const orderId = activeInprogressOrder ? activeInprogressOrder.orderId : undefined;
    let body = {};

    if (payload.deliverType) {
      body = {
        orderId,
        x_calculateOrder: ORDER_CONFIGS.xcalculateOrder1,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderItem: orderItemId,
        x_deliverType: payload.x_deliverType,
      };
    } else {
      if (payload.x_addressId || payload.x_pageLoad) {
        let csOrderId = "";
        if (orderId === undefined) {
          csOrderId = payload.orderId;
        } else {
          csOrderId = orderId;
        }
        if (csOrderId === "" || csOrderId === undefined) {
          csOrderId = ".";
        }
        const orderItems: any = [];
        if (quantity instanceof Array) {
          for (let i = 0; i < quantity.length; i++) {
            orderItems.push({
              quantity: quantity[i],
              orderItemId: orderItemId[i],
              contractId: payload?.contractId,
            });
          }
        }
        body = {
          orderId: csOrderId,
          orderItem: orderItems.length > 0 ? orderItems : orderItemId,
          x_calculateOrder: ORDER_CONFIGS.xcalculateOrder1,
          x_calculationUsage: ORDER_CONFIGS.calculationUsageDelivery,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          x_realtimeInventory: ORDER_CONFIGS.x_realtimeInventory,
          x_deliverType: payload.x_deliverType,
          x_page: payload.x_page,
          x_pageLoad: payload.x_pageLoad,
          x_addressId: payload.x_addressId,
          x_storeState: payload.x_storeState,
          x_storeCity: payload.x_storeCity,
          x_storeLatitude: payload.x_storeLatitude,
          x_storeLongitude: payload.x_storeLongitude,
        };
      } else if (quantity instanceof Array) {
        const orderItems: any = [];
        for (let i = 0; i < quantity.length; i++) {
          orderItems.push({
            quantity: quantity[i],
            orderItemId: orderItemId[i],
            contractId: payload?.contractId,
          });
        }
        body = {
          orderId,
          x_calculateOrder: ORDER_CONFIGS.xcalculateOrder1,
          x_calculationUsage: ORDER_CONFIGS.calculationUsageDelivery,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderItem: orderItems,
          x_colorDetails: payload?.x_colorDetails,
          x_updateColor: payload?.x_updateColor,
          x_deleteColor: payload?.x_deleteColor,
        };
      } else {
        body = {
          orderId,
          x_calculateOrder: ORDER_CONFIGS.xcalculateOrder1,
          x_calculationUsage: ORDER_CONFIGS.calculationUsageDelivery,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
          orderItem: [
            {
              quantity: quantity,
              orderItemId: orderItemId,
              contractId: payload?.contractId,
            },
          ],
          xitem_colorCode: payload?.xitem_colorCode,
          x_colorDetails: payload?.x_colorDetails,
          x_updateColor: payload?.x_updateColor,
          x_deleteColor: payload?.x_deleteColor,
        };
      }
    }
    const response = yield call(cartService.updateOrderItem, { widget, body });
    yield put({ type: ACTIONS.ITEM_UPDATE_SUCCESS, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_UPDATE_ERROR, error });
  }
}

export function* initFromStorageFetchCart(action: any) {
  const { WCToken, rememberMe } = action.payload || {};
  if (WCToken || rememberMe) {
    yield* fetchCart(action);
  }
}

function* sendCartTag (orderItems, action) {
  const items: any = [];
  if (orderItems.length > 0) {
    for (let i = 0; i < orderItems.length; i++) {
      const priceTwoDecimals = parseFloat(orderItems[i].orderItemPrice).toFixed(2);
      const itemobj = {
        item: orderItems[i]?.partNumber,
        price: parseFloat(priceTwoDecimals),
        quantity: parseInt(orderItems[i].quantity),
      };
      if (!orderItems[i]?.partNumber?.includes("SI")) {
        items.push(itemobj);
      }
    }
  }
  const itemsTag = ["cart", items];
  if (["ITEM_REMOVE_REQUESTED", "ITEM_ADD_REQUESTED", "ITEM_UPDATE_REQUESTED"].includes(action.type)) {
    const storeConfData = yield select(onlineStoreRdc);
    const userId = yield select(userIdSelector);
    if (storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD !== undefined
      && storeConfData?.ENABLE_EMARSYS_IDENTIFIER_FIELD === 'true') {
      if (userId) {
        if (storeConfData?.ENABLE_EMARSYS_TEST_MODE !== undefined
          && storeConfData?.ENABLE_EMARSYS_TEST_MODE === 'true') {
            window.ScarabQueue.push(['testMode']);
          }
        addSearchTag(itemsTag, false);
        addSearchTag(["setCustomerId", sha256value(userId)], true);
      }
    } else if (orderItems[0]?.email1) {
      addSearchTag(itemsTag, false);
      addSearchTag(["setEmail", orderItems[0].email1], true);
    } else {
      addSearchTag(itemsTag, true);
    }
  }
}


/**
 * Saga worker to invoke get cart API
 */
export function* fetchCart(action: any) {
  const data = store.getState().currentStoreReducer;
  const DEFAULT_STORE = "8702"; //coapa del hueso

  const getPhysicalStoreByActionType = (action, payload) => {
    if (action === "SET_CURRENT_STORE") {
      return payload.findStore ? payload.findStore.stLocId : DEFAULT_STORE;
    }

    if (action === "LOGIN_SUCCESS") {
      return payload.physicalStoreId ? payload.physicalStoreId : DEFAULT_STORE;
    }

    if (["ITEM_REMOVE_REQUESTED", "ITEM_ADD_REQUESTED", "ITEM_UPDATE_REQUESTED"].includes(action)) {
      return payload.currentStore ? payload.currentStore.currentStore.stLocId : DEFAULT_STORE;
    }

    return DEFAULT_STORE;
  };

  const activeInprogressOrder = yield select(activeInprogressOrderSelector);
  try {
    const payload = action.payload;

    const currentStoreData = yield select(currentStoreSelector);
    const loginStatus = yield select(loginStatusSelector);
    const proCustomerLevel = yield select(proCustomerLevelSelector);
    const mySite = yield select(siteSelector);

    const isB2B = mySite?.isB2B;

    const physicalStoreId = currentStoreData?.currentStore?.stLocId;
    // if(isB2B && loginStatus && proCustomerLevel){
    //   physicalStoreId = `${currentStoreData?.currentStore?.stLocId}_${proCustomerLevel}`
    // }
    const parameters = {
      ...payload,
      physicalStoreId: !(data && Object.keys(data).length === 0) ? data["currentStore"].stLocId : "",
      sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
    };

    const fetchCatentries: boolean = payload.fetchCatentries ? payload.fetchCatentries : false;
    const checkInventory: boolean = payload.checkInventory ? payload.checkInventory : false;

    if (payload.widget) {
      parameters["widget"] = payload.widget;
    }

    if (!activeInprogressOrder) {
      const responseCart = yield call(cartService.getCart, { ...parameters });

      let catentries: any = null;
      if (responseCart) {
        const cart = responseCart.data;

        if (cart) {
          const orderItems = cart.orderItem;

          if (orderItems && orderItems.length > 0) {
            sendCartTag(orderItems, action);
            let catentryIdList: string[] = [];
            const contracts = orderItems.reduce((p, c) => {
              if (p[c.contractId]) {
                if (p[c.contractId].indexOf(c.productId) === -1) {
                  p[c.contractId].push(c.productId);
                }
              } else {
                p[c.contractId] = [c.productId];
              }
              return p;
            }, {});

            if (fetchCatentries || orderItems.length <= MINICART_CONFIGS.maxItemsToShow) {
              //get product info for all items
              orderItems.forEach((item: any, index: number) => {
                catentryIdList.push(item.productId);
              });
            } else {
              //get product info for mini cart only
              orderItems.slice(MINICART_CONFIGS.maxItemsToShow * -1).forEach((item: any, index: number) => {
                catentryIdList.push(item.productId);
              });
            }
            if (catentryIdList.length > 0) {
              catentryIdList = [...new Set(catentryIdList)];

              const currency = parameters ? parameters.currency : "";
              const paramsProduct: any = {
                currency: currency,
                id: catentryIdList,
                contracts: contracts,
                // add empty seller parameter -- don't filter when fetching order-item details
                query: { [SELLER_PARAM]: EMPTY_STRING },
              };
              if (parameters?.cancelToken) {
                paramsProduct["cancelToken"] = parameters.cancelToken;
              }
              if (parameters?.widget) {
                paramsProduct["widget"] = parameters.widget;
              }

              if (physicalStoreId) {
                paramsProduct["physicalStoreId"] = physicalStoreId;
              }

              if(payload?.initCart || !paramsProduct?.physicalStoreId){
                paramsProduct["langId"] = "-5";
                paramsProduct["physicalStoreId"] = DEFAULT_STORE;
              }

              try {
                //pass physicalStoreId to fetchOrderItemDetailsByIds
                const contents = yield call(fetchOrderItemDetailsByIds, paramsProduct);
                const mySite = yield select(siteSelector);
                if (contents) {
                  catentries = {};
                  contents.forEach((catentry: any, index: number) => {
                    const { seller, sellerId } = catentry;
                    const obj = {
                      name: catentry.name,
                      manufacturer: catentry.manufacturer,
                      thumbnail: catentry.thumbnail,
                      attributes: catentry.attributes,
                      price: catentry.price,
                      isB2B: mySite.isB2B,
                      seo: catentry.seo,
                      disallowRecurringOrder: catentry.disallowRecurringOrder,
                      parentCatalogGroupID: catentry.parentCatalogGroupID,
                      merchandisingAssociations: catentry?.merchandisingAssociations,
                      shortDescription: catentry?.shortDescription,
                      seller,
                      sellerId,
                    };
                    catentries[catentry.id] = obj;
                  });
                }
              } catch (error) {
                console.log("Could not retrieve products");
                //Cannot retrieve catentry details; return order items as-is
                catentries = null;
              }
            }
          }
        }
      }

      if (catentries != null) {
        yield put({
          type: ACTIONS.CART_GET_SUCCESS,
          response: responseCart.data,
          catentries: catentries,
          checkInventory: checkInventory,
        });
      } else {
        yield put({
          type: ACTIONS.CART_GET_SUCCESS,
          response: responseCart.data,
          checkInventory: checkInventory,
        });
      }
    } else {
      const siteInfo = getSite();
      const oderPayload = {
        contractId: payload.contractId,
        orderId: activeInprogressOrder.orderId,
        skipErrorSnackbar: true,
        currency: siteInfo?.defaultCurrencyID ?? "",
      };
      yield put(FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ACTION(oderPayload));
    }
  } catch (error) {
    if(store?.getState()?.order?.cart) {
      yield put({
        type: ACTIONS.CART_GET_ERROR,
        error,
      });
    }
  }
}

/**
 * Saga worker to invoke get usable ship info API
 */
export function* fetchShipInfo(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.getUsableShippingInfo, payload);
    yield put({
      type: ACTIONS.SHIPINFO_GET_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.SHIPINFO_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get usable ship modes API
 */
export function* fetchShipModes(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.getUsableShippingMode, payload);
    yield put({
      type: ACTIONS.SHIPMODES_GET_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.SHIPMODES_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke update ship mode API
 */
export function* updateShipMode(action: any) {
  try{
    const payload = action.payload;
    let body = {};
    if (payload?.x_page === "cart") {
      body = {
        body: {
          calculateOrder: ORDER_CONFIGS.calculateOrder,
          x_calculationUsage: ORDER_CONFIGS.calculationUsageDelivery,
          orderId: ".",
          //addressId: payload.shipAddressId,
          orderItem: payload.orderItemId,
          x_storeZipcode: payload.x_storeZipcode,
          x_realtimeInventory: payload.x_realtimeInventory,
          x_deliverType: payload.x_deliverType,
          x_page: payload.x_page,
          x_pageLoad: payload.x_pageLoad,
        },
      };
    } else {
      body = {
        body: {
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          x_calculationUsage: ORDER_CONFIGS.calculationUsage,
          x_allocate: ORDER_CONFIGS.allocate,
          x_backorder: ORDER_CONFIGS.backOrder,
          x_remerge: ORDER_CONFIGS.remerge,
          x_check: ORDER_CONFIGS.check,
          orderId: ".",
          shipModeId: payload.shipModeId,
          //addressId: payload.shipAddressId,
          orderItem: [], //bypass defect HC-2784
        },
      };
    }
    if (payload.widget) {
      body["widget"] = payload.widget;
    }
    const response = yield call(shippingInfoService.updateOrderShippingInfo, body);
    yield put({
      type: ACTIONS.SHIPMODE_UPDATE_SUCCESS,
      response: response.data,
    });
  }catch(error) {
    yield put({ type: ACTIONS.SHIPMODES_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get usable payment methods API
 */
export function* fetchPayMethods(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(cartService.getUsablePaymentInfo, payload);
    yield put({
      type: ACTIONS.PAYMETHODS_GET_SUCCESS,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PAYMETHODS_GET_ERROR, error });
  }
}

export function* getAllOrders(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(orderService.findByStatus, payload);
    yield put({
      type: FETCH_ORDERS_SUCCESS_ACTION,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: FETCH_ORDERS_ERROR_ACTION, error });
  }
}

export function* getAllowableShipmodes(action: any) {
  const shipApi = new ShippingInfoApi(undefined, site.transactionContext);
  const siteInfo = getSite();
  const storeId = siteInfo?.storeID ?? "";
  const locale = localStorageUtil.get(LOCALE);
  const lang = CommerceEnvironment.reverseLanguageMap[locale];

  try {
    const r = yield call(shipApi.cartGetAllowableShippingModes.bind(shipApi), storeId, locale, lang, RF_JSON);
    const p = { ...action.payload, modes: r.data.usableShippingMode };
    yield put(FETCH_ALLOWABLE_SHIPMODES_SUCCESS_ACTION(p));
  } catch (e) {
    yield put(FETCH_ALLOWABLE_SHIPMODES_ERROR_ACTION(e));
  }
}

export function* fetchActiveInprogressOrderItem(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(orderService.findByOrderId, payload);
    const orderDetails = response.data;

    const fetchCatentries: boolean = payload.fetchCatentries ? payload.fetchCatentries : false;

    const checkInventory: boolean = payload.checkInventory ? payload.checkInventory : false;

    let catentries: any = null;
    if (orderDetails) {
      const orderItems = orderDetails.orderItem;
      if (orderItems && orderItems.length > 0) {
        let catentryIdList: string[] = [];
        const contracts = orderItems.reduce((p, c) => {
          if (p[c.contractId]) {
            if (p[c.contractId].indexOf(c.productId) === -1) {
              p[c.contractId].push(c.productId);
            }
          } else {
            p[c.contractId] = [c.productId];
          }
          return p;
        }, {});

        if (fetchCatentries || orderItems.length <= MINICART_CONFIGS.maxItemsToShow) {
          //get product info for all items
          orderItems.forEach((item: any, index: number) => {
            catentryIdList.push(item.productId);
          });
        } else {
          //get product info for mini cart only
          orderItems.slice(MINICART_CONFIGS.maxItemsToShow * -1).forEach((item: any, index: number) => {
            catentryIdList.push(item.productId);
          });
        }

        if (catentryIdList.length > 0) {
          catentryIdList = [...new Set(catentryIdList)];

          const currency = payload ? payload.currency : "";
          const paramsProduct: any = {
            currency: currency,
            id: catentryIdList,
            contracts: contracts,
            // add empty seller parameter -- don't filter when fetching order-item details
            query: { [SELLER_PARAM]: EMPTY_STRING },
          };
          if (payload?.cancelToken) {
            paramsProduct["cancelToken"] = payload.cancelToken;
          }
          if (payload?.widget) {
            paramsProduct["widget"] = payload.widget;
          }

          try {
            const contents = yield call(fetchOrderItemDetailsByIds, paramsProduct);
            const mySite = yield select(siteSelector);
            if (contents) {
              catentries = {};
              contents.forEach((catentry: any, index: number) => {
                const { seller, sellerId } = catentry;
                const obj = {
                  name: catentry.name,
                  thumbnail: catentry.thumbnail,
                  attributes: catentry.attributes,
                  price: catentry.price,
                  isB2B: mySite.isB2B,
                  seo: catentry.seo,
                  disallowRecurringOrder: catentry.disallowRecurringOrder,
                  parentCatalogGroupID: catentry.parentCatalogGroupID,
                  merchandisingAssociations: catentry?.merchandisingAssociations,
                  shortDescription: catentry?.shortDescription,
                  seller,
                  sellerId,
                };
                catentries[catentry.id] = obj;
              });
            }
          } catch (error) {
            console.log("Could not retrieve products");
            //Cannot retrieve catentry details; return order items as-is
            catentries = null;
          }
        }
      }
    }

    yield put(
      FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_SUCCESS_ACTION({
        orderDetails: orderDetails,
        catentries: catentries,
        checkInventory: checkInventory,
      })
    );
  } catch (error) {
    yield put(FETCH_ACTIVE_INPROGRESS_ORDER_ITEM_ERROR_ACTION({ error }));
  }
}

export function* getAllowablePaymethods(action: any) {
  const api = new CartApi(undefined, site.transactionContext);
  const siteInfo = getSite();
  const storeId = siteInfo?.storeID ?? "";

  try {
    const r = yield call(api.cartGetPaymentPolicyListDataBean.bind(api), storeId, RF_JSON);

    const p = {
      ...action.payload,
      methods: get(r, "data.resultList[0].paymentPolicyInfoUsableWithoutTA", []),
    };
    yield put(FETCH_ALLOWABLE_PAYMETHODS_S_ACTION(p));
  } catch (e) {
    yield put(FETCH_ALLOWABLE_PAYMETHODS_F_ACTION(e));
  }
}
export function* setCurrentStore(action: any) {
  localStorageUtil.set("currentStore", action.payload.findStore.stLocId);
  yield "";
}
