/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/productNotAvailable";

const SET_PRODUCT_NOT_AVAILABLE_ACTION = createAction<any, string>(ACTIONTYPES.SET_PRODUCT_NOT_AVAILABLE);

export { SET_PRODUCT_NOT_AVAILABLE_ACTION };
