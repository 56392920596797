import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";

import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

import { StyledBox, StyledButton, StyledCardEmpty, StyledTypography, StyledLink,StyledGrid } from "../../../hdm/elements";
import TitleCard from "../../custom-components/styled-title-card/TitleCard";

import { Divider, SvgIcon } from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddIcon from "@material-ui/icons/Add";
import { palette } from "../../../hdm/themes";
// import { HomeIcon } from "../../../hdm/components/svg-icons";
import HomeIcon from "@material-ui/icons/Home";
import { ContainerCheckoutProfile, StyledSwipeableDrawersDireccion } from "./container-checkout-profile";
import CheckoutProfileAddressForm from "./CheckoutProfileAddressForm";
import { AllowScroll, DisableScroll } from "../../../hdm/functions/utils";
import personContactService from "../../../_foundation/apis/transaction/personContact.service";
import { addressDetailsSelector } from "../../../redux/selectors/account";
import { useSite } from "../../../_foundation/hooks/useSite";
import Direcciones from "./Direcciones";
import personService from "../../../_foundation/apis/transaction/person.service";
import addressUtil from "../../../utils/addressUtil";
import storeUtil from "../../../utils/storeUtil";
import { get } from "lodash-es";
import { PAYMENT } from "../../../constants/order";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { CURRENT_USER } from "../../../_foundation/constants/common";
import { CheckoutProfileApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { RF_JSON } from "../../../constants/common";
import { site } from "../../../_foundation/constants/site";
import { StyledPromp } from "../../custom-components/styled-promp";
interface Props {
  updateDataProfile: any;
  addressDetails: any;
  misDirecciones: any;
  handleSelectedDireccion: (dir: any) => void;
  handleGuardadoExito: () => void;
  selectedMetodoPago?: any;
  setRefHeights?: any;
  refHeights?: any;
  isMobile?: boolean;
}

const DireccionDeEntrega = (props: Props) => {
  const {
    handleSelectedDireccion,
    handleGuardadoExito,
    misDirecciones,
    addressDetails,
    updateDataProfile,
    setRefHeights,
    refHeights,
    selectedMetodoPago,
    isMobile,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [guardarDireccion, setGuardarDireccion] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [dataDireccion, setDataDireccion] = useState<any>(null);
  const [inicializado, setInicializado] = useState(false);

  const storeConfData = useSelector(onlineStoreRdc);
  const { logonId } = useSelector(addressDetailsSelector);
  const [googleApiGeoKey, setGoogleApiGeoKey] = useState("");

  const dispatch = useDispatch();
  const { mySite } = useSite();

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const widgetName = getDisplayName(DireccionDeEntrega);
  const [profileList, setProfileList] = useState<any[]>([]);
  const currentUser = localStorageUtil.get(CURRENT_USER);
  
  const AddressListRef = useRef<HTMLDivElement>(null);
  const [isInBlockList,setIsInBlockList]: any = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setRefHeights((prevState) => {
      return {
        ...prevState,
        addressListHeight: AddressListRef?.current?.clientHeight,
      };
    });

    if (selectedMetodoPago?.paymentMethodName === "PayPal") {
      setRefHeights((prevState) => {
        return {
          ...prevState,
          addressListHeight: AddressListRef?.current?.clientHeight,
          // addressListHeight: "",
        };
      });
    }
  }, [setRefHeights, misDirecciones, selectedMetodoPago]);

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (isOpen) {
      DisableScroll();
    } else {
      AllowScroll();
    }
  }, [isOpen]);

  useEffect(() => {
    const defaultAddress = misDirecciones.filter((d) => d.primary === "true")[0];
    if (!inicializado) {
      if (updateDataProfile && updateDataProfile.profile.isValid) {
        const selected = misDirecciones.filter(
          (dir) => dir.nickName === updateDataProfile.profile.shipping_nickName
        )[0];
        setSelectedAddress(selected);
        setDataDireccion(selected);
      } else {
        setSelectedAddress(defaultAddress);
        setDataDireccion(defaultAddress);
      }

      setInicializado(true);
    } else {
      if (dataDireccion) {
        const selected = misDirecciones.filter((dir) => dir.nickName === dataDireccion.nickName)[0];
        setSelectedAddress(selected);
        setDataDireccion(null);
      } else {
        const selected = misDirecciones.filter((dir) => dir.nickName === selectedAddress?.nickName)[0];
        setSelectedAddress(selected);
      }
    }
  }, [misDirecciones]);

  useEffect(() => {
    if (storeConfData?.googleAPIGeoLocateKey) {
      setGoogleApiGeoKey(storeConfData?.googleAPIGeoLocateKey);
    }
  }, [storeConfData]);

  useEffect(() => {
    handleSelectedDireccion(selectedAddress);
  }, [selectedAddress]);

  const handleSelectedAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleOpenDireccion = (option) => {
    if (option === "editar") {
      setIsEdit(true);
    } else {
      setIsInBlockList(false);
      setIsEdit(false);
    }
    setIsOpen(true);
  };

  const handleCloseDireccion = () => {
    setIsOpen(false);
    setIsEdit(false);
    setIsInBlockList(false);
  };

  const handleCanSaveDireccion = (data) => {
    if (data) {
      setGuardarDireccion(true);
      setDataDireccion(data);
    } else {
      setGuardarDireccion(false);
      setDataDireccion(null);
    }
  };

  const handleSaveDireccion = async () => {
    const gpsAddress = `${dataDireccion?.state},${dataDireccion?.city},${dataDireccion?.colonia},${dataDireccion?.apartamento},${dataDireccion?.zipCode}`;
    let coords = "";

    const geocoder = new window["google"].maps.Geocoder();

    await geocoder.geocode(
      {
        address: gpsAddress,
        componentRestrictions: {
          country: "MX",
        },
      },
      function (results, status) {
        if (results && results.length > 0 && status === "OK") {
          const result = results[0];
          coords = result.geometry.location.lat() + "," + result.geometry.location.lng();
        }
      }
    );

    const newAddressData = {
      firstName: addressDetails?.firstName,
      lastName: addressDetails?.lastName,
      // field1: "23.2626843,-106.4626734",
      field1: coords,
      city: dataDireccion?.city,
      country: "MX",
      state: dataDireccion?.state,
      zipCode: dataDireccion.zipCode,
      nickName: dataDireccion?.nickName,
      email1: addressDetails?.email1,
      addressType: "ShippingAndBilling",
      addressLine: [dataDireccion?.calle, dataDireccion?.numeroExt, dataDireccion?.colonia],
      xaddr_addressField3: dataDireccion?.apartamento,
    };

    personContactService
      .addPersonContact({
        body: newAddressData,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then((addressData) => {

        handleGuardadoExito();
        handleCloseDireccion();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleEditDireccion = async () => {
    const gpsAddress = `${dataDireccion?.state},${dataDireccion?.city},${dataDireccion?.colonia},${dataDireccion?.apartamento},${dataDireccion?.zipCode}`;
    let coords = "";

    const geocoder = new window["google"].maps.Geocoder();

    await geocoder.geocode(
      {
        address: gpsAddress,
        componentRestrictions: {
          country: "MX",
        },
      },
      function (results, status) {
        if (results && results.length > 0 && status === "OK") {
          const result = results[0];
          coords = result.geometry.location.lat() + "," + result.geometry.location.lng();
        }
      }
    );

    let newAddressData: any = {};

    if (selectedAddress.primary === "true") {
      newAddressData = {
        addressLine: [dataDireccion?.calle, dataDireccion?.numeroExt, dataDireccion?.colonia],
        addressLine1: dataDireccion?.calle,
        addressLine2: dataDireccion?.numeroExt,
        addressLine3: dataDireccion?.colonia,
        addressType: "ShippingAndBilling",
        city: dataDireccion?.city,
        email1: addressDetails?.email1,
        field3: dataDireccion?.apartamento,
        xaddr_addressField3: dataDireccion?.apartamento,
        firstName: addressDetails?.firstName,
        lastName: addressDetails?.lastName,
        nickName: dataDireccion?.nickName,
        state: dataDireccion?.state,
        zipCode: dataDireccion?.zipCode,
      };
    } else {
      newAddressData = {
        firstName: addressDetails?.firstName,
        lastName: addressDetails?.lastName,
        field1: coords,
        city: dataDireccion?.city,
        country: "MX",
        state: dataDireccion?.state,
        zipCode: dataDireccion.zipCode,
        nickName: dataDireccion?.nickName,
        email1: addressDetails?.email1,
        addressType: "ShippingAndBilling",
        addressLine: [dataDireccion?.calle, dataDireccion?.numeroExt, dataDireccion?.colonia],
        xaddr_addressField3: dataDireccion?.apartamento,
      };
    }

    personContactService
      .updatePersonContact({
        nickName: dataDireccion?.nickName,
        body: newAddressData,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then(async (addressData) => {
        if (addressData?.userId || addressData?.addressId) {

          const addRes = await personService.findPersonBySelf(payloadBase);
          if (addRes.data) {
            const contactMap = {};
            const contactList = addRes.data.contact;

            if (contactList) {
              contactList.forEach((address: any) => {
                if (address && address.addressId) {
                  contactMap[address.addressId] = address;
                }
              });

              const { contact, ...person } = addRes.data;
              const newPerson = {
                ...person,
                contactMap,
                contactList,
              };
              updateCheckoutProfile(newPerson);
            } else {
              const newPerson = {
                ...addRes.data,
              };
              updateCheckoutProfile(newPerson);
            }
          }

          handleGuardadoExito();
          handleCloseDireccion();
        }
        
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getObjWithPrefix = useCallback((list, v, prefix) => {
    const acceptedKeys = [
      "addressLine",
      "addressId",
      "city",
      "country",
      "zipCode",
      "state",
      "firstName",
      "lastName",
      "nickName",
    ];
    const obj = list.find(({ nickName }) => nickName === v);
    const acceptedMap = storeUtil.toMap(acceptedKeys);
    const rc = {};
    if (obj) {
      Object.keys(obj)
        .filter((k) => acceptedMap[k])
        .forEach((k) => (rc[`${prefix}${k}`] = obj[k]));
    }
    return rc;
  }, []);

  const updateCheckoutProfile = async (newPerson) => {
    const addresses: any[] = [];
    if (newPerson?.contactList) {
      newPerson.contactList.forEach((address) => addresses.push(address));
    }
    if (newPerson?.addressLine) {
      addresses.push(addressUtil.getRegisteredInitialAddress(newPerson));
    }

    const p = profileList.map((profile) => {
      if (!profile.isValid) {
        return Promise.resolve();
      }
      const body: any = {};

      const shipData = getObjWithPrefix(addresses, profile.shipping_nickName, "shipping_");
      const billData = getObjWithPrefix(addresses, profile.billing_nickName, "billing_");
      const pm = get(profile, "billingData.payment_method.value");
      const isCreditCard = PAYMENT.paymentMethodName[pm];

      Object.assign(body, {
        profileName: profile.xchkout_ProfileName,
        ...shipData,
        ...billData,
        shipping_modeId: profile.shipping_modeId,
        URL: "noURL",
        pay_payment_method: pm,
      });

      if (currentUser) {
        const { userId } = currentUser;
        Object.assign(body, { userId });
      }

      if (isCreditCard) {
        Object.assign(body, {
          pay_account: get(profile, "billingData.account.value"),
          pay_expire_month: get(profile, "billingData.expire_month.value"),
          pay_expire_year: get(profile, "billingData.expire_year.value"),
          pay_cc_brand: pm,
        });
      }

      const checkoutProfileApi = new CheckoutProfileApi(undefined, site.transactionContext);
      return checkoutProfileApi.checkoutProfileUpdateCheckoutProfileById(
        mySite.storeID,
        profile.xchkout_ProfileId,
        RF_JSON,
        body
      );
    });

    try {
      await Promise.all(p);
    } catch (e) {
      console.log("Could not update the checkout profile for address", e);
    }
  };

  let hasDirecciones = false;
  if (misDirecciones && misDirecciones.length !== 0) {
    hasDirecciones = true;
  }

  return (
    <StyledBox mb={2} className="card-direccion-entrega" ref={AddressListRef}>
      <StyledCardEmpty
        style={{
          padding: "10px",
          // minHeight: refHeights?.addressListHeight < refHeights?.paymethodsHeight && !isMobile ? refHeights?.paymethodsHeight : ""
          minHeight:
            selectedMetodoPago?.paymentMethodName === "PayPal"
              ? refHeights?.paymethodsHeight
              : refHeights?.addressListHeight < refHeights?.paymethodsHeight && !isMobile
              ? refHeights?.paymethodsHeight
              : refHeights?.addressListHeight,
        }}>
        <TitleCard title="Dirección de entrega" alinear="left" />

        {hasDirecciones ? (
          <>
            <StyledBox display="flex" alignItems="center" justifyContent="flex-start">
              <AddIcon color="primary" fontSize="small" onClick={() => handleOpenDireccion("nuevo")} />
              <StyledLink onClick={() => handleOpenDireccion("nuevo")}>Agregar dirección</StyledLink>
            </StyledBox>
            <StyledBox>
              <Direcciones
                logonId={logonId}
                misDirecciones={misDirecciones}
                selectedAddress={selectedAddress}
                isEditable={true}
                handleSelectedAddress={handleSelectedAddress}
                handleOpenDireccion={handleOpenDireccion}
                fromCheckoutProfiles
              />
            </StyledBox>
          </>
        ) : (
          <>
            <StyledBox>
              <StyledTypography varinat="bodyBaseline" className="gray300" align="center">
                Aún no tienes direcciones guardadas.
              </StyledTypography>
            </StyledBox>
            <StyledBox align="center">
              <StyledBox
                display="flex"
                width={130}
                height={130}
                borderRadius="50%"
                bgcolor={palette.gray["50"]}
                p={2}
                alignItems="center"
                justifyContent="center">
                <SvgIcon
                  component={HomeIcon}
                  style={{ height: "100px", width: "100px" }}
                  viewBox="2 2 20 20"
                  className="colorIconPaper"
                  stroke={palette.gray["500"]}
                  strokeWidth="0.3">
                  {/* <HomeIcon /> */}
                </SvgIcon>
              </StyledBox>
            </StyledBox>

            <StyledBox mt={2}>
              <StyledButton variant="outlined" onClick={() => handleOpenDireccion("nuevo")}>
                Agregar dirección
              </StyledButton>
            </StyledBox>
          </>
        )}
      </StyledCardEmpty>

      <StyledSwipeableDrawersDireccion
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchor="right"
        disableEnforceFocus>
        <ContainerCheckoutProfile className="styled--createDirectionDrawer">
          <StyledBox p={2}>
            <StyledBox style={{ position: "absolute", right: "0px", top: "0px", padding: "10px" }}>
              <CancelOutlinedIcon
                style={{ color: palette.gray["100"], cursor: "pointer" }}
                onClick={handleCloseDireccion}
              />
            </StyledBox>
            <StyledTypography variant="textSegment" align="center">
              {isEdit ? "Editar dirección" : "Agregar dirección"}
            </StyledTypography>
          </StyledBox>
          <Divider style={{ backgroundColor: palette.gray["50"], marginBottom: "20px" }} />

          <StyledBox px={2} className="styled--createDirectionDrawer-content">
            <CheckoutProfileAddressForm
              misDirecciones={misDirecciones}
              handleCanSaveDireccion={handleCanSaveDireccion}
              selectedAddress={selectedAddress}
              isEdit={isEdit}
              isOpen={isOpen}
              setIsInBlockList={setIsInBlockList}
            />{isInBlockList ? (
              <StyledGrid xs={12} md={12} style={{ paddingTop: "10px",paddingLeft: "4px", paddingRigth: "18px" }} >
              <StyledBox id={"error-blocklist-message"} px={2} item className={"prompt-container-pro"}>
                <StyledPromp
                  variant="error"
                  message={t("DeliveryZone.ChooseDeliveryzoneCheckout.NoAvailableZone")}
                />
              </StyledBox></StyledGrid>
            ) : null}
          </StyledBox>

          <StyledBox>
            <StyledBox className="container-fixdown-drawer">
              <StyledBox mb={2} className="container-fixdown-sticky">
              {isInBlockList ? (
              <StyledBox id={"error-blocklist-message"} px={2} item className={"prompt-container-pro"}>
                <StyledPromp
                  variant="error"
                  openOutOfRange={isInBlockList}
                  message={t("DeliveryZone.ChooseDeliveryzoneCheckout.NoAvailableZone")}
                />
              </StyledBox>
            ) : null}
                <StyledButton
                  varinat="contained"
                  disabled={!guardarDireccion || isInBlockList}
                  onClick={isEdit ? handleEditDireccion : handleSaveDireccion}>
                  Guardar dirección
                </StyledButton>
                <StyledBox py={2}>
                  <StyledLink onClick={handleCloseDireccion}>Cancelar</StyledLink>
                </StyledBox>
              </StyledBox>
            </StyledBox>
          </StyledBox>
        </ContainerCheckoutProfile>
      </StyledSwipeableDrawersDireccion>
    </StyledBox>
  );
};

export default DireccionDeEntrega;
