import { Divider, InputAdornment, useMediaQuery } from "@material-ui/core";
import { StyledAccordion, StyledBox, StyledCardEmpty, StyledContainer, StyledGrid, StyledPaper, StyledTextField, StyledTypography } from "../../../../../hdm";
import { tabletBreakpoint } from "../../../../../hdm/functions/utils";
import { palette } from "../../../../../hdm/themes";
import theme from "../../../../../hdm/themes/thd";
import { StyledContainerHDM, StyledHeaderHDM } from "../../../../header/Header";
import { SLFooterElements } from "../dimensions/Footer";
import { SLStyledTypography } from "../SLStyledTypography";
import { StyledSkeleton } from "../StyledSkeleton";
import SearchIcon from "@material-ui/icons/Search";
import { StyledCardContainer } from "../../../../../hdm/elements/card/styled-card-styles";
import { PaypalMyAccountContainer } from "../../../../pages/payment-method-book/paypalMyAccountStyles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { HDmHeadingTitle } from "../../../../../hdm/components/hdm-heading-main-title/HdmHeadingMainTitle";
import { useTranslation } from "react-i18next";
import HomeSharpIcon from "@material-ui/icons/HomeSharp";
import { StyledAddAddressCard, StyledAddAddressCardContainer } from "../../../../pages/address-book/AddressBookStyles";
import { StyledDivider } from "../../../../header/categories-menu/categories-styles";
import { ContainerOrderHistory } from "../../../../pages/_sapphire/order/container-order-history";
import { SpecialTitle } from "../../../../../hdm/components/hdm-special-title";
import { SLShoppingCart } from "../dimensions/ShoppingCart";

export const SLMainAddressCard = () => {
    return (
        <StyledGrid container>
            <StyledGrid item xs={1}>
                <StyledSkeleton className={"top-margin-1"} variant="rect" width={20} height={20} />
            </StyledGrid>
            <StyledGrid item xs={11}>
                <StyledSkeleton className={"bottom-margin-1 right-padding-1 wide"} variant="rect" height={20} />
                <StyledSkeleton className={"bottom-margin-1 right-padding-1 wide"} variant="rect" height={20} />
                <StyledSkeleton className={"bottom-margin-1 right-padding-1 wide"} variant="rect" height={20} />
            </StyledGrid>
        </StyledGrid>
    )
}

export const SLPersonalInfoComponent = () => {
    return (
        <StyledGrid container spacing={2}>
            <StyledGrid container item xs={12} spacing={2}>
                <StyledGrid item xs={1}>
                    <StyledSkeleton variant="rect" width={20} height={20} />
                </StyledGrid>
                <StyledGrid item xs={11}>
                    <StyledSkeleton className={"wide"} variant="rect" height={20} />
                </StyledGrid>
            </StyledGrid>
            <StyledGrid container item xs={12} spacing={2}>
                <StyledGrid item xs={1}>
                    <StyledSkeleton variant="rect" width={20} height={20} />
                </StyledGrid>
                <StyledGrid item xs={11}>
                    <StyledSkeleton className={"wide"} variant="rect" height={20} />
                </StyledGrid>
            </StyledGrid>
            <StyledGrid container item xs={12} spacing={2}>
                <StyledGrid item xs={1}>
                    <StyledSkeleton variant="rect" width={20} height={20} />
                </StyledGrid>
                <StyledGrid item xs={11}>
                    <StyledSkeleton className={"wide"} variant="rect" height={20} />
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    )
}
export const SLSimpleRecentOrderCard = (props) => {
    const { margin , top} = props;
    return (
        <StyledCardEmpty className={`order-card-container padding-2 ${margin?"right-margin-2":""} ${top?"top-margin-2":""}`}>
            <StyledGrid container item xs={12} className="SLRecentOrderCard">
                <StyledGrid container item xs={12} justifyContent="space-between">
                    <StyledGrid item xs={12}>
                        <StyledSkeleton noCard variant="text" width={"100%"} height={57} />
                    </StyledGrid>
                </StyledGrid>
                <StyledGrid item xs={12}>
                    <StyledDivider />
                </StyledGrid>
                <StyledGrid container item={12} justifyContent="space-between" className={"top-margin-1"}>
                    <StyledGrid item xs={6}>
                        <StyledSkeleton height={35} width={"90%"} />
                    </StyledGrid>
                    <StyledGrid item xs={6}>
                        <StyledSkeleton height={35} width={"100%"} />
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        </StyledCardEmpty>
    )
}
const SLRecentOrderCard = (props) => {
    return (
        <>
            <StyledGrid container item xs={12} className="SLRecentOrderCard">
                <StyledGrid container item xs={12} justifyContent="space-between">
                    <StyledGrid item xs={12}>
                        <StyledSkeleton noCard variant="text" width={"100%"} height={95} />
                    </StyledGrid>
                </StyledGrid>
                <StyledGrid item xs={12}>
                    <StyledDivider />
                </StyledGrid>
                <StyledGrid container item={12} justifyContent="space-between" className={"top-margin-1"}>
                    <StyledGrid item xs={6}>
                        <StyledSkeleton height={35} width={"90%"} />
                    </StyledGrid>
                    <StyledGrid item xs={6}>
                        <StyledSkeleton height={35} width={"100%"} />
                    </StyledGrid>
                </StyledGrid>

            </StyledGrid>
        </>

    );
}
const SLProfileCard = (props) => {
    return (
        <>
            <StyledGrid container item xs={12} className="SLRecentOrderCard" style={{ height: "202px" }} >
                <StyledGrid container item xs={12} justifyContent="space-between">
                    <StyledGrid item xs={12}>
                        <StyledSkeleton variant="text" width={"100%"} height={130} />
                    </StyledGrid>
                </StyledGrid>
                <StyledGrid item xs={12}>
                    <StyledDivider />
                </StyledGrid>
                <StyledGrid container item={12} justifyContent="space-between" className={"top-margin-1"}>
                    <StyledGrid item xs={6}>
                        <StyledSkeleton height={35} width={"90%"} />
                    </StyledGrid>
                    <StyledGrid item xs={6}>
                        <StyledSkeleton height={35} width={"100%"} />
                    </StyledGrid>
                </StyledGrid>

            </StyledGrid>
        </>

    );
}

const SLProfileCardFirst = (props) => {
    return (
        <>
            <StyledGrid container item xs={12} className="SLRecentOrderCard" style={{ height: "202px" }} >
                <StyledGrid container item xs={12} justifyContent="space-between">
                    <StyledGrid item xs={12}>
                        <StyledSkeleton variant="text" width={"100%"} height={190} />
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        </>

    );
}

export const SLRecentOrdersComponent = () => {
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
    return (
        <StyledBox display="flex" flexDirection={isTablet ? "column" : "row"} flexWrap={"wrap"} mt={2}>

            <StyledCardEmpty className={`order-card-container padding-2 right-margin-1 top-margin-2`}>
                <SLRecentOrderCard />
            </StyledCardEmpty>
            <StyledCardEmpty className={`order-card-container padding-2 ${!isTablet && "horizontal-margin-1"} top-margin-2`}>
                <SLRecentOrderCard />
            </StyledCardEmpty>
            <StyledCardEmpty className={`order-card-container padding-2 ${!isTablet && "left-margin-1"} top-margin-2`}>
                <SLRecentOrderCard />
            </StyledCardEmpty>
        </StyledBox>


    )
}

export const SLRecentOrdersComponentWithFilter = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
    return (
        <>
            <StyledBox px={isMobile ? "10px" : "0px"}>
                <StyledCardEmpty style={{ padding: "10px" }}>
                    <StyledBox item display="flex" justifyContent="center" alignItems="center" >
                        <SLStyledTypography variant="textSegment" align="center" />
                    </StyledBox>

                    <StyledBox pt={2}>
                        <Divider style={{ backgroundColor: palette.gray["50"] }} />
                    </StyledBox>

                    <StyledBox display="flex" justifyContent="center" alignItems="center">
                        <StyledBox flexGrow={1}>
                            <StyledTextField
                                type="text"
                                placeholder="Buscar por número de orden"
                                variant="outlined"
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon style={{ color: "#DDD" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </StyledBox>
                        {isMobile ? null : (
                            <StyledBox px={2} pt={1}>
                                <SLStyledTypography />
                            </StyledBox>
                        )}
                    </StyledBox>
                </StyledCardEmpty>
                {!isMobile && (<StyledBox className="top-margin-2">
                    <SLStyledTypography className="filtros-aplicados" variant="bodyBaseline" weight="semibold" />
                </StyledBox>)}
            </StyledBox>

            {!isMobile && (<StyledBox display="flex" flexDirection={isTablet ? "wrap" : "row"} flexWrap={"wrap"} mt={2}>
                <StyledCardEmpty className="order-card-container padding-2 right-margin-1">
                    <SLRecentOrderCard />
                </StyledCardEmpty>
                <StyledCardEmpty className="order-card-container padding-2 horizontal-margin-1">
                    <SLRecentOrderCard />
                </StyledCardEmpty>
                <StyledCardEmpty className="order-card-container padding-2 left-margin-1">
                    <SLRecentOrderCard />
                </StyledCardEmpty>
            </StyledBox>)}
            {isMobile && (
                <StyledBox display="flex" flexDirection={isTablet ? "column" : "row"} flexWrap={"wrap"} mt={2} className={"left-padding-2 right-padding-2 top-margin-3"} >
                    <StyledCardEmpty className="order-card-container padding-2 right-margin-1">
                        <SLRecentOrderCard />
                    </StyledCardEmpty>
                    <StyledCardEmpty className="order-card-container padding-2 top-margin-2">
                        <SLRecentOrderCard />
                    </StyledCardEmpty>
                    <StyledCardEmpty className="order-card-container padding-2 top-margin-2">
                        <SLRecentOrderCard />
                    </StyledCardEmpty>
                </StyledBox>)}
        </>


    )

}

const SLAddressCard = () => {
    const styles = {
        minHeight: "238px",
        height: "238px",
        minWidth: "143px",
        width: "auto"
    }
    return (

        <StyledAccordion className={"horizontal-margin-1"} style={styles}>
            <StyledGrid container xs={12}
                direction="column"
                justifyContent="space-between"
            >
                <StyledGrid container item xs={12} >
                    <StyledGrid item xs={12}>
                        <StyledSkeleton className={"bottom-margin-1"} height={35}  />
                    </StyledGrid>
                    <StyledGrid item xs={12} style={{ marginTop: "-30px" }}>
                        <StyledSkeleton height={170}  />
                    </StyledGrid>
                </StyledGrid>

                <StyledGrid container item xs={12} className={"bottom-margin-2"}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <StyledGrid item xs={12}>
                        <StyledDivider />
                    </StyledGrid>
                    <StyledGrid container item={12} justifyContent="space-between" spacing={4}>
                        <StyledGrid item xs={6}>
                            <StyledSkeleton height={30} />
                        </StyledGrid>
                        <StyledGrid item xs={6}>
                            <StyledSkeleton height={30} />
                        </StyledGrid>
                    </StyledGrid>

                </StyledGrid>
            </StyledGrid>
        </StyledAccordion>
    )
}

export const SLAddressCards = ({ isMobile, isTablet }) => {
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { t } = useTranslation();

    if (isMobile && !isTablet) return (
        <>
            <StyledGrid item xs={12} className="horizontal-margin-1" style={{ marginLeft: "0px" }}>
                <StyledAddAddressCardContainer item container xs={12}>
                    <StyledAddAddressCard id={"address--list-addBtn"} >
                        <StyledBox className="icon-background">
                            <HomeSharpIcon />
                        </StyledBox>
                        <StyledTypography variant="bodyBaseline" className={`left-padding-2`}>
                            {t("AddressBook.AddrMsg")}
                        </StyledTypography>
                        {isMobile ? <ChevronRightIcon className="address--chevronRightIcon" /> : null}
                    </StyledAddAddressCard>
                </StyledAddAddressCardContainer>
            </StyledGrid>
            <StyledGrid item xs={12} className="top-padding-2">
                <SLAddressCard />
            </StyledGrid>
            <StyledGrid item xs={12} className="top-padding-2">
                <SLAddressCard />
            </StyledGrid>
        </>
    )
    return (
        <>
            <StyledGrid container spacing={2}>
                <StyledGrid item xs={4} >
                    <StyledAddAddressCardContainer item container xs={12}>
                        <StyledAddAddressCard id={"address--list-addBtn"} >
                            <StyledBox className="icon-background">
                                <HomeSharpIcon />
                            </StyledBox>
                            <StyledTypography variant="bodyBaseline" className={`${isMobile ? "left-padding-2" : "top-padding-4"}`}>
                                {t("AddressBook.AddrMsg")}
                            </StyledTypography>
                            {isMobile ? <ChevronRightIcon className="address--chevronRightIcon" /> : null}
                        </StyledAddAddressCard>
                    </StyledAddAddressCardContainer>
                </StyledGrid>
                <StyledGrid item xs={4}>
                    <SLAddressCard />
                </StyledGrid>
                <StyledGrid item xs={4}>
                    <SLAddressCard />
                </StyledGrid>
            </StyledGrid>
        </>
    )
}

export const SLAccountProfilesWithFilter = ({ isMobile, isTablet }) => {
    // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // const isTablet = useMediaQuery(theme.breakpoints.down(tabletBreakpoint()));
    const { t } = useTranslation();
    console.log('isTablet', isTablet);

    return (
        <>
            <StyledGrid
                item
                xs={12}
                md={9}
                className="stylegrid--misperfilesdecompra-container"
                id={"purchaseProfile--container"}>

                <HDmHeadingTitle title={t("CheckoutProfile.checkoutProfileTitle")} />

                <StyledBox style={{ height: "31px" }} className={"top-margin-4"} >
                    <StyledTypography variant="bodySubtext" className="styled--savetimeonpurchase-text">
                        {t("CheckoutProfile.saveTimeOnPurchase")}
                    </StyledTypography>
                </StyledBox>
                <StyledGrid item xs={12}
                    md={12}
                    // className="stylegrid--misperfilesdecompra-container"
                    id={"purchaseProfile--container"}
                >
                    <StyledBox px={isMobile ? "10px" : "0px"}>
                        <StyledCardEmpty style={{ padding: "10px" }}>
                            <StyledBox item display="flex" justifyContent="center" alignItems="center" >
                                <SLStyledTypography variant="textSegment" align="center" />
                            </StyledBox>
                            <StyledBox pt={2}>
                                <Divider style={{ backgroundColor: palette.gray["50"] }} />
                            </StyledBox>

                            <StyledBox display="flex" justifyContent="center" alignItems="center">
                                <StyledBox flexGrow={1}>
                                    <StyledTextField
                                        type="text"
                                        placeholder="Buscar por alias"
                                        variant="outlined"
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon style={{ color: "#DDD" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </StyledBox>
                            </StyledBox>
                        </StyledCardEmpty>
                        {isMobile && (<StyledBox className="top-margin-2">
                            <StyledCardEmpty className={"order-card-container "} style={{ paddingLeft: "20px", paddingBottom: "5px" }} >
                                <StyledSkeleton width={"90%"} height={50} className="" />
                            </StyledCardEmpty>
                        </StyledBox>)}
                        {!isMobile && (
                            <StyledBox display="flex" flexDirection={isTablet ? "wrap" : "row"} flexWrap={"wrap"} mt={2}>
                                <StyledCardEmpty style={{ width: "32.6%" }} className="order-card-container padding-2 right-margin-1">
                                    <SLProfileCardFirst />
                                </StyledCardEmpty>
                                <StyledCardEmpty style={{ width: "32.6%" }} className="order-card-container padding-2 horizontal-margin-1">
                                    <SLProfileCard />
                                </StyledCardEmpty>
                                <StyledCardEmpty style={{ width: isTablet ? "32%" : "32.6%" }} className="order-card-container padding-2 left-margin-1">
                                    <SLProfileCard />
                                </StyledCardEmpty>
                            </StyledBox>)}
                        {isMobile && (
                            <StyledBox display="flex" flexDirection={isTablet ? "column" : "row"} flexWrap={"wrap"} mt={2} className={" top-margin-3"} >
                                <StyledCardEmpty className="order-card-container padding-2">
                                    <SLRecentOrderCard />
                                </StyledCardEmpty>
                                <StyledCardEmpty className="order-card-container padding-2 top-margin-2">
                                    <SLRecentOrderCard />
                                </StyledCardEmpty>
                                <StyledCardEmpty className="order-card-container padding-2 top-margin-2">
                                    <SLRecentOrderCard />
                                </StyledCardEmpty>
                            </StyledBox>)}
                    </StyledBox>
                </StyledGrid>
            </StyledGrid>
        </>


    )

}

//payment methods

export const SLPaymentCards = () => {
    return (
        <StyledGrid item xs={12} md={8} style={{ textAlign: "center" }}>
            <StyledPaper className="payment-method-card-no-card-skeleton">
                <div className="head-card-payment-info-no-card">

                </div>
                <div className="body-card-payment-no-card">
                    <StyledSkeleton className="paypal--link-account-text" variant="rect" width={103} height={18} />
                </div>
            </StyledPaper>
        </StyledGrid>
    )
}

export const SLPaypalPayment = () => {
    return (


        <StyledGrid container xs={12} spacing={2}>
                        {/* <div id="paypal-button-container-BA" className="paypal--button-container-BA"></div> */}
            <StyledGrid item xs={12} md={4} id={"payment--paypal-addCard"}>
                <StyledPaper className="paypal-link-account-container">
                    <StyledBox className="paypal-link-account-wrapper">
                        <StyledBox className="paypal--icon-circle-background bottom-margin-1">
                            <StyledSkeleton className={"right-margin-1"} variant="circle" width={60} height={60} />
                        </StyledBox>
                        <StyledSkeleton className="paypal--link-account-text" variant="rect" width={103} height={18} />
                    </StyledBox>
                    <StyledBox>
                        <ChevronRightIcon className="paypal--chevron-icon" color="primary" />
                    </StyledBox>
                </StyledPaper>
            </StyledGrid>
            <StyledGrid item xs={12} md={8}>
                <StyledPaper className="paypal--unlinked-account-container">
                    <StyledBox className="paypal--unlinked-icon-circle-background">
                    </StyledBox>
                    <StyledSkeleton className="paypal--unlinked-account-text" variant="rect" width={191} height={18} />

                </StyledPaper>
            </StyledGrid>
        </StyledGrid>

    )
}

const SLBreadCrumbs = ({ isMobile }) => {
    return (
        <StyledBox align="center" mb={1} display="flex" alignItems="center" className={"bottom-padding-3 top-padding-1"} style={{ overflow: isMobile ? "auto" : "" }} >
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={100} height={22} />
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={isMobile ? 27 : 22} height={22} />
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={100} height={22} />
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={isMobile ? 27 : 22} height={22} />
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={100} height={22} />
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={isMobile ? 27 : 22} height={22} />
            <StyledSkeleton noCard={true} sharp={true} className={"left-margin-1"} variant="rect" width={120} height={22} />

        </StyledBox>
    )
}
const SLOrderStatus = (props) => {
    const { isMobile } = props;
    return (
        <>
            <StyledGrid container item xs={12} className="padding-2" style={{ height: isMobile ? "231px" : "154px" }}  >
                <StyledGrid item xs={12}>
                    <StyledBox align="center" mb={3} display="flex" alignItems="center" justifyContent="center" >
                        <StyledTypography variant="headingFeatured" component="span" className={"left-padding-1"} >
                            <SLStyledTypography variant="textSegment" align="center" />
                        </StyledTypography>
                    </StyledBox>
                </StyledGrid>
                <StyledGrid item xs={12}>
                    <StyledDivider />
                </StyledGrid>
                <StyledGrid container item xs={12} justifyContent="space-between" >
                    <StyledGrid item xs={12} style={{ marginTop: "-20px" }}>
                        <StyledBox align="center" display="flex" alignItems="center" justifyContent="space-between" width={isMobile ? "100%" : "80%"} style={{ gap: isMobile ? "0" : "30px" }} flexDirection={isMobile ? "column" : "row"}>
                            <StyledSkeleton variant="text" width={"100%"} height={130} />
                            <div style={{ marginTop: isMobile ? "-45px" : "", width: "100%" }}>
                                <StyledSkeleton variant="text" width={"100%"} height={130} />
                            </div>
                        </StyledBox>
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid>
        </>

    );
}
const SLProductsOrder = (props) => {
    const { isMobile } = props;
    console.log('props', props);

    return (
        <>
            <StyledGrid container item style={{ justifyContent: "center" }}>
                <StyledGrid item xs={6} md={8} lg={7}
                    style={{ display: "inline-flex", justifyContent: "center", borderRight: "1px solid #EEEEEE" }}>
                    <StyledSkeleton variant="rect" width={116.78} height={18} />
                </StyledGrid>
            </StyledGrid>
            <Divider className="top-margin-2" />
            {/* Datos del producto */}
            <StyledGrid container style={{ width: "inherit", borderBottom: "1px solid #EEEEEE" }}>
                {/* Datos principales */}
                <StyledGrid container item xs={8} style={{ padding: "10px", borderRight: "1px solid #EEEEEE", paddingRight: "10px" }}>
                    {/* Imagen */}
                    <StyledGrid item style={{ marginBottom: "10px", paddingRight: "10px" }}>
                        <StyledSkeleton
                            variant="rect"
                            width={SLShoppingCart.details.product.info.image.getWidth()}
                            height={SLShoppingCart.details.product.info.image.getHeight()}
                        />
                    </StyledGrid>
                    {/* Nombre y SKU */}
                    <StyledGrid item style={{ width: "calc(100% - 70px)" }}>
                        <StyledGrid item style={{ marginBottom: "10px" }}>
                            <StyledSkeleton variant="rect" width={"inherit"} height={34} />
                        </StyledGrid>
                        <StyledGrid item>
                            <StyledSkeleton variant="rect" width={71.4} height={13.6} />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
                {/* Precio */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
            </StyledGrid>

            <StyledGrid container style={{ width: "inherit", height: "56px" }}>
                <StyledGrid item xs={8} style={{ borderRight: "1px solid #EEEEEE",display: "flex", alignItems: "center", justifyContent: "left", gap: "25%"  }}>
                    <StyledSkeleton variant="rect" width={"40%"} height={18} />
                    <StyledSkeleton variant="rect" width={"30%"} height={18} />
                </StyledGrid>
                {/* Subtotal */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
            </StyledGrid>
            <Divider />
            {/* <Divider className="top-margin-2" /> */}
            {/* Datos del producto */}
            <StyledGrid container style={{ width: "inherit", borderBottom: "1px solid #EEEEEE" }}>
                {/* Datos principales */}
                <StyledGrid container item xs={8} style={{ padding: "10px", borderRight: "1px solid #EEEEEE", paddingRight: "10px" }}>
                    {/* Imagen */}
                    <StyledGrid item style={{ marginBottom: "10px", paddingRight: "10px" }}>
                        <StyledSkeleton
                            variant="rect"
                            width={SLShoppingCart.details.product.info.image.getWidth()}
                            height={SLShoppingCart.details.product.info.image.getHeight()}
                        />
                    </StyledGrid>
                    {/* Nombre y SKU */}
                    <StyledGrid item style={{ width: "calc(100% - 70px)" }}>
                        <StyledGrid item style={{ marginBottom: "10px" }}>
                            <StyledSkeleton variant="rect" width={"inherit"} height={34} />
                        </StyledGrid>
                        <StyledGrid item>
                            <StyledSkeleton variant="rect" width={71.4} height={13.6} />
                        </StyledGrid>
                    </StyledGrid>
                </StyledGrid>
                {/* Precio */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
            </StyledGrid>

            <StyledGrid container style={{ width: "inherit", height: "56px" }}>
                <StyledGrid item xs={8} style={{ borderRight: "1px solid #EEEEEE",display: "flex", alignItems: "center", justifyContent: "left", gap: "25%"  }}>
                    <StyledSkeleton variant="rect" width={"40%"} height={18} />
                    <StyledSkeleton variant="rect" width={"30%"} height={18} />
                </StyledGrid>
                {/* Subtotal */}
                <StyledGrid item xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <StyledSkeleton variant="rect" width={60} height={31} />
                </StyledGrid>
            </StyledGrid>
            {/* <Divider /> */}

            {/* <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit", height: "62px", columnGap: "10px" }}>
                <StyledGrid container item xs={12} md={6} lg={5} style={{ flexWrap: "nowrap" }}>
                    <StyledGrid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                        <StyledSkeleton variant="rect" width={125} height={42} />
                    </StyledGrid>
                    <StyledGrid item xs={6} style={{ width: "100%", display: "flex", alignItems: "center", paddingLeft: "18px" }}>
                        <StyledSkeleton variant="rect" width={112.55} height={18} />
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid> */}

            {/* <StyledGrid container style={{ flexWrap: "nowrap", width: "inherit", height: "62px", columnGap: "10px" }}>
                <StyledGrid container item xs={12} md={5} lg={5} style={{ flexWrap: "nowrap", columnGap: "10px" }}>
                    <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center" }}>
                        <StyledSkeleton variant="rect" width={"inherit"} height={40} />
                    </StyledGrid>
                    <StyledGrid item style={{ display: "flex", width: "100%", alignItems: "center" }}>
                        <StyledSkeleton variant="rect" width={"inherit"} height={40} />
                    </StyledGrid>
                </StyledGrid>
            </StyledGrid> */}
        </>
    )
}


export const SLCardProductsOrder = (props) => {
    const { isMobile } = props;
    return (
        <div className={isMobile ? "top-margin-2" : ""}>
            <StyledCardEmpty className={"padding-2 "}>
                <SLProductsOrder {...props} />
            </StyledCardEmpty>
        </div>)
}

export const SLOrderDetails = ({ isMobile, isB2B }) => {
    const m = isMobile;

    return (

        <StyledContainer style={{ width: "100%", margin: "0 auto" }} container className="full-width">
            {/* <StyledGrid item md={1}  xs={12} ></StyledGrid>
            <StyledGrid item md={10} xs={12} > */}
                <SLBreadCrumbs isMobile={isMobile} />
                <ContainerOrderHistory>
                    <SpecialTitle titulo="Detalle de mi orden" width="455px" />
                    <StyledBox align="center" mb={3} display="flex" alignItems="center" justifyContent="center" >
                        <StyledTypography variant="bodyBaseline" component="span">
                            Número de orden
                        </StyledTypography>
                        <StyledTypography variant="headingFeatured" component="span" className={"left-padding-1"} >
                            <SLStyledTypography variant="textSegment" align="center" />
                        </StyledTypography>
                    </StyledBox>
                    <StyledBox align="center" mb={1} display="flex" alignItems="center" justifyContent="center" >
                        <StyledTypography variant="bodyBaseline" component="span">
                            Recibida el
                        </StyledTypography>
                        <StyledTypography variant="headingFeatured" component="span" className={"left-padding-1"} >
                            <SLStyledTypography variant="textSegment" align="center" />
                        </StyledTypography>
                    </StyledBox>
                    <StyledBox display="flex" flexDirection={isMobile ? "column" : "row"} mt={2} mx={isMobile ? 2 : 0}>
                        <StyledBox className="detalle-orden-lado-izquierdo">
                            <StyledCardEmpty>
                                <SLOrderStatus isMobile={isMobile} />
                            </StyledCardEmpty>
                            <StyledCardEmpty className={"top-margin-2"}>
                                <SLOrderStatus isMobile={isMobile} />
                            </StyledCardEmpty>
                        </StyledBox>

                        <StyledBox display="flex" flexDirection={isMobile ? "column" : "row"} mt={m ? 2 : 0} className={"detalle-orden-lado-derecho"} >
                            <div  style={{width:"98%"}}>
                                <SLCardProductsOrder isMobile={isMobile} />
                            </div>
                        </StyledBox>
                    </StyledBox>
                </ContainerOrderHistory>
            {/* </StyledGrid>
            <StyledGrid item md={1}  xs={12} ></StyledGrid> */}

        </StyledContainer>
    )
}

export const SLPaymentContainer = () => {
    return (
        <StyledContainer container className={"card-metodo-pago bottom-margin-2"} style={{height:"248px"}}>
            <StyledCardEmpty className={"vertical-padding-2 horizontal-padding-2"} style={{height:"100%"}}>
                <StyledGrid container >
                    <StyledGrid item xs={12} >
                        <StyledSkeleton sharp={true} className={"wide"} variant="rect" height={21} width={"170px"}  />
                    </StyledGrid>
                    <StyledGrid item xs={12} className={"top-padding-2 bottomt-padding-2"}>
                        <Divider style={{ backgroundColor: palette.gray["50"] }} />
                    </StyledGrid>
                    <StyledBox display="flex" flexDirection={"column"}  className={"top-margin-2"} style={{gap: "10px", width:"100%"}} >
                        <StyledSkeleton sharp={true} variant="rect" height={37} width={"100%"} />
                        <StyledSkeleton sharp={true} variant="rect" height={37} width={"100%"} />
                        <StyledSkeleton sharp={true} variant="rect" height={37} width={"100%"} />
                        <StyledSkeleton sharp={true} variant="rect" height={37} width={"100%"} />

                    </StyledBox>
                </StyledGrid>
            </StyledCardEmpty>
        </StyledContainer>
    )
}