import { Divider } from "@material-ui/core";
import { useEffect } from "react";
//Standard libraries
import { useTranslation } from "react-i18next";
import { StyledGrid, StyledTypography } from "../../../../elements";
import FormattedPriceDisplay from "../../../../widgets/formatted-price-display";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { useSite } from "../../../../../_foundation/hooks/useSite";
import { EMPTY_STRING } from "../../../../../constants/common";

const OpenPay = ({ ...props }) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const orderDataTYP = props.orderDataTYP;
  const orderData = props.orderData;
  const storeConfData = props.storeConfData;
  const paymentInstruction = orderData?.paymentInstruction;
  let bank = "";
  let clabe = "";
  let agreement = "";
  let paymentAmount = "";
  let name = "";
  let description = "";
  if (paymentInstruction.length > 0) {
    const protocolData = paymentInstruction[0].protocolData;
    if (protocolData.length > 0) {
      bank = protocolData.filter((data) => data.name === "bank")[0]?.value;
      clabe = protocolData.filter((data) => data.name === "clabe")[0]?.value;
      agreement = protocolData.filter((data) => data.name === "agreement")[0]?.value;
      paymentAmount = protocolData.filter((data) => data.name === "paymentAmount")[0]?.value;
      name = protocolData.filter((data) => data.name === "name")[0]?.value;
      description = protocolData.filter((data) => data.name === "description")[0]?.value;
    }
  }
  return (
    <>
      <StyledGrid container>
        <StyledGrid xs={12}>
          <StyledTypography variant="headingFeatured" className={"orderConfirmation--styledTypography-upperCase"}>
            {t("OrderConfirmation.PaymentMethod.OpenPay.NameConfirm")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" component="span">
            {t("OrderConfirmation.PaymentMethod.OpenPay.completePayment")}
          </StyledTypography>
          <StyledTypography variant="bodyBaseline" weight="semibold" component="span">
            {storeConfData?.openpayPayExpHrs + " " + t("OrderConfirmation.hrs") + "."}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid xs={12} className={isB2B ? "vertical-padding-2" : EMPTY_STRING}>
          <Divider className={isB2B ? EMPTY_STRING : "vertical-margin-2"}/>
        </StyledGrid>
        <StyledGrid xs={12}>
          <StyledTypography variant="headingFeatured">
            {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.references")}
          </StyledTypography>
        </StyledGrid>
        <StyledGrid item xs={12} md={6} className="orderConfirmation--StyledGrid-container">
          <StyledGrid className={`orderConfirmation--StyledGrid-refenceContainer ${isB2B ? "top-margin-4" : "top-margin-2"}`}>
            <SwapHorizIcon className="orderConfirmation--icon-highLight orderConfirmation--icon-swapHoriz" />
            <StyledTypography variant="bodyBaseline" weight={"semibold"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.bbvaBank")}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.bbvaDetail.convenio")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" " + agreement}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.bbvaDetail.reference")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
            {" " + name}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"vertical-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.orderTotal")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" "}
              <FormattedPriceDisplay min={paymentAmount} currency={orderData?.grandTotalCurrency} />
            </StyledTypography>
          </StyledGrid>
        </StyledGrid>
        <StyledGrid item xs={12} md={6} className="orderConfirmation--StyledGrid-container">
          <StyledGrid className={`orderConfirmation--StyledGrid-refenceContainer ${isB2B ? "top-margin-4" : "top-margin-2"}`}>
            <SwapHorizIcon className="orderConfirmation--icon-highLight orderConfirmation--icon-swapHoriz" />
            <StyledTypography variant="bodyBaseline" weight={"semibold"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherBank")}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherDetail.beneficiary")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" The Home Depot México"}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherDetail.destinyBank")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" " + bank}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherDetail.clabe")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" " + clabe}
            </StyledTypography>
          </StyledGrid>
          {
            isB2B && (
              <StyledGrid item xs={12} className={"top-margin-2"}>
                <StyledTypography
                  variant="bodyBaseline"
                  className={"orderConfirmation--StyledTypography-darkGray"}
                  component={"span"}>
                  {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherDetail.concept")}
                </StyledTypography>
                <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
                  {" " + name}
                </StyledTypography>
              </StyledGrid>
            )
          }
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherDetail.reference")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" " + agreement}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.PaymentMethod.OpenPay.paymentReferences.otherDetail.paymentConcept")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {` ${isB2B ? description : name}`}
            </StyledTypography>
          </StyledGrid>
          <StyledGrid item xs={12} className={"top-margin-2"}>
            <StyledTypography
              variant="bodyBaseline"
              className={"orderConfirmation--StyledTypography-darkGray"}
              component={"span"}>
              {t("OrderConfirmation.orderTotal")}
            </StyledTypography>
            <StyledTypography variant="bodyBaseline" weight={"semibold"} className={"top-margin-1"} component={"span"}>
              {" "}
              <FormattedPriceDisplay min={paymentAmount} currency={orderData?.grandTotalCurrency} />
            </StyledTypography>
          </StyledGrid>
        </StyledGrid>
      </StyledGrid>
    </>
  );
};

export { OpenPay };
