import styled from "styled-components";
import { StyledDialog } from "../dialog";
import { StyledGrid } from "../grid";

export const StyledCardContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme ,..._props}) => `
    @media (max-width: 1024px){
        margin: ${theme.spacing(0)}px ${theme.spacing(1)}px;
    }
    @media (min-width: 960px){
        min-height: ${_props.maxHeight ? '302px' : '238px'};
        max-height: ${_props.maxHeight ? '302px' : '238px'};
    }    
    width: 100%;

    background-color: #ffffff;
    box-shadow: #cccccc 0 3px 6px;
    padding: ${theme.spacing(2)}px;



    & .text-center{
        text-align: center;
    }

    & .alias-title{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.3;
    }

    .location-icon{
        font-size: 15px;
        color: ${theme.palette.gray["200"]};
    }

    & .divider{
        width: 100%;
        border-top: 1px solid ${theme.palette.gray["50"]};
        height: 100%;
        display: flex;
        padding-top: 10px;
    }
    & .account-card-links{
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 0px;
    }
    & .content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    & .rigth-divider{
        border-right: solid 1px ${theme.palette.gray["50"]};
    }

    & .address-card-radiobutton{
        gap: 0;
        transform: translateX(-10px);
        margin-top: 5px;
        .MuiRadio-root {
        opacity: 0.5;
        }

        .MuiRadio-colorPrimary.Mui-checked {
        opacity: 1 !important;
        color:#F96302  !important;
        }
        .MuiRadio-root {
            color: #CCCCCC;
        }
        span:nth-child(2){
        transform: translateX(-5px);
        color: ${theme.palette.secondary.main};
        font-size: 15px;
        }
    }

    &.myaccount-addresscard{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .content-container{
            max-width: 100%;
            min-height: 57px; 

            & div{
                max-width: 100%;
                max-height: 92px;
                overflow: hidden;

                & span{
                    overflow: hidden;
                    display: block;
                }
                & p{
                    white-space: nowrap;
                }
            }
        }
    }
`}
`;

export const StyledAddressCardActionPopUp = styled(({ ...props }) => <StyledDialog {...props} />)`
  ${({ theme }) => `
    .MuiPaper-root.MuiDialog-paper {
        margin: 0;
        width: auto;
    }

    & button{
        margin-bottom: 0;
    }

    & .pop-up-bodytext{
        margin-bottom: ${theme.spacing(2)}px;
    }
}
`}
`;
