import { StyledRadio, StyledTypography, StyledGrid, StyledBox } from "../../../../hdm/elements";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { palette } from "../../../../hdm/themes";

// Icons
import CheckIcon from "@material-ui/icons/Check";
import RoomIcon from "@material-ui/icons/Room";
import LocalShipping from "@material-ui/icons/LocalShipping";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import FaceIcon from "@material-ui/icons/Face";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import { DeleteTrashIcon } from "../../../../hdm/components/svg-icons";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import bopisSvg from "../../../../assets/homedepot/images/bopis.svg";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { useSelector } from "react-redux";
import { addressDetailsSelector } from "../../../../redux/selectors/account";
import { useSite } from "../../../../_foundation/hooks/useSite";

const StyledRadioCardContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme, ..._props }) => `
    width: 100%;
    display:flex;
    height: 42px;
    align-items: center;

    &.container{
      outline: 1px solid ${_props.disabled === true ? theme.palette.gray["200"] : theme.palette.secondary.light};
      border-radius: 3px;
      background-color: ${_props.disabled === true ? theme.palette.gray["50"] : "unset"};

    }

    &.radioCard--grid-radioCardSelector{
      color: ${theme.palette.text.primary};
    }

    &.radioCard--grid-containerCart{
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 5px;
      &.storeChecked{
        outline: 1px solid ${theme.palette.primary.main};
      }
      &.radioCard--grid-CardSelector_Pro {
        padding-left: ${theme.spacing(2)}px;
      }
    }
    &.radioCard--grid-containerCart_Pro {
      padding: 10px;
      background-color: #FFFFFF;;
      &.radioCard--grid-CardSelectorError {
        padding-right: ${theme.spacing(0)}px;
      }

      .radioCard--styledGrid-flexIcon {
        opacity: 50%;
      }
    }

    &.radioCard--grid-containerCartWarning{
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 5px;
      &.storeChecked{
        outline: 1px solid ${theme.palette.warning.main};
      }
    }

    &.radioCard--grid-CardSelector{
      outline: 1px solid ${theme.palette.gray["100"]};
      color: ${theme.palette.gray["100"]};
    }

    &.radioCard--grid-CardSelectorError{
      outline: 1px solid ${theme.palette.error.main};
    }

    .radioCard--styledTypography-CardSelector{
      color: ${theme.palette.gray["500"]};
    }

    .radioCard--styledGrid-flexIcon{
      display: flex;
      margin-left: ${theme.spacing(1)}px;
    }

    .radioCard--svg-bopisUnchecked{
      width: 20px;
      margin-right: ${theme.spacing(1)}px;
      filter: invert(44%) sepia(13%) saturate(1866%) hue-rotate(159deg) brightness(90%) contrast(83%);
    }

    .radioCard--svg-bopisChecked{
      width: 20px;
      margin-right: ${theme.spacing(1)}px;
      filter: invert(39%) sepia(73%) saturate(2459%) hue-rotate(3deg) brightness(104%) contrast(98%);
    }

    .radioCard--svg-bopisCheckedWarning{
      width: 20px;
      margin-right: ${theme.spacing(1)}px;
    }

    .radioCard--icon-successDeliveryIcon{
      font-size: 20px;
    }

    .radioCard--icon-PriorityHighIcon {
      font-size: 18px;
    }

    .deliveryCheckedSuccess{
      color: ${theme.palette.primary.main};
    }

    &:hover {
      outline: 1px solid ${theme.palette.primary.main};
      cursor: pointer;
    }

    &.storeChecked{
      outline: 1px solid ${theme.palette.success.main};
    }

    .successIconRadio{
      justify-content: flex-end;
    }

    .radioCard--icon-delivery{
      font-size: 16px;
      margin: 5px;
    }
    .radioCard--typography-checkedText{
      color: black;
    }

    .shippingCheckoutInstallment--styledTypography-primary{
      margin-left: ${theme.spacing(2)}px;
    }

    .shippingCheckout--styledTypography-primary{
      margin-left: ${theme.spacing(2)}px;
      color: ${theme.palette.gray["500"]};
    }

    .radioCard-img-iconRadio{
      display: flex;
      align-items: center;
      margin-right: ${theme.spacing(1)}px;
    }

    .radioCard-img-iconRadio svg,
    .radioCard-img-iconRadio img {
      margin-right: ${theme.spacing(1)}px;
    }

    .radioCard-img-iconRadioColor img {
      color: ${theme.palette.gray["500"]};
    }

    .radioCard-img-installmentChecked{
      color: ${theme.palette.primary.main};
    }
    .radioCard-img-installmentUnChecked{
      color: ${_props.disabled === true ? theme.palette.gray["200"] : theme.palette.secondary.main};
    }

    .radioCard--lable-installment {
      color: ${_props.disabled === true ? theme.palette.gray["200"] : theme.palette.secondary.main};
    }

    .styledRadioCard--container-successCardSelector{
      display: flex;
      justify-content: flex-end;
      background-color: #FFFFFF;
    }

    .styledRadioCard--icon-trashcanContainer{
      cursor: pointer;
      z-index: 1000;

      svg {
        color: ${theme.palette.gray["300"]};
      }
    }

    .storeSelector--styledTypography-secondaryPalette{
      color: ${theme.palette.secondary.main};
    }
  `}
`;
const RadioCard = styled(({ ...props }) => <StyledRadio {...props} />)`
  ${({ theme, ...props }) => `
    text-align: left;
    width: 100%;
    ${props?.noMarginTop ? "" : ` margin-top: ${theme.spacing(2)}px;`};
    ${props?.noMarginBottom ? "" : `margin-bottom: ${theme.spacing(2)}px;`};

    height: 42px;
    line-height: 30px;
    padding: 0 !important;
    :hover{
      background: unset !important;
      outline: 1px solid ${theme.palette.primary.main};
      border-radius: 3px;
    }
    &.Mui-checked{
      :hover{
        background: unset !important;
        outline: none;
        border-radius: 3px;
        cursor:default;
      }
    }
    
  `}
`;
const SuccessRoomIcon = styled(({ ...props }) => <RoomIcon {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.success.main} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
  `}
`;

const CheckedRadioIcon = styled(({ ...props }) => <RadioButtonCheckedIcon {...props} />)`
  ${({ theme }) => `
      color: ${theme.palette.success.main} !important;
      margin-right: ${theme.spacing(2)}px;
      margin-left: ${theme.spacing(1)}px;
    `}
`;
const UncheckedRadioIcon = styled(({ ...props }) => <RadioButtonUncheckedIcon {...props} />)`
  ${({ theme }) => `
    color: ${palette.gray["100"]} !important;
    margin-right: ${theme.spacing(2)}px;
    margin-left: ${theme.spacing(1)}px;
    &.unCheckedRadioPro {
      color: ${palette.gray["100"]} !important;
      margin-right: ${theme.spacing(2)}px;
      margin-left: 0px;
    }
`}
`;
const SuccessIconRadio = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  background-color: ${theme.palette.success.main};
  width: 26px;
  padding-top: ${theme.spacing(1)}px;
  height: 42px;
  display: inline-block;
  color: ${theme.palette.text.expandedMenu};
  text-align: center;
`}
`;
const SuccessIconRadioCart = styled(({ ...props }) => <span {...props} />)`
  ${({ theme, ..._props }) => `
  background-color: ${_props?.type === "warning" ? theme.palette.warning.main : theme.palette.primary.main};
  width: ${theme.spacing(5)+1}px;
  padding-top: ${theme.spacing(1)}px;
  height: 42px;
  display: inline-block;
  color: ${theme.palette.text.expandedMenu};
  text-align: center;
`}
`;
const ErrorIconRadioCart = styled(({ ...props }) => <span {...props} />)`
  ${({ theme }) => `
  background-color: ${theme.palette.error.main};
  width: ${theme.spacing(5)+1}px;
  padding-top: ${theme.spacing(1)}px;
  height: 42px;
  display: inline-block;
  color: ${theme.palette.text.expandedMenu};
  text-align: center;
`}
`;

const IconStyledRadioCard = ({type}) => {
  return type === "warning" ? (
    <PriorityHighIcon
      style={{ color: palette.text.expandedMenu }}
      className="radioCard--icon-PriorityHighIcon"
    />
  ) : (
    <CheckIcon
      style={{ color: palette.text.expandedMenu }}
      className="radioCard--icon-successDeliveryIcon"
    />
  )
}

const StyledRadioCard = (props) => {
  const checked = props.checked;
  const variant = props?.variant;
  const { t } = useTranslation();
  const icon = props.icon ? props.icon : null;
  const deleteCard = props.deleteCard ? props.deleteCard : null;
  const expiredCard = props.expiredCard;
  const type = props?.type ? props.type : "";
  const idComponent = props.idComponent ? props.idComponent : null;
  const addressDetails = useSelector(addressDetailsSelector);
  const logonId = addressDetails?.logonId ? addressDetails?.logonId : "";
  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const UnCheckedIconStyled = () => {
    if (
      variant === "delivery" ||
      variant === "deliveryFull" ||
      variant === "proLeadNaturalPerson" ||
      variant === "proLeadLegalPerson"
    ) {
      return (
        <StyledRadioCardContainer className={"container radioCard--grid-containerCart pro-lead-radio-container"}>
          {variant === "proLeadNaturalPerson" ? (
            <FaceIcon className="radioCard--icon-delivery" color="secondary" />
          ) : variant === "proLeadLegalPerson" ? (
            <SupervisedUserCircleIcon className="radioCard--icon-delivery" color="secondary" />
          ) : (
            <LocalShipping className="radioCard--icon-delivery" color="secondary" />
          )}
          <StyledTypography variant="bodySubText" color="secondary">
            {t(
              `${
                variant === "proLeadNaturalPerson"
                  ? "ProLeadForm.PersonTypes.NaturalPerson"
                  : variant === "proLeadLegalPerson"
                  ? "ProLeadForm.PersonTypes.LegalPerson"
                  : "DeliveryMethod.Send"
              }`
            )}
            {variant === "deliveryFull" ? (
              <>
                <b>{" " + t("DeliveryMethod.All")}</b>
                <br />
              </>
            ) : (
              <> </>
            )}
            {variant === "proLeadNaturalPerson" || variant === "proLeadLegalPerson" ? (
              <>
                <br />
              </>
            ) : null}
            <StyledTypography
              component="span"
              className="pro-lead-radiobutton-subtitle"
              weight={variant === "proLeadNaturalPerson" || variant === "proLeadLegalPerson" ? "semibold" : ""}>
              {t(
                `${
                  variant === "proLeadNaturalPerson"
                    ? "ProLeadForm.PersonTypes.NaturalType"
                    : variant === "proLeadLegalPerson"
                    ? "ProLeadForm.PersonTypes.LegalType"
                    : "DeliveryMethod.Delivery"
                }`
              )}
            </StyledTypography>
          </StyledTypography>
        </StyledRadioCardContainer>
      );
    }
    if (variant === "bopis" || variant === "bopisFull") {
      return (
        <StyledRadioCardContainer className={"container radioCard--grid-containerCart pro-lead-radio-container"}>
          <img className={"radioCard--svg-bopisUnchecked"} src={bopisSvg}></img>
          <StyledTypography variant="bodySubText" color="secondary">
            {t("DeliveryMethod.Pick")}
            {variant === "bopisFull" ? (
              <>
                <b>{" " + t("DeliveryMethod.All")}</b>
                <br />
              </>
            ) : (
              <></>
            )}{" "}
            {t("DeliveryMethod.Bopis")}
          </StyledTypography>
        </StyledRadioCardContainer>
      );
    }
    if (variant === "checkoutAddress") {
      return (
        <StyledRadioCardContainer
          id={idComponent}
          className={isB2B ? "container radioCard--grid-containerCart_Pro" : "container radioCard--grid-containerCart"}
          disabled={props.disabled}>
          <UncheckedRadioIcon className={isB2B ? "unCheckedRadioPro" : ""} fontSize={"small"} />
          {icon ? (
            <div className={`${isB2B ? "radioCard-img-iconRadioColor" : ""} radioCard-img-iconRadio`}>
                {icon}
              <StyledTypography variant="bodySubText" color="secondary">
                {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
              </StyledTypography>
            </div>
          ) : props.nickName ? (
            <StyledTypography variant="bodySubText" color="secondary">
              {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
            </StyledTypography>
          ) : (
            ""
          )}
        </StyledRadioCardContainer>
      );
    }
    if (variant === "cardSelector") {
      return (
        <StyledRadioCardContainer
          className={
            "container radioCard--grid-containerCart radioCard--grid-CardSelector " +
            (expiredCard ? "radioCard--grid-CardSelectorError" : "") +
            (isB2B ? " radioCard--grid-containerCart_Pro" : "")
          }>
          <StyledGrid className={"radioCard--styledGrid-flexIcon"}>{icon}</StyledGrid>
          <StyledRadioCardContainer item xs={8}>
            <StyledTypography variant="bodySubText" className={"radioCard--styledTypography-CardSelector"}>
              {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledGrid item xs={4} className={"styledRadioCard--container-successCardSelector"}>
            {expiredCard && (
              <>
                <StyledBox className="styledRadioCard--icon-trashcanContainer" onClick={deleteCard}>
                  <DeleteTrashIcon />
                </StyledBox>
                <ErrorIconRadioCart>
                  <PriorityHighIcon
                    style={{ color: palette.text.expandedMenu }}
                    className="radioCard--icon-PriorityHighIcon"
                  />
                </ErrorIconRadioCart>
              </>
            )}
          </StyledGrid>
        </StyledRadioCardContainer>
      );
    }
    if (variant === "installment") {
      return (
        <StyledRadioCardContainer className={"container radioCard--grid-containerCart "} disabled={props.disabled}>
          <StyledGrid className={"left-margin-1 right-margin-2 radioCard-img-installmentUnChecked"}>{icon}</StyledGrid>
          <StyledRadioCardContainer item xs={8} md={10}>
            <StyledTypography
              className="radioCard--lable-installment"
              variant="bodySubText"
              color="secondary"
              component="label">
              {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledRadioCardContainer item xs={4} md={2}></StyledRadioCardContainer>
        </StyledRadioCardContainer>
      );
    }
    return (
      <StyledRadioCardContainer className={"container"} disabled={props.disabled}>
        <UncheckedRadioIcon fontSize={"small"} />
        <StyledTypography variant="bodySubText"  
           className={props.disabled ? "disabled" : "storeSelector--styledTypography-secondaryPalette"}>
          {variant === "checkout-profile" ? t("Cart.SelectProfileTextRadio") : t("storeLocator.chooseAStore")}
        </StyledTypography>
      </StyledRadioCardContainer>
    );
  };

  const CheckedIconStyled = () => {
    if (
      variant === "delivery" ||
      variant === "deliveryFull" ||
      variant === "proLeadNaturalPerson" ||
      variant === "proLeadLegalPerson"
    ) {
      return (
        <StyledRadioCardContainer
          className={
            type === "warning"
              ? "container radioCard--grid-containerCartWarning storeChecked"
              : "container radioCard--grid-containerCart storeChecked"
          }>
          <StyledRadioCardContainer item xs={9}>
            {variant === "proLeadNaturalPerson" ? (
              <FaceIcon
                color={type === "warning" ? undefined : "primary"}
                className={"radioCard--icon-delivery" + (type === "warning" ? " color-gray500" : "")}
              />
            ) : variant === "proLeadLegalPerson" ? (
              <SupervisedUserCircleIcon
                color={type === "warning" ? undefined : "primary"}
                className={"radioCard--icon-delivery" + (type === "warning" ? " color-gray500" : "")}
              />
            ) : (
              <LocalShipping
                color={type === "warning" ? undefined : "primary"}
                className={"radioCard--icon-delivery" + (type === "warning" ? " color-gray500" : "")}
              />
            )}
            {/* <LocalShipping color={type === "warning" ? undefined :  "primary"} className={"radioCard--icon-delivery" + (type === "warning" ? " color-gray500" : "")}/> */}
            <StyledTypography variant="bodySubText" className="radioCard--typography-checkedText">
              {t(
                `${
                  variant === "proLeadNaturalPerson"
                    ? "ProLeadForm.PersonTypes.NaturalPerson"
                    : variant === "proLeadLegalPerson"
                    ? "ProLeadForm.PersonTypes.LegalPerson"
                    : "DeliveryMethod.Send"
                }`
              )}
              {/* {t("DeliveryMethod.Send")} */}
              {variant === "deliveryFull" ? (
                <>
                  <b>{" " + t("DeliveryMethod.All")}</b>
                  <br />
                </>
              ) : (
                <></>
              )}{" "}
              {variant === "proLeadNaturalPerson" || variant === "proLeadLegalPerson" ? (
                <>
                  <br />
                </>
              ) : null}
              <StyledTypography
                component="span"
                className="pro-lead-radiobutton-subtitle"
                // weight="semibold"
                weight={variant === "proLeadNaturalPerson" || variant === "proLeadLegalPerson" ? "semibold" : ""}>
                {t(
                  `${
                    variant === "proLeadNaturalPerson"
                      ? "ProLeadForm.PersonTypes.NaturalType"
                      : variant === "proLeadLegalPerson"
                      ? "ProLeadForm.PersonTypes.LegalType"
                      : "DeliveryMethod.Delivery"
                  }`
                )}
              </StyledTypography>
              {/* {t("DeliveryMethod.Delivery")} */}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledRadioCardContainer item xs={3} className={"successIconRadio"}>
            <SuccessIconRadioCart type={type}>
              <IconStyledRadioCard type={type} />
            </SuccessIconRadioCart>
          </StyledRadioCardContainer>
        </StyledRadioCardContainer>
      );
    }
    if (variant === "bopis" || variant === "bopisFull") {
      return (
        <StyledRadioCardContainer
          className={
            type === "warning"
              ? "container radioCard--grid-containerCartWarning storeChecked"
              : "container radioCard--grid-containerCart storeChecked"
          }>
          <StyledRadioCardContainer item xs={9}>
            <img
              className={
                type === "warning"
                  ? "radioCard--svg-bopisCheckedWarning lazyload"
                  : "radioCard--svg-bopisChecked lazyload"
              }
              src={bopisSvg}></img>
            <StyledTypography variant="bodySubText" className="radioCard--typography-checkedText">
              {t("DeliveryMethod.Pick")}
              {variant === "bopisFull" ? (
                <>
                  <b>{" " + t("DeliveryMethod.All")}</b>
                  <br />
                </>
              ) : (
                <></>
              )}{" "}
              {t("DeliveryMethod.Bopis")}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledRadioCardContainer item xs={3} className={"successIconRadio"}>
            <SuccessIconRadioCart type={type}>
              <IconStyledRadioCard type={type} />
            </SuccessIconRadioCart>
          </StyledRadioCardContainer>
        </StyledRadioCardContainer>
      );
    }
    if (variant === "checkoutAddress") {
      return (
        <StyledRadioCardContainer className={"container storeChecked"} disabled={props.disabled}>
          <StyledRadioCardContainer item xs={8}>
            <StyledTypography
              variant="bodySubText"
              className={"storeSelector--styledTypography-primary shippingCheckout--styledTypography-primary"}>
              {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledRadioCardContainer item xs={4} className={"successIconRadio"}>
            <SuccessIconRadio>
              <CheckIcon style={{ color: palette.text.expandedMenu, fontSize: 20 }}  />
            </SuccessIconRadio>
          </StyledRadioCardContainer>
        </StyledRadioCardContainer>
      );
    }
    {
      /* <StyledRadioCardContainer className={"container radioCard--grid-containerCart radioCard--grid-CardSelector"}>
          <StyledGrid className={"left-margin-1 right-margin-2"}>
          {icon}
          </StyledGrid>
          <StyledTypography variant="bodySubText">{props.nickName}</StyledTypography>
        </StyledRadioCardContainer>*/
    }
    if (variant === "cardSelector") {
      return (
        <StyledRadioCardContainer
          className={`container radioCard--grid-containerCart storeChecked radioCard--grid-radioCardSelector 
          ${isB2B ? "radioCard--grid-CardSelector_Pro":""}`}>
          <StyledGrid className={"radioCard--styledGrid-flexIcon"}>{icon}</StyledGrid>
          <StyledRadioCardContainer item xs={isB2B ? 9 : 8}>
            <StyledTypography variant="bodySubText" className={"radioCard--styledTypography-CardSelector"}>
              {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledGrid item xs={isB2B ? 3 : 4} className={"styledRadioCard--container-successCardSelector"}>
            {deleteCard && (
              <StyledBox className="styledRadioCard--icon-trashcanContainer" onClick={deleteCard}>
                <DeleteTrashIcon />
              </StyledBox>
            )}
            <SuccessIconRadioCart>
              <CheckIcon style={{ color: palette.text.expandedMenu }} className="radioCard--icon-successDeliveryIcon" />
            </SuccessIconRadioCart>
          </StyledGrid>
        </StyledRadioCardContainer>
      );
    }

    if (variant === "installment") {
      return (
        <StyledRadioCardContainer
          className={
            "container radioCard--grid-containerCart storeChecked radioCard--grid-radioCardSelector radioCard--grid-containerCart"
          }>
          <StyledGrid className={"left-margin-1 radioCard-img-installmentChecked"}>{icon}</StyledGrid>
          <StyledRadioCardContainer item xs={8}>
            <StyledTypography
              variant="bodySubText"
              className={"shippingCheckoutInstallment--styledTypography-primary"}
              component="label">
              {props.nickName === logonId ? t("Account.Labels.MainAddress") : props.nickName ? props.nickName : ""}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledRadioCardContainer item xs={4} className={"successIconRadio"}>
            <SuccessIconRadioCart>
              <CheckIcon style={{ color: palette.text.expandedMenu }} className="radioCard--icon-successDeliveryIcon" />
            </SuccessIconRadioCart>
          </StyledRadioCardContainer>
        </StyledRadioCardContainer>
      );
    }

    if (variant === "checkout-profile") {
      return (
        <StyledRadioCardContainer className={"container storeChecked"}>
          <StyledRadioCardContainer item xs={8}>
            <CheckedRadioIcon fontSize={"small"} />
            <StyledTypography variant="bodySubText" className={"storeSelector--styledTypography-primary"}>
              {t("Cart.SelectProfileTextRadioSelected")}
            </StyledTypography>
          </StyledRadioCardContainer>
          <StyledRadioCardContainer item xs={4} className={"successIconRadio"}>
            <SuccessIconRadio>
              <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
            </SuccessIconRadio>
          </StyledRadioCardContainer>
        </StyledRadioCardContainer>
      );
    }

    return (
      <StyledRadioCardContainer className={"container storeChecked"}>
        <StyledRadioCardContainer item xs={8}>
          <SuccessRoomIcon fontSize={"small"} />
          <StyledTypography variant="bodySubText" className={"storeSelector--styledTypography-primary"}>
            {t("storeLocator.choosedStore")}
          </StyledTypography>
        </StyledRadioCardContainer>
        <StyledRadioCardContainer item xs={4} className={"successIconRadio"}>
          <SuccessIconRadio>
            <CheckIcon style={{ color: palette.text.expandedMenu }} fontSize={"small"} />
          </SuccessIconRadio>
        </StyledRadioCardContainer>
      </StyledRadioCardContainer>
    );
  };
  return (
    <StyledGrid>
      <RadioCard
        checked={checked}
        disableripple="true"
        disabled={props.disabled}
        value={props.value}
        noMarginBottom={props.noMarginBottom}
        noMarginTop={props.noMarginTop}
        checkedIcon={<CheckedIconStyled />}
        icon={<UnCheckedIconStyled />}
        onClick={props.onClick ? props.onClick : null}
      />
    </StyledGrid>
  );
};

export { StyledRadioCard };
