import { breadcrumbsSelector } from "../../../redux/selectors/catalog";
import styled from "styled-components";
import {
  StyledBox,
  StyledButton,
  StyledCardEmpty,
  StyledContainer,
  StyledGrid,
  StyledTypography,
} from "../../elements";
import BlockSharpIcon from "@material-ui/icons/BlockSharp";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { CATALOG_PREFIX, SEARCH } from "../../../constants/routes";
import { setNativeInterface } from "../../functions/utils";
import { useEffect } from "react";
import { SET_PRODUCT_NOT_AVAILABLE_ACTION } from "../../../redux/actions/productNotAvailable";

export const ContainerStyledProductNoAvailable = styled(({ ...props }) => <StyledBox {...props} />)`
  ${({ theme, color, subCategory }) => `

margin-bottom: 10px;
${!subCategory ? "margin-top: 15px;" : ""}
${theme.breakpoints.down("sm")} {
    margin-left: 10px;
    margin-right: 10px;
}

.styled--icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    ${theme.breakpoints.down("sm")} {
        width: 100%;
        padding: 10px;
    }
}

.styled-info-container {
    width: 60%;
    ${theme.breakpoints.down("sm")} {
        width: 100%;
        padding: 10px;
        text-align: center;
    }
}

.styled--icon-shadown {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: ${theme.palette.grey["50"]};
    display: flex;
    align-items: center;
    justify-content: center;
}

.styled--blocksharp-icon {
    width: 60px;
    height: 60px;

    &.MuiSvgIcon-root {
        fill: ${theme.palette.background.paper};
        stroke: ${theme.palette.grey["500"]};
        stroke-width: 1px;
    }
}

.styled--blocksharp-icon > path {
    stroke-width: 1px;
}

.styled--showproduct-buuton {
    max-width: 350px;
    ${theme.breakpoints.down("sm")} {
        max-width: 100%;
    }
}

`}
`;

interface Props {
  breadcrumbsValue: any;
}

const isMobileApp = window.navigator.userAgent.includes("THDMXAPP");

const ProductNoAvailable = (props: Props) => {
  const { breadcrumbsValue } = props;

  const dispatch = useDispatch();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let href = "/";
  let subCategory = "";

  const handleSearchSubcategoryProducts = (hasCategories) => {
    
    if(hasCategories){
      if(isMobileApp){
        setNativeInterface(`${CATALOG_PREFIX}${href}`);
      }else {
      navigate(`${CATALOG_PREFIX}${href}`);
      }
    }else {
      const [search] = location.pathname.split("/p/")[1].split("-")
      if(isMobileApp){
        setNativeInterface(`${SEARCH}/${search}`);
      }else {
      navigate(`${SEARCH}/${search}`);
      }
    }

  };

  if (location?.state && location?.state?.breadcrumbs) {
    location.state.breadcrumbs.map((breadCrum) => {
      if (breadCrum?.type_ === "FACET_ENTRY_CATEGORY") {
        href = breadCrum?.seo?.href;
        subCategory = breadCrum?.label;
      }
    });
  }

  if (breadcrumbsValue && breadcrumbsValue?.length !== 0) {
    breadcrumbsValue.map((breadCrum) => {
      if (breadCrum?.type_ === "FACET_ENTRY_CATEGORY") {
        href = breadCrum?.seo?.href;
        subCategory = breadCrum?.label;
      }
    });
  }

  let hasCategories = true
  if(breadcrumbsValue?.length === 0){
    hasCategories = false
  }

  useEffect(() => {
    dispatch(
      SET_PRODUCT_NOT_AVAILABLE_ACTION(true)
    );
  }, []);

  return (
    <StyledContainer>
      <ContainerStyledProductNoAvailable subCategory={!!subCategory}>
        <StyledCardEmpty>
          <StyledGrid container>
            <StyledGrid item className="styled--icon-container">
              <StyledBox className="styled--icon-shadown">
                <BlockSharpIcon className="styled--blocksharp-icon" />
              </StyledBox>
            </StyledGrid>

            <StyledGrid item className="styled-info-container">
              <StyledTypography variant="headingHeadline" className="vertical-padding-2">
                {t("productNoAvailable.noAvailable")}
              </StyledTypography>
              <StyledTypography variant="bodyBaseline" className="vertical-padding-2 color-gray300">
                {t("productNoAvailable.weAreSorry")}
              </StyledTypography>
              <StyledTypography variant="bodyBaseline" className="vertical-padding-2 color-gray300" component="span">
                {hasCategories ?
                t("productNoAvailable.findSimilarCategoriesProducts")
                :
                t("productNoAvailable.findRelatedProducts")
                }
              </StyledTypography>
              <StyledTypography
                variant="bodyBaseline"
                fontWeight={600}
                className="left-padding-1 vertical-padding-2 color-gray500"
                component="span">
                {subCategory}
              </StyledTypography>
              <StyledBox className="top-margin-2">
                <StyledButton
                  variant="outlined"
                  className="styled--showproduct-buuton"
                  onClick={() => handleSearchSubcategoryProducts(hasCategories)}>
                    {hasCategories ?
                    t("productNoAvailable.showProducts")
                    :
                    t("productNoAvailable.showProductsRelated")
                    }
                </StyledButton>
              </StyledBox>
            </StyledGrid>
          </StyledGrid>
        </StyledCardEmpty>
      </ContainerStyledProductNoAvailable>
    </StyledContainer>
  );
};

export default ProductNoAvailable;
