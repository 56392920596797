import { useTranslation } from "react-i18next";
import { StyledGrid, StyledTypography } from "../../../hdm";
import FormattedPriceDisplay from "../formatted-price-display";

const TotalDiscounts = ({totalDiscounts, order}) => {
    const { t } = useTranslation();
    
    return (
        <>
            {totalDiscounts !== null && totalDiscounts !== 0 && (
                <>
                  <StyledGrid item xs={6} className={"summary--grid-marginDiscounts"}>
                    <StyledTypography
                      className="total-discount orderSummary--styledTypography-highlight"
                      variant="bodyBaseline">
                      {t("OrderTotalSummary.Labels.Discount")}
                    </StyledTypography>
                  </StyledGrid>
                  <StyledGrid item xs={6} className={"summary--grid-marginDiscounts"}>
                    <StyledTypography
                      className="total-discount orderSummary--styledTypography-highlight"
                      variant="bodyBaseline"
                      align="right">
                      <FormattedPriceDisplay min={totalDiscounts * -1} currency={order.totalAdjustmentCurrency} />
                    </StyledTypography>
                  </StyledGrid>
                </>
              )}
        </>
    )
}

export {TotalDiscounts};