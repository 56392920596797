import axios from 'axios';

const BASE_URL = '/wcs/resources/store/10351';

export const zipCodeBlockListService = {
  getAllZipCodeBlockList: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/zipCodeBlockList/all`);
      return response.data.response.result.zipCodeBlockList;
    } catch (error) {
      console.error(`Error fetching zip code block list: ${error}`);
      return null; 
    }
  },
};