import styled from "styled-components";
import { StyledGrid } from "../..";

export const OrderConfirmationContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
  .orderConfirmation-styledGrid-container{
    ${theme.breakpoints.down("sm")}{
      padding-left: ${theme.spacing(2)}px;
      padding-right: ${theme.spacing(2)}px;
    }
  }
  .left-padding-0 {padding-left: ${theme.spacing(0)}px;}
  .orderConfirmation--svg-lightGray{
    font-size: 15px;
    margin-right: ${theme.spacing(1)}px;
    filter: invert(87%) sepia(18%) saturate(21%) hue-rotate(335deg) brightness(92%) contrast(89%);
  }
  .orderConfirmation--icon-size {
    width: 20px;
    height: 20px;
  }
  .orderConfirmation--icon-lightGray{
    font-size: 17px;
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.gray["100"]};
  }
  .orderConfirmation--icon-mediumGray{
    font-size: 17px;
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.gray["200"]};
  }
  .orderConfirmation--mailIcon {
    width: 20px;
    margin-right: ${theme.spacing(1)}px;
  }
  .orderConfirmation--timerOutlinedIcon {
    width: 18px;
    height: 18px;
    margin-right: ${theme.spacing(1)}px;
  }
  .orderConfirmation--icon-darkGray{
    font-size: 15px;
    margin-top: 2px;
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.gray["300"]};
    &.no-margin {
      margin-right: ${theme.spacing(0)}px;
    }
  }
  .orderConfirmation--icon-general{
    font-size: 15px;
    margin-right: ${theme.spacing(1)}px;
    margin-top: 2px;
  }
  .flexRow {
    display: flex;
    flex-direction: row;
  }
  .flexColumn {
    display: flex;
    flex-direction: column;
  }
  .flexAlignItems {
    display: flex;
    align-items: center;
  }
  .orderConfirmation--styledGrid-contactInfoContainer{
    display: flex;
    align-items: start;
    margin-top: ${theme.spacing(1)}px;
    
    .orderConfirmation-eta{
      white-space: inherit;
    }

    &-B2B {
      margin-top: ${theme.spacing(2)}px;
    }

  }
  .orderConfirmation--icon-chevronLeft{
    margin-left: -${theme.spacing(2)}px;
  }
  .orderConfirmation--styledGrid-referenceContainer{
    border: 1px solid ${theme.palette.gray["500"]};
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;
    margin-top: ${theme.spacing(1)}px;

    letter-spacing: ${theme.spacing(3)}px;
    
    ${theme.breakpoints.up("xs")}{
      letter-spacing: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.up("sm")}{
      letter-spacing: ${theme.spacing(2)}px;
    }
    ${theme.breakpoints.up("md")}{
      letter-spacing: ${theme.spacing(2)}px;
    }
    
    text-align: center;
  }
  .orderConfirmation--styledGrid-reference {
    width: calc(50% - 5px);
  }
  .orderConfirmation--styledGrid-referenceFont{
    font-size: 14px;
    margin-right: -${theme.spacing(3)}px;
  }
  .orderConfirmation--styledGrid-amount{
    text-align: right;
  }

  .orderConfirmation--StyledTypography-darkGray{
    color: ${theme.palette.gray["300"]};
  }

  .orderConfirmation--StyledTypography-dark {
    color: ${theme.palette.gray["500"]};
  }

  .orderConfirmation--icon-highLight{
    color: ${theme.palette.text.highlight};
  }

  .orderConfirmation--icon-swapHoriz{
    width: 20px;
  }

  .orderConfirmation--StyledGrid-container {
    width: calc(50% - 10px);
  }

  .orderConfirmation--StyledGrid-refenceContainer{
    display: flex;
    align-items: center;
  }

  .orderConfirmation--styledTypography-upperCase{
    text-transform: uppercase;
  }

  .orderConfirmation--styledTypography-noneCase{
    text-transform: none;
  }

  .styledBox--homeDeliveryAddres-container {
    display: flex;
    align-items: start;

    .orderConfirmation--icon-darkGray {
      margin-right: ${theme.spacing(0)}px;
    }
    .styledBox--homeDeliveryAddres-Location {
      margin-left: ${theme.spacing(1)}px;
    } 
  }
  .shopnow-button{
    margin-top: ${theme.spacing(2)}px;

    ${theme.breakpoints.down("sm")} {
      margin: ${theme.spacing(2)}px ${theme.spacing(2)}px;
      max-width: -webkit-fill-available;
    }
  }

  .no-white-spaces{
    white-space: normal;
  }

  .divider-gray50 {background: ${theme.palette.gray["50"]};}
  .divider-gray100 {background: ${theme.palette.gray["100"]};}
  .h-15 {height: 15px;}
`}
`;
export const DownloableTYPContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    margin: 0 0 ${theme.spacing(2)}px 0;
    background-color: #ffffff;
    padding: ${theme.spacing(2)}px;
    max-width: 670px;
    min-width: 670px;
    min-height: 680px;
    align-content: flex-start;
    opacity: 0;

    &.hidePdf {
      display: none;
    }

    &.showPdf {
      opacity: 1;
      position: absolute;
      right: -1000px;
    }

    .divider{
      margin-top: ${theme.spacing(1)}px;
      border: solid 1px ${theme.palette.gray["100"]};
    }

    .left-divider{
      padding-left: 10px;
      border-left: solid 1px ${theme.palette.gray["100"]};
      padding-top: 10px;
    }

    .downloadble-typ-logo {
      height: 90px;
      width: 90px;
    }

    .downloadble-typ-title{
      color: ${theme.palette.primary.main};
      padding-top: ${theme.spacing(2)}px;
    }

    .downloadble-typ-order-number{
      margin-top: ${theme.spacing(2)}px;
    }

    .downloadble-typ-org-info span {
      color: ${theme.palette.primary.main};
    }

    .download-typ-order-date{
      margin-top: 3px;
    }

    .downloadble-typ-greetings{
      margin-top: ${theme.spacing(2)}px;
    }

    .greetings-body{
      line-height: 1.5;
      & span{
        color: ${theme.palette.primary.main};
        font-weight: 600;
      }
    }
    
    .paypal-greetings-subbody{
      margin: ${theme.spacing(4)}px 0px;
    }

    .downloadble-typ-instructions{
      margin: ${theme.spacing(5)}px 0;
      display: flex;
        & p{
          margin-left: 5px;
        }

        & span{
          color: ${theme.palette.primary.main};
          font-weight: bold;
        }

      & .downloadble-typ-instructions-numbers{
        background-color: ${theme.palette.primary.main};
        padding: 0px 5px 2px 5px;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        margin-right: 5px;

        display: inline-flex;    
        align-items: center;

        text-align: center;
      }
    }

    .download-typ-reference-instructions{
      margin: ${theme.spacing(4)}px 0;
      border: solid 1px ${theme.palette.primary.main};

      & .MuiGrid-grid-xs-1 {
        flex-basis: 4.333333%;
      }

      & p{
        padding: 3px 5px 3px 9px;
      }

      .download-typ-instructions-sign{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.palette.primary.main};
        color: white;
        font-weight: bold;
        min-width: 20px;
        height: 45px;
        font-size: 16px;
        margin: -1px 0px 0px -1px;
      }

    }

    .download-typ-references{
      margin-top: ${theme.spacing(2)}px;

        .oxxopay-doublereferences-icon{
          height: 14px;
          margin-left: ${theme.spacing(1)}px;
          margin-bottom: -2px;
        }

        .visa-references{
          margin-bottom: ${theme.spacing(6)}px !important;
        }

        .order-data{
          margin-top: ${theme.spacing(1)}px;
          margin-bottom: ${theme.spacing(1)}px;
          display: flex;
          flex-direction: column;

          & p{
            margin-top: ${theme.spacing(1)}px;
          }

          & span{
            margin-left:5px;
            color: ${theme.palette.primary.main};
          }

          .debitcreditcard-subtitle{
            font-weight: bold;
          }

          .debitcreditcard-data-lastfourdigits{
            & span{
              color: black;
            }
          }

          .payinstore-references{
            line-height: 1.5;
          }

          .paypal-authnumber{
            & span{
              color: black;
              font-weight: bold;
            }
          }

          .oxxopay-two-references-container{
            flex-direction: column;
            & p:first-child{
              font-weight: bold;
            }

            .oxxopay-barcode-container{
              display: flex;
              justify-content: center;
            }
          }

          .paypal-transaction-date{
            & span{
              color: black;
              font-weight: bold;
            }
          }
        }
        
        .oxxo-barcode{
          justify-content: center;
          & img{
            height: 80px;
          }
        }

        .oxxo-reference{
          padding-top: ${theme.spacing(2)}px;
          & img{
            width: 50px;
            height: auto;
          }

          .reference-header{
            display: flex;
            justify-content: space-between;

            & span{
              margin-left: ${theme.spacing(1)}px;
            }
          }

          .reference-box{
            border: solid 1px ${theme.palette.gray["100"]};
            letter-spacing: 8px;
            text-align: end;
            padding: ${theme.spacing(2)}px ${theme.spacing(1)}px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .oxxopay-two-references-container2{
            flex-direction: column;

            & p:first-child{
              color: #ffffff;
            }

            & p:nth-child(n+2){
              margin-top: ${theme.spacing(1)}px;
            }

            & span{
              margin-left: ${theme.spacing(1)}px;
              color: ${theme.palette.primary.main};
            }

            .oxxopay-barcode-container{
              display: flex;
              justify-content: center;
            }
          }
        }

        .debitcreditcard-reference{
          .debitcreditcard-storeinfo-container{
            margin-bottom: ${theme.spacing(2)}px;
            & p:first-child{
              font-weight: bold;
            }

            & p:nth-child(n+2){
              margin-top: ${theme.spacing(1)}px;
            }

            & span{
              margin-left: ${theme.spacing(1)}px;
              color: ${theme.palette.primary.main};
            }
          }
        }

        .openpay-helpsection-disclaimer{
          color: ${theme.palette.gray["300"]};
          line-height: 1.6;
          margin-top: ${theme.spacing(3)}px !important;
        }

        .openpay-reference{
          .openpay-right-section-references{
            & p:nth-child(n+2){
              margin-top: ${theme.spacing(1)}px;
            }

            & span{
              margin-left: ${theme.spacing(1)}px;
              color: ${theme.palette.primary.main};
            }
          }
        }
    }

    .shipping-data-container{
      .shippment-data-title{
        margin-bottom: ${theme.spacing(2)}px;
      }

      .shipping-icon{
        font-size: 35px;
      }

      .shipping-data-section{
        margin-bottom: ${theme.spacing(2)}px;

        & span{
            margin-left: ${theme.spacing(1)}px;
            color: ${theme.palette.primary.main};
        }
      }

      .section-one{
        margin-bottom: ${theme.spacing(4)}px;
        & p:nth-child(n+2){
            margin-top: ${theme.spacing(1)}px;
            color: ${theme.palette.gray["300"]};
        }
      }

      .section-two{
        & p:nth-child(n+2){
          margin-top: ${theme.spacing(1)}px;
      }
      }
    }

    .download-typ-order-resume{
      margin-top: ${theme.spacing(2)}px;

      & .download-typ-order-data{
        margin-top: ${theme.spacing(2)}px;
      }

      & .download-typ-order-data-checkin{
        margin-top: ${theme.spacing(2)}px;
        color: ${theme.palette.gray["300"]};
        & p{
          line-height: 1.6;
        }
      }

    }

    .order-resume-left-section{
      & p:nth-child(n+2){
        margin-top: ${theme.spacing(2)}px;
      }
    }

    .order-resume-right-section{
      & p:nth-child(n+2){
        margin-top: ${theme.spacing(2)}px;
      }
      & .order-resume-right-section-savings{
        color: ${theme.palette.success.main};
      }
      & .order-resume-right-section-grandtotal{
        color: ${theme.palette.primary.main};
      }
    }







    `}
`;
