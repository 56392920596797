import { Dispatch, Fragment, useEffect, useState } from "react";

import { StyledCardEmpty } from "../../elements/card";

import { Divider, FormControlLabel } from "@material-ui/core";

import { palette } from "../../themes/color-palette";

import StyledSwipeableDrawerHDM from "../../../components/custom-components/styled-drawer-hdm/StyledDrawerHDM";

import {
  StyledBox,
  StyledFormControl,
  StyledMenuItem,
  StyledNativeSelect,
  StyledRadio,
  StyledRadioGroup,
  StyledSelect,
  StyledTypography,
} from "../../elements";
import React from "react";
import { useNavigate } from "react-router";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { hdmDrawerColorDataRdc } from "../../../redux/selectors/hdmData";
import { FETCH_HDM_DATA_REQUESTED } from "../../../redux/action-types/hdmData";
import * as customInv from "../../../_foundation/utils/inventoryAvailability";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EspotHdm from "../../../components/custom-components/shared/EspotHdm";
import { StyledLinkButton } from "../../../components/custom-components/styled-store-selector/styled-link-button";
import { useTranslation } from "react-i18next";
import { StyledSelectHdm } from "../../../components/custom-components/styled-select/StyledSelectHdm";
import { useSite } from "../../../_foundation/hooks/useSite/useSite";
import Axios, { Canceler } from "axios";
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
import SuperSKU from "../../elements/product-card/Styled-product-supersku";
import inventoryavailabilityService from "../../../_foundation/apis/transaction/inventoryavailability.service";
import { BuyByColorDrawer } from "../pdp-comparar-color/BuyByColorDrawer";
import { MenuItemHDM } from "../../../components/header/categories-menu/MenuDesktop";
import { CLOSE_BACKDROP_LOADER_ACTION } from "../../../redux/actions/backdrop-loader";
import { SLPDPHelpForChoose, SLPDPSelectYourOptions } from "../../../components/custom-components/skeleton-loader/components/elements/PDP";

interface AttrValue {
  identifier: any;
  SKUs: Array<string>;
}
interface AttrSuperSKU {
  identifier: any;
  values: Map<string,AttrValue>;
}

interface Props {
  product: any;
  currentPartNumber: string;
  isTablet: boolean;
  currentMarket: any;
}
const SuperSkuContainer = (props: Props) => {
  const { product, currentPartNumber, isTablet, currentMarket } = props;

  const [superSKUAttributes, setSuperSKUAttributes] = useState<any>(null);
  const [itemsAvailability, setItemsAvailability] = useState<any>(null); //initial value null, for change value detection when availability request ends
  const [currentSKUAttributes, setCurrentSKUAttributes] = useState<any>(null);
  const [lastRadioButtonChanged, setLastRadioButtonChanged] = useState<any>(null);
  const [superSKUEspot, setSuperSKUEspot] = useState<any>(null);
  const [superSKUEspotComponent, setSuperSKUEspotComponent] = useState<any>(null);
  const [espotHasContent, setEspotHasContent] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const formRef: any = React.useRef(null);
  const buttonRef: any = React.useRef(null);
  const navigate = useNavigate();
  const hdmDrawerColorData = useSelector(hdmDrawerColorDataRdc);
  const { t } = useTranslation();
  const superSkuDrawerTitle = t("SuperSku");
  const [colorCode, setColorCode] = useState<any>(null);
  const [showSkeletonLoader, setShowSkeletonLoader] = useState<any>(false); //flag for showing the Skeleton Loader
  //Map of attribute values with exact match
  const [valuesWithExactMatch, setValuesWithExactMatch] = useState<{ [key: string]: boolean }>({}); //key -> AttrValueID: value -> If has existing exact match (if not it will be opaque)  

  const { mySite } = useSite();
  const isB2B = mySite?.isB2B;
  const DrawerEspotName = isB2B ? "PRO_PDP-SuperSKU_Help" : "PDP-SuperSKU_Help";
  const ATTR_STORE_BUYABLE_EXCLUDE = isB2B ? 'PRO_STORE_BUYABLE_EXCLUDE' : 'STORE_BUYABLE_EXCLUDE';
  const ATTR_MARKET_BUYABLE_EXCLUDE = isB2B ? 'PRO_MARKET_BUYABLE_EXCLUDE' : 'MARKET_BUYABLE_EXCLUDE';

  const dispatch = useDispatch<Dispatch<any>>();

  const storeID: string = mySite ? mySite.storeID : "";
  const catalogID: string = mySite ? mySite.catalogID : "";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const currentStoreData = useSelector(currentStoreSelector);
  const storeUniqueId = currentStoreData?.currentStore?.uniqueID;
  const physicalStoreIdentifier = currentStoreData?.currentStore?.stLocId; //Physical store identifier
  const currentMarketId = currentStoreData?.currentStore?.marketId; //Market Id
 
  const attributes = product?.attributes;
  const items = product?.items;
  const partNumber = product?.partNumber;

  useEffect(() => {
    if (hdmDrawerColorData?.changeBaseColor) {
      handleChangeBaseColor(hdmDrawerColorData?.changeBaseColor);
    }
  }, [hdmDrawerColorData]);

  /**
   * Extracts the SuperSKU attributes value that the current product has
   * @param items 
   */
  function extractCurrentSSKUValues(items) {
    const currentValues = {};
    const data = items?.find((item) => item.partNumber === currentPartNumber);

    superSKUAttributes?.forEach((attribute) => {
      const match = data?.attributes?.find((dataAttribute) => dataAttribute.identifier === attribute.identifier);
      if (match !== null) {
        currentValues[attribute.id] = {
          value: match?.values[0]?.identifier,
          identifier: attribute?.identifier,
          valueId: match?.values[0]?.id,
        };
      } else {
        currentValues[attribute.identifier] = null;
      }
    });

    //console.debug("extractCurrentSSKUValues", currentValues)
    setCurrentSKUAttributes(currentValues);
    handleCheckScroll();
  }

  const handleCheckScroll = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      setTimeout(() => {
        sessionStorage.removeItem("scrollPosition");
        window.scrollTo({ top: parseInt(scrollPosition), behavior: "instant" });
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
      }, 300);
    } else {
      setTimeout(() => {
        dispatch(CLOSE_BACKDROP_LOADER_ACTION({})); //if catentry data available close loader
      }, 300);
    }
  };

  /**
   * Extracts the SKU attributes for the current parent product (ProductBean)
   * @param data The parent product attributes 
   */
  function extractSSKUValues(data) {
    //Parent partnumber like 123_SS, first element equals superSKU ID
    const prefix = partNumber.split("_")[0]; 
    const attrs: any = [];
    data?.forEach((element) => {
      //Filter attributes corresponding to this super sku
      if (element.identifier.endsWith(prefix)) {
        const skuData = {};
        skuData["title"] = element.name;
        skuData["id"] = element.id;
        skuData["identifier"] = element.identifier;
        skuData["usage"] = element?.usage;
        skuData["counter"] = element.values.length;
        const values: any = [];
        //console.debug("element", element)
        element.values.forEach((value) => {
          const valueData = {};
          valueData["value"] = value.value;
          valueData["id"] = value.id;
          valueData["identifier"] = value.identifier;
          valueData["isAvailable"] = findItemWithAttrValueAvailable(items, value.id) ? true : false;
          valueData["isDisplayable"] = isValueDisplayableInSSKUItems(items, value.id);
          values.push(valueData);
        });
        skuData["values"] = values;
        attrs.push(skuData);
      }
      setSuperSKUAttributes(attrs);
    });
    //console.debug("Valor del superSKUAttributes",attrs)
    if (attrs.length !== 0) {
      const query = {
        url: "",
        data: {
          ...hdmDrawerColorData,
          superSKUAttributes: attrs,
        },
        option: "HDM_DRAWER_COLOR_DATA",
        fetch: false,
      };

      dispatch({ type: FETCH_HDM_DATA_REQUESTED, query });
    }
  }

  function isItemAvailable(id) {
    if (Object.keys(itemsAvailability).length > 0) {
      return Object.prototype.hasOwnProperty.call(itemsAvailability, id) && itemsAvailability[id];
    }
  }

  /**
   * Find the first item that contains the attribute value
   * 1.- Check if the atribute value ID exists in some item
   * 2.- Check inventory availability and also store buyable condition of those items
   * 3.- If all items with that attribute are unavailable, then the attribute is disabled
   * @param items The itemBeans
   * @param id AttrValue ID
   * @returns 
   */
  function findItemWithAttrValueAvailable(items, id) {
    //console.debug("Searching AttrValueID",id)
    //Filter those products that contain this value attribute
    const productsWithCurrentAttrValID = items?.filter((item) => {
      if (item?.attributes) {
        return item.attributes.some((attribute) => {
          return attribute.values.some((value) => {
            return value.id === id;
          });
        });
      }
    });
    //console.debug("Searching AttrValueID",id,"productsWithCurrentAttrValID",productsWithCurrentAttrValID)
    //Filter items with inventory availability and buyable in store
    const itemsBuyableAndAvailable = productsWithCurrentAttrValID?.filter((item) => isItemAvailable(item.id) && !isProductExcluded(item));
    //console.debug("itemsBuyableAndAvailable",itemsBuyableAndAvailable)
    return itemsBuyableAndAvailable?.length > 0 ? itemsBuyableAndAvailable[0] : null;
  }

  /** 
   * 1.- Check if the atribute value ID exists in some item
   * 2.- Check store buyable condition of those items
   * @param items The itemBeans
   * @param id AttrValue ID
   * @returns 
   */
  function isValueDisplayableInSSKUItems(items, id) {
    //Filter those products that contain this value attribute
    const productsWithCurrentAttrValID = items?.filter((item) => {
      if (item?.attributes) {
        return item.attributes.some((attribute) => {
          return attribute.values.some((value) => {
            return value.id === id;
          });
        });
      }
    });
    //Check store buyable condition
    const isAttributeVisibleInAnyItem = productsWithCurrentAttrValID?.some((item) => !isProductExcluded(item) );
    return isAttributeVisibleInAnyItem;
  }

  /**
   * Find the item that match the selection, in order to redirect
   * @param items 
   * @param selection 
   * @returns 
   */
  function findItemByRadioGroupSelection(items, selection) {
    //console.log("findItemByRadioGroupSelection",selection,items)
    //The selection object structure is ATTR_ID: ATTRVAL_ID
    const selectedAttributeIds = Object.keys(selection);
    //Try to find the exact match
    let matches = items?.filter((item) => {
      //The product must contain all the attributes selected (attrId)
      return selectedAttributeIds.every((attrId) => {
        return item.attributes.some(
          (attr) =>
            attr.id === attrId &&
            attr.values.some((val) => val.id === selection[attrId])
        );
      });
    });
    //Exclude no buyable products in the store
    matches = matches?.filter((item) => !isProductExcluded(item));
    if (matches?.length > 0) {
      //Returns exact match
      return matches[0];
    }

    //Try to find the second best match
    //Current new attribute selected
    const newAttrId = Object.keys(lastRadioButtonChanged)[0];
    const newAttrValueId = lastRadioButtonChanged[newAttrId];
    const secondMatch = findItemWithAttrValueAvailable(items, newAttrValueId);
    return secondMatch;
  }

  /**
   * Check the availability of products in the current store
   * @param items 
   */
  function obtainItemsAvailability(items) {
    const itemsIds = items ? items.map((item) => item.id) : [];
    const itemAvailability = {};

    customInv
      .getAvailabilityByStores({
        productIds: itemsIds,
        onlineStoreId: mySite.storeID,
        productavailable: true,
        fullfillment_type: "Store",
        type: "ItemBean",
        search: 2,
        physicalStoreId: [storeUniqueId],
      })
      .then((data) => {
        data?.forEach((item) => {
          //WIP: Check if used response field are correct to consider if an item is available or not.
          itemAvailability[item?.productId] = item?.inventoryStatus === "Available" && (Number(item?.availableQuantity) > 0 || Number(item?.x_AvailableQuantityInMarket) > 0 || Number(item?.x_customField2) > 0);
        });
        setItemsAvailability(itemAvailability);
      })
      .catch((error) => {
        //If error, set items availability empty
        setItemsAvailability({});
        console.error("Algo salio mal:", error);
        handleCheckScroll();
      });
  }

  //When product data is ready
  useEffect(() => {
    //get items inventories
    obtainItemsAvailability(items);
  }, [product]);

  //When items availability is setted
  useEffect(() => {
    //If still have the initial value null, do nothing
    if (itemsAvailability != null) {
      //Here a value has been setted
      if (Object.keys(itemsAvailability).length > 0) {
        extractSSKUValues(attributes);
      } else {
         //Item availability request completed, but no item available
        setShowSkeletonLoader(false); //Stop showing Skeleton Loader
        setCurrentSKUAttributes(undefined); //Flag for empty currentSKUAttributes, allow to hide the Skeleton Loader
      }
    }

    setTimeout(() => {
      handleCheckScroll();
    }, 5000);
  }, [itemsAvailability]);

  useEffect(() => {
    superSKUAttributes != null && extractCurrentSSKUValues(items);
  }, [superSKUAttributes]);

  //on component load, call for SuperSKU_Help Espot
  useEffect(() => {
    const asyncCall = async () => {
      const parameters: any = {
        storeId: storeID,
        name: DrawerEspotName,
        catalogId: catalogID,
        query: {
          DM_ReturnCatalogGroupId: true,
          DM_FilterResults: false,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      let eSpotRoot;
      try {
        const res = await eSpotService.findByName(parameters);
        eSpotRoot = res.data.MarketingSpotData[0];
        //If the espot exists and has content
        if (eSpotRoot?.eSpotName && eSpotRoot?.baseMarketingSpotActivityData?.length > 0) {
          setSuperSKUEspot(res);
        }
        else{
          //If no espot, change null to undefined
          //In order to detect a new value and then can hide the sekeleton loader
          setSuperSKUEspot(undefined);
        }
      } catch (e) {
        eSpotRoot = null;
      }
    };

    asyncCall();
  }, [superSKUAttributes]);

  useEffect(() => {
    //If still have the initial value null, do nothing
    if (superSKUEspot !== null) {
      //Here a value has been setted
      //If there is an espot drawer, enable the link to open it
      if (superSKUEspot != undefined) {
        setEspotHasContent(true);
        setSuperSKUEspotComponent(<EspotHdm espotName={DrawerEspotName} />);
      } else {
        //Asign false so the Skeleton Loader can be hidden
        setEspotHasContent(false);
      }
    }
  }, [superSKUEspot]);

  const handleSetScrollPosition = () => {
    sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const handleChangeBaseColor = async ({ name, value, id, color }) => {
    const lastSelection = {};
    lastSelection[id] = value;

    await setColorCode(color);
    const updatedValue = {};
    //value field is not used, in case it is neccesary.. further development is necessary
    updatedValue[id] = { value: "", identifier: name, valueId: value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    await setLastRadioButtonChanged(lastSelection);

    buttonRef.current.click();
  };

  /**
   * When clicks some radio button
   * @param event Radio button event
   * @param id attribute ID
   */
  const handleChange = async (event, id) => {
    const lastSelection = {};
    lastSelection[id] = event.target.value;
    const updatedValue = {};

    //value field is not used, in case it is neccesary.. further development is necessary
    //Update the selected attribute
    updatedValue[id] = { value: "", identifier: event.target.name, valueId: event.target.value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    await setLastRadioButtonChanged(lastSelection);
    //Submit the form (handleSubmit)
    buttonRef.current.click();
  };

  /**
   * When change a value in the select
   * @param event Change selection event
   * @param id attribute ID
   */
  const handleSelectChange = async (event, id) => {
    const lastSelection = {};
    lastSelection[id] = event.target.value;
    await setLastRadioButtonChanged(lastSelection);

    const updatedValue = {};
    //value field is not used, in case it is neccesary.. further development is necessary
    //Update the selected attribute
    updatedValue[id] = { value: "", identifier: event.target.name, valueId: event.target.value };
    await setCurrentSKUAttributes((currentSKUAttributes) => ({
      ...currentSKUAttributes,
      ...updatedValue,
    }));
    //Submit the form (handleSubmit)
    buttonRef.current.click();
  };

  //When submit the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const radioGroupSelection = {};
    superSKUAttributes.forEach((attr) => {
      const element = formRef?.current?.elements[attr.identifier];
      if (element) {
        radioGroupSelection[attr.id] = element.value;
      }
    });
    //Filter items with inventory
    const availableItems = items.filter((item) => itemsAvailability[item.id]);
    const match = findItemByRadioGroupSelection(availableItems, radioGroupSelection);
    //Navigate to product match result
    if (match?.seo?.href) {
      const color = colorCode ? `#colorCode:${colorCode}` : "";
      handleSetScrollPosition();
      navigate(match.seo.href + color);
    }
  };

  //If the current product is the unique combination available, do not show the card "Selecciona tus opciones"
  let shouldRenderComponent = false;
  if(superSKUAttributes && itemsAvailability && currentSKUAttributes){
    //Total superSKU options
    let attrAvailabeOptionCount = superSKUAttributes?.length;
    superSKUAttributes?.forEach(superSKUAttr => {  
      //Exclude color
      if (superSKUAttr?.identifier?.includes("BASE_")) {
        attrAvailabeOptionCount--;
      }      
      else{
        //How many available values has the attribute
        const availableAttrValues = superSKUAttr.values.filter(
          (value) => value.isDisplayable && value.isAvailable
        );
        //If there is only 1 selectable value
        if(availableAttrValues?.length == 1){
          //Validate if its the value for the current product
          const currentItemAttrValueId = currentSKUAttributes[superSKUAttr.id]?.valueId;
          if(currentItemAttrValueId && currentItemAttrValueId === availableAttrValues[0]?.id){
            //The user cant choose another option for this attribute, so decrease selectable options counter
            attrAvailabeOptionCount--;
          }
        }
      }
    });
    //If there is no selectable options available, do not render component
    shouldRenderComponent = attrAvailabeOptionCount > 0;
  }

  /*
  Checks if the attribute value has an exact match with the rest of the attributes of the current product
  If not, the attribute value will be displayed with 60% opacity
  */
  useEffect(() => {
    if (superSKUAttributes && itemsAvailability && currentSKUAttributes) {
      const newItemsWithExactMatch: { [key: string]: boolean } = {};
      superSKUAttributes.forEach((attribute) => {
        attribute.values.forEach((value) => {
          //Copy the current attributes selection
          const selectionCopy = { ...currentSKUAttributes };
          //Current value attribute variant
          selectionCopy[attribute.id] = {
            value: value.value,
            identifier: attribute.identifier,
            valueId: value.id,
          };
  
          const matchingProduct = findMatchingProduct(selectionCopy);
          //If the superSKU attribute value has exact match available (if there is no exact match it will be opaque)
          newItemsWithExactMatch[value.id] = matchingProduct !== null;
        });
      });
  
      setValuesWithExactMatch(newItemsWithExactMatch);
    }
  }, [superSKUAttributes, itemsAvailability, currentSKUAttributes]);

  /**
   * Finds an exact match with the entered combination of attributes
   * Must have inventory and not be unpublished
   * @param selection Combination of attribute values to match against
   * @returns 
   */
  function findMatchingProduct(selection) {
    const newSelectedAttributes = Object.keys(selection);
  
    const matchingProducts = items.filter((item) => {
      //Para cada atributo de la nueva seleccion
      const matchesAllAttributes = newSelectedAttributes.every((attrId) => {
        const itemHasAttribute = item.attributes.some((itemAttr) => {
          const attributeMatches =
            itemAttr.id === attrId &&
            itemAttr.values.some(
              (val) => val.id === selection[attrId].valueId
            );
          return attributeMatches;
        });
        return itemHasAttribute;
      });
      return matchesAllAttributes;
    });
  
    //console.debug('Seleccion actual:', selection);
    //console.debug('Productos que coinciden:', matchingProducts);
  
    //The matched items must have inventory and be published
    const availableProducts = matchingProducts?.filter(
      (product) => itemsAvailability[product.id] && !isProductExcluded(product)
    );
    //console.debug('Productos disponibles:', availableProducts);
  
    return availableProducts?.length > 0 ? availableProducts[0] : null;
  }

  /**
   * Check if the product is excluded for purchase in the current store or market
   * @param item 
   * @returns 
   */
  function isProductExcluded(item) {
   return item.attributes.some((attribute) => {
      if (attribute?.identifier === ATTR_STORE_BUYABLE_EXCLUDE) {
        return attribute?.values.some((value) => {
          return value?.value && value.value.includes(physicalStoreIdentifier);
        });
      }
      else if(attribute?.identifier === ATTR_MARKET_BUYABLE_EXCLUDE) {
        return attribute?.values.some((value) => {
          return value?.value && value.value.includes(currentMarketId);
        });
      }
      return false;
    });
  }

const PDPRightSideCard = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme }) => `
  background-color:white;

  .super-sku-label-unavailable{
    opacity:60%;
  }

  .atribute-sku-hide {
    display: none;
  }
`}
`;

const PDPRightSideCardRadioContainer = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme }) => `
  display: flex;
  margin-right:10px;
  margin-bottom:10px;

  p{
    margin-right:5px;
  }

  .PDP-SSKUContainer-RadioComponent{
    margin-bottom:10px;
    display: flex;
    align-items:center;
  }
`}
`;

const PDPRightSideCardContainer = styled(({ ...props }) => <StyledBox {...props} />)`
${({ theme }) => `
  padding:10px;
  margin-bottom: 10px;

  .PDPRightSideCard-title{
    margin-bottom: 10px;
  }

  .PDPRightSideCard-divider{
    background-color:${theme.palette.gray["50"]};
    margin-bottom:10px;
  }
`}
`;

const StyledFormControlLabel = styled(({ ...props }) => <FormControlLabel {...props} />)`
${({ theme }) => `
  padding: 0 !important;
  margin: 0px !important;

  .MuiRadio-colorSecondary.Mui-checked {
    color: #F96302 !important;
  }
  
  &.hidden {
    display: none;
  }
`}
`;

  return (
    <>
      {attributes && items && currentStoreData?.currentStore?.uniqueID && (
        <>
          {/* This over-validations seems unnecesary, but must be this way for Skeleton Loader right working */}
          {showSkeletonLoader || superSKUAttributes == null || currentSKUAttributes == null ? (
              <SLPDPSelectYourOptions />
            ) : (
              <PDPRightSideCard id="pdp-supersku-card" hidden={!shouldRenderComponent}>
                <PDPRightSideCardContainer className="super-sku supersku-card">
                  <StyledTypography variant="textSegment" className={"PDPRightSideCard-title"}>
                    Selecciona tus opciones
                  </StyledTypography>
                  <Divider className={"PDPRightSideCard-divider"} />
                  <form ref={formRef} onSubmit={handleSubmit}>
                    {/* Print all SS attributes */}
                    {superSKUAttributes.map((attribute) => {
                      let classAttrHide = "";
                      if (attribute?.identifier.includes("BASE_") && attribute?.usage === "Defining") {
                        classAttrHide = "atribute-sku-hide";
                      }
                      //Available values count
                      const availableValuesCount = attribute.values.filter(
                        (value) => value.isDisplayable && value.isAvailable
                      ).length;
                      return (
                        <StyledBox key={attribute?.valueId} className={classAttrHide}>
                          <PDPRightSideCardRadioContainer className="supersku-attribute">
                            <StyledTypography variant="bodyBaseline">{attribute.title}</StyledTypography>
                            <StyledTypography variant="bodyBaseline" fontWeight={600}>
                              {currentSKUAttributes[attribute.id].value}
                            </StyledTypography>
                          </PDPRightSideCardRadioContainer>

                          {availableValuesCount < 5 ? (
                            <>
                              <StyledRadioGroup
                                aria-labelledby={"super-sku-" + attribute.identifier}
                                defaultValue={currentSKUAttributes[attribute.id].valueId}
                                name={attribute.identifier}
                                onChange={(e) => handleChange(e, attribute.id)}>
                                <PDPRightSideCardRadioContainer>
                                  {attribute.values.map((value) => {
                                    if(value.isDisplayable && value.isAvailable){
                                      const hasExactMatch = valuesWithExactMatch[value.id];
                                      return (
                                        <div className={"PDP-SSKUContainer-RadioComponent"} style={{ marginBottom: "10px" }} key={attribute.id + "|" + value.id}>
                                          <StyledFormControlLabel
                                            color="primary"
                                            className={
                                              hasExactMatch ? "super-sku-label-available" : "super-sku-label-unavailable"
                                            }
                                            value={value.id}
                                            control={<StyledRadio />}
                                            label={
                                              <StyledTypography
                                                variant="bodyBaseline"
                                                className={
                                                  hasExactMatch ? "super-sku-label-available" : "super-sku-label-unavailable"
                                                }>
                                                {value.value}
                                              </StyledTypography>
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                                </PDPRightSideCardRadioContainer>
                              </StyledRadioGroup>
                            </>
                          ) : (
                            <PDPRightSideCardRadioContainer style={{ marginBottom: "10px" }}>
                              <StyledSelectHdm
                                label={null}
                                name={attribute.identifier}
                                value={currentSKUAttributes[attribute.id].valueId}
                                handleChange={(e) => handleSelectChange(e, attribute.id)}
                                id={"super-sku-" + attribute.id}
                                className="supersku-select"
                                defaultValue={currentSKUAttributes[attribute.id].valueId}
                                items={attribute.values.map((value) =>
                                  value.isDisplayable && value.isAvailable ? (
                                    <MenuItemHDM
                                      className={
                                        valuesWithExactMatch[value.id] ? "super-sku-label-available" : "super-sku-label-unavailable"
                                      }
                                      value={value.id}
                                      key={attribute.id + "|" + value.id}>
                                      {value.value}
                                    </MenuItemHDM>
                                  ) : null
                                )}
                              />
                            </PDPRightSideCardRadioContainer>
                          )}
                        </StyledBox>
                      );
                    })}
                    <button ref={buttonRef} type="submit" style={{ display: "none" }}>
                      Submit
                    </button>
                  </form>
                  {/* While espotHasContent equals null, show Skeleton Loader */}
                  {/* Renderiza el espot si esta disponible */}
                  {espotHasContent !== null ? (
                    espotHasContent === true &&
                    superSKUEspotComponent && (
                      <>
                        <StyledLinkButton
                          variant="bodySubText"
                          onClick={() => setOpenDrawer(true)}
                          to=""
                          color="secondary">
                          {" Ayuda para elegir"}
                        </StyledLinkButton>

                        <StyledSwipeableDrawerHDM
                          isOpen={openDrawer}
                          setOpenDrawer={setOpenDrawer}
                          variant={"super-sku"}
                          title={superSkuDrawerTitle}>
                          {superSKUEspotComponent}
                        </StyledSwipeableDrawerHDM>
                      </>
                    )
                  ) : (
                    <SLPDPHelpForChoose />
                  )}
                </PDPRightSideCardContainer>
              </PDPRightSideCard>
            )
          }
        </>
      )}
    </>
  );
};

export default SuperSkuContainer;
