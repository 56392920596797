import { ChangeEvent, useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import { onlineStoreRdc } from "../../../redux/selectors/hdmData";

import {
  StyledBox,
  StyledIconButton,
  StyledMenuItem,
  StyledTextField,
  StyledTooltip,
  StyledTypography,
} from "../../../hdm/elements";
import { StyledSelectHdm } from "../../custom-components/styled-select/StyledSelectHdm";
import { ClickAwayListener, InputAdornment } from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/core/styles";
import HelpIcon from "../../../assets/homedepot/images/HelpIcon.js";
import { useSelector } from "react-redux";
import { StyledTooltipHdm } from "../../custom-components/styled-tooltip-hdm/StyledTooltipHdm";
import EspotHdm from "../../custom-components/shared/EspotHdm";
import { OTHER_COLONY } from "../../../constants/common";
import { AddressForm } from "../../widgets/address-form/AddressForm";
import { useSite } from "../../../_foundation/hooks/useSite";

interface Props {
  misDirecciones: any;
  handleCanSaveDireccion: (dataDireccion: any) => void;
  selectedAddress: any;
  isOpen: boolean;
  isEdit: boolean;
  setIsInBlockList:any;
}

const ESPOT_TOOLTIP = "Checkout_DeliveryDetail_AddressForm-Tooltip";

const initAddress = {
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "0",
  addressLine3Txt: "",
  field1: "",
  field3: "",
  city: "0",
  country: "MX",
  state: "0",
  zipCode: "",
  phone1: "",
  nickName: "",
  email1: "",
  addressType: "ShippingAndBilling"
}
const CheckoutProfileAddressForm = (props: Props) => {

  const {setIsInBlockList} = props;
  const storeConfData = useSelector(onlineStoreRdc);
  const [googleApiGeoKey, setGoogleApiGeoKey] = useState("");
  const { mySite } = useSite();
  const isB2B = Boolean(mySite?.isB2B);
  const { misDirecciones, handleCanSaveDireccion, selectedAddress, isOpen, isEdit } = props;
  const [addressFormData, setAddressFormData] = useState<any>(initAddress);

  const [nickName, setNickname] = useState<string>("");
  const [calle, setCalle] = useState<string>("");
  const [numeroExt, setNumeroExt] = useState<string>("");
  const [apartamento, setApartamento] = useState<string>("");
  const [colonia, setColonia] = useState<string>("");
  const [colonias, setColonias]: any = useState([]);
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState<any>({});

  const [itemsColonias, setItemsColonias] = useState<any>([]);
  const [itemCity, setItemCity] = useState<any>([]);
  const [itemState, setItemState] = useState<any>([]);

  const [coloniasDisabled, setColoniasDisabled] = useState<boolean>(true);

  const [errorNickname, setErrorNickname] = useState<any>({ error: false, errorMsg: "" });
  const [errorCalle, setErrorCalle] = useState<any>({ error: false, errorMsg: "" });
  const [errorNumeroExt, setErrorNumeroExt] = useState<any>({ error: false, errorMsg: "" });
  const [errorZipCode, setErrorZipCode] = useState<any>({ error: false, errorMsg: "" });
  const [errorColonia, setErrorColonia] = useState<any>({ error: false, errorMsg: "" });
  const [errorCustomColonia, setErrorCustomColonia] = useState<any>({ error: false, errorMsg: "" });

  const [customColonia, setCustomColonia] = useState<string>("");
  const [coloniaNotFound, setColoniaNotFound] = useState(false);

  const [isEdited, setIsEdited] = useState<boolean>(false);

  const theme = useTheme();
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const isMobile = false;

  const { t } = useTranslation();

  useEffect(() => {
    if (storeConfData?.googleAPIGeoLocateKey) {
      setGoogleApiGeoKey(storeConfData?.googleAPIGeoLocateKey);
    }
  }, [storeConfData]);

  useEffect(() => {

    if(isEdit){
      let departamento: any = ""
      if(selectedAddress?.attributes && selectedAddress?.attributes?.length > 0){
        selectedAddress.attributes.map(attr => {
          if(attr?.key && attr.key === "addressField3"){
            departamento = attr?.value
          }
          if(attr?.contactInfoAttrKey && attr.contactInfoAttrKey === "addressField3"){
            departamento = attr?.contactInfoAttrValue
          }
        })
      }
      const thisAddress = {
        ...selectedAddress,
        addressLine1 : selectedAddress?.addressLine ? selectedAddress?.addressLine[0] : "",
        addressLine2 : selectedAddress?.addressLine ? selectedAddress?.addressLine[1] : "",
        addressLine3 : selectedAddress?.addressLine ? selectedAddress?.addressLine[2] : "",
        field3: departamento
      }
      setAddressFormData(thisAddress)
    }else {
      setAddressFormData(initAddress)
    }

  }, [])

  // useEffect(() => {
  //   setCustomAddressList([...filteredAddresses]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [usableAddresses]);

  useEffect(() => {
    handleCanSave()
  }, [addressFormData])

  useEffect(() => {
    if (nickName.length > 0) {
      setErrorNickname({
        error: false,
        errorMsg: "",
      });
    }

    if (calle.length > 0) {
      setErrorCalle({
        error: false,
        errorMsg: "",
      });
    }

    if (numeroExt.length > 0) {
      setErrorNumeroExt({
        error: false,
        errorMsg: "",
      });
    }

    if (customColonia.length > 0) {
      setErrorCustomColonia({
        error: false,
        errorMsg: "",
      });
    }

    handleCanSave();
  }, [nickName, calle, numeroExt, colonia, city, state, apartamento, customColonia]);

  useEffect(() => {
    if (zipCode.length === 5) {
      //   setZipCodeDisabled(true)
      setColoniasDisabled(false);
      //   setCityDisabled(true)
      //   setStateDisabled(true)
      handleGetZipCode();
    }

    if (zipCode.length < 5) {
      setColoniasDisabled(true);
      //   setCityDisabled(true)
      //   setStateDisabled(true)

      setItemState(
        <StyledMenuItem value={""} key={"estado"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline"></StyledTypography>
        </StyledMenuItem>
      );
      setItemCity(
        <StyledMenuItem value={""} key={"ciudad"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline"></StyledTypography>
        </StyledMenuItem>
      );
      setItemsColonias(
        <StyledMenuItem value={""} key={"coonia"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline"></StyledTypography>
        </StyledMenuItem>
      );
      setColonia("");
      setState("");
      setCity("");
    }

    if (zipCode.length > 0) {
      setErrorZipCode({
        error: false,
        errorMsg: "",
      });
    }
  }, [zipCode]);

  useEffect(() => {
    if (isOpen) {
      if (isEdit) {
        setNickname(selectedAddress.nickName);
        setCalle(selectedAddress.addressLine[0]);
        setNumeroExt(selectedAddress.addressLine[1]);
        setColonia(selectedAddress.addressLine[2]);
        setZipCode(selectedAddress.zipCode);

        selectedAddress?.attributes &&
          selectedAddress.attributes.map((attr) => {
            if (selectedAddress.primary === "true") {
              if (attr.contactInfoAttrKey === "addressField3") {
                setApartamento(attr.contactInfoAttrValue);
              }
            } else {
              if (attr.key === "addressField3") {
                setApartamento(attr.value);
              }
            }
          });
      }
    }
  }, [isOpen]);

  const DarkTooltipInput = withStyles({
    tooltip: {
      backgroundColor: "#EEEEEE !important",
      color: "#333",
      borderRadius: "0px",
      width: "100px",
      marginTop: "-3px",
      marginRight: "65px",
      borderTop: "2px solid " + theme.palette.primary.main,
    },
  })(StyledTooltip);

  const StyledHelpIcon = withStyles({
    root: {
      color: theme.palette.primary.main,
      width: "14px",
      height: "14px",
      marginRight: "-25px",
    },
  })(HelpIcon);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(!openTooltip);
  };

  const handleCanSave = () => {
    let canSave = true;
    if (addressFormData?.nickName.trim() === "") {
      canSave = false;
    }
    if (addressFormData?.addressLine1.trim() === "") {
      canSave = false;
    }
    if (addressFormData?.addressLine2.trim() === "") {
      canSave = false;
    }
    if (addressFormData?.zipCode.trim().length !== 5 || (isB2B && !isFormValid?.isZipCodeValid)) {
      canSave = false;
    }
    if (addressFormData?.addressLine3.trim() === "Selecciona una colonia" || addressFormData?.addressLine3.trim() === "" || addressFormData?.addressLine3.trim() === "0") {
      canSave = false;
    }

    if(addressFormData?.addressLine3.trim() === OTHER_COLONY && addressFormData?.addressLine3Txt.trim() === ""){
      canSave = false;
    }
    // if (addressFormData?.addressLine3.trim() === "" || colonia === "Selecciona una colonia") {
    //   canSave = false;
    // }
    // if (colonia === OTHER_COLONY) {
    //   if (customColonia.trim() === "") {
    //     canSave = false;
    //   }
    // }
    if (addressFormData?.city.trim() === "") {
      canSave = false;
    }
    if (addressFormData?.state.trim() === "") {
      canSave = false;
    }

    if (!isEdit) {
      misDirecciones.map((dir) => {
        if (dir.nickName.trim().toLowerCase() === addressFormData?.nickName.trim().toLowerCase()) {
          canSave = false;
          setErrorNickname({
            error: true,
            errorMsg: "Alias no disponible",
          });
        }
      });
    }

    if (canSave && isEdited) {
      handleCanSaveDireccion({
        nickName: addressFormData?.nickName.trim(),
        calle: addressFormData?.addressLine1.trim(),
        numeroExt: addressFormData?.addressLine2.trim(),
        apartamento: addressFormData?.field3?.trim(),
        zipCode: addressFormData?.zipCode.trim(),
        colonia: addressFormData?.addressLine3 === OTHER_COLONY ? addressFormData?.addressLine3Txt : addressFormData?.addressLine3,
        city: addressFormData?.city.trim(),
        state: addressFormData?.state.trim(),
      });
    } else {
      handleCanSaveDireccion(null);
    }
  };

  const handleChangeAllReadyEdited = () => {
    setIsEdited(true)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsEdited(true);


    if (event.target.name === "nickName") {
      const patt = new RegExp(/^[A-Za-z-ñÑáéíóúÝÉÝÓÚ´\s]+$/g);
      const isValid = patt.test(event.target.value);

      if (isValid || event.target.value.trim() === "") {
        setNickname(event.target.value);
      }
    }

    if (event.target.name === "calle") {
      const patt = new RegExp(/^[A-Za-z0-9ñÑáéíóúÝÉÝÓÚ´\s]+$/g);
      const isValid = patt.test(event.target.value);

      if (isValid || event.target.value.trim() === "") {
        setCalle(event.target.value);
      }
    }

    if (event.target.name === "apartamento") {
      const patt = new RegExp(/^[-_ A-Za-z0-9\s]+$/g);
      const isValid = patt.test(event.target.value);

      if (isValid || event.target.value.trim() === "") {
        setApartamento(event.target.value);
      }
    }

    if (event.target.name === "colonia") {
      if (event.target.value === OTHER_COLONY) {
        //setCustomColonia("");
        setColoniaNotFound(true);
        setColonia(event.target.value);
      } else {
        setColoniaNotFound(false);
        setColonia(event.target.value);
        setCustomColonia("");
      }
    }

    if (event.target.name === "coloniaNotFound") {
      setCustomColonia(event.target.value);
      // setColonia(OTHER_COLONY);
    }
  };

  const handleZipCode = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const num = Number(event.target.value.trim());
    if (Number.isInteger(num)) {
      if (event.target.value.trim().length < 6) {
        if (event.target.value.trim() !== zipCode) {
          setZipCode(event.target.value.trim());
        }
      }
    }
  };

  const handleGetZipCode = async () => {
    let ciudad = "";
    let estado = "";
    let postcode_localities = [];

    const geocoder = new window["google"].maps.Geocoder();

    await geocoder.geocode(
      {
        componentRestrictions: {
          country: "MX",
          postalCode: zipCode,
        },
      },
      function (results, status) {
        if (results && results.length > 0 && status === "OK") {
          postcode_localities = results[0]?.postcode_localities ? results[0].postcode_localities : [];
          const col = results[0]?.postcode_localities ? results[0].postcode_localities : [];

          if (col.length === 0) {
            col.push(results[0].address_components[1].long_name);
          }

          col.unshift("Selecciona una colonia");
          col.push(OTHER_COLONY);
          {
            console.info("COLLLL", col);
          }
          if (col.length !== 0) {
            setColonias(["init", ...col, OTHER_COLONY]);
          }

          if (results[0]?.address_components) {
            const addressComponents = results[0]?.address_components;

            addressComponents.map((address) => {
              if (address.types.includes("administrative_area_level_1")) {
                estado = address.long_name;
              }

              if (address.types.includes("locality")) {
                ciudad = address.long_name;
              }

              //FALTA IMPLEMENTACION QUE SE ESTA REALIZANDO REMOVER ESTO CUANDO QUEDE HECHA
              if (ciudad === "") {
                ciudad = estado;
              }
            });
          }
          postcode_localities = col;
        }
      }
    );

    const menuItemsColonias: any = [];
    if (postcode_localities.length === 0) {
      menuItemsColonias.push(
        <StyledMenuItem value={""} key={"0"} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">Selecciona una colonia</StyledTypography>
        </StyledMenuItem>
      );
      setItemsColonias(menuItemsColonias);
      setColonia("");
    } else {
      postcode_localities.map((colony, index) => {
        menuItemsColonias.push(
          <StyledMenuItem value={colony} key={colony} className={"styledSelect--menuItems-color"} fullWidth>
            <StyledTypography variant="bodyBaseline">{colony}</StyledTypography>
          </StyledMenuItem>
        );
      });

      setItemsColonias(menuItemsColonias);
      if (colonia === "") {
        setColonia(postcode_localities[0]);
      } else {
        const coloniaInItems = postcode_localities.find((item) => item === colonia);
        if (coloniaInItems) {
          setColonia(coloniaInItems);
        } else {
          setColonia(postcode_localities[postcode_localities.length - 1]);
          setCustomColonia(colonia);
          setColoniaNotFound(true);
        }
      }
    }

    if (ciudad === "") {
      setItemCity(
        <StyledMenuItem value={ciudad} key={ciudad} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{ciudad}</StyledTypography>
        </StyledMenuItem>
      );
      setCity("");
    } else {
      setItemCity(
        // <StyledMenuItem value={""} className={"styledSelect--menuItems-color"} fullWidth>
        //   <StyledTypography variant="bodyBaseline">Selecciona un municipio</StyledTypography>
        // </StyledMenuItem>
        <StyledMenuItem value={ciudad} key={ciudad} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{ciudad}</StyledTypography>
        </StyledMenuItem>
      );
      setCity(ciudad);
    }

    if (estado === "") {
      setItemState(
        <StyledMenuItem value={estado} key={estado} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{estado}</StyledTypography>
        </StyledMenuItem>
      );
      setState("");
    } else {
      setItemState(
        // <StyledMenuItem value={""} className={"styledSelect--menuItems-color"} fullWidth>
        //   <StyledTypography variant="bodyBaseline">Selecciona un Estado</StyledTypography>
        // </StyledMenuItem>
        <StyledMenuItem value={estado} key={estado} className={"styledSelect--menuItems-color"} fullWidth>
          <StyledTypography variant="bodyBaseline">{estado}</StyledTypography>
        </StyledMenuItem>
      );
      setState(estado);
    }

    if (postcode_localities.length === 0 || ciudad === "" || estado === "") {
      {
        console.info("ALgo paso D: ", postcode_localities);
      }
      {
        console.info("Ciudad: ", ciudad);
      }
      {
        console.info("Estado: ", estado);
      }
      setColoniasDisabled(false);
      // setCityDisabled(true)
      // setStateDisabled(true)
    } else {
      setColoniasDisabled(false);
      // setCityDisabled(false)
      // setStateDisabled(false)
    }

    //   setZipCodeDisabled(false)
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const valor = event.target.value;

    if (name === "nickName") {
      if (valor.trim() === "") {
        setErrorNickname({
          error: true,
          errorMsg: "Campo obligatorio",
        });
      } else {
        setErrorNickname({
          error: false,
          errorMsg: "",
        });
      }

      misDirecciones.map((dir) => {
        if (dir.nickName.trim().toLowerCase() === valor.trim().toLowerCase()) {
          setErrorNickname({
            error: true,
            errorMsg: "Alias no disponible",
          });
        }
      });
    }

    if (name === "calle") {
      if (valor.trim() === "") {
        setErrorCalle({
          error: true,
          errorMsg: "Campo obligatorio",
        });
      } else {
        setErrorCalle({
          error: false,
          errorMsg: "",
        });
      }
    }

    if (name === "numeroExt") {
      if (valor.trim() === "") {
        setErrorNumeroExt({
          error: true,
          errorMsg: "Campo obligatorio",
        });
      } else {
        setErrorNumeroExt({
          error: false,
          errorMsg: "",
        });
      }
    }

    if (name === "zipCode") {
      if (valor.trim() === "") {
        setErrorZipCode({
          error: true,
          errorMsg: "Campo obligatorio",
        });
      } else {
        setErrorZipCode({
          error: false,
          errorMsg: "",
        });
      }
    }

    if (name === "coloniaNotFound") {
      if (valor.trim() === "") {
        setErrorCustomColonia({
          error: true,
          errorMsg: "Campo obligatorio",
        });
      } else {
        setErrorCustomColonia({
          error: false,
          errorMsg: "",
        });
      }
    }

  };


  return (
    <AddressForm
        cid="newAddress"
        setAddressFormData={setAddressFormData}
        addressFormData={addressFormData}
        edit={isEdit}
        fromCheckOutProfiles={true}
        handleChangeAllReadyEdited={handleChangeAllReadyEdited}
        usableAddresses={misDirecciones}
        page="checkout-profile"
        setIsFormValid={setIsFormValid}
        isFormValid={isFormValid}
        setIsInBlockList={setIsInBlockList}
    />
  )

  
};

export default CheckoutProfileAddressForm;
