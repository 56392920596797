/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import { ProductNotAvailableReducerState } from "./reducerStateInterface";
import { SET_PRODUCT_NOT_AVAILABLE_ACTION } from "../actions/productNotAvailable";

/**
 * ProductNotAvailable reducer
 * handles states used by productnotavailable related components
 * @param state State object managed by productnotavailable reducer
 * @param action The dispatched action
 */
const productNotAvailableReducer = createReducer(initStates.productNotAvailable, (builder) => {
  builder.addCase(SET_PRODUCT_NOT_AVAILABLE_ACTION, (state: ProductNotAvailableReducerState | any, action: AnyAction) => {
    state.isProductNotAvailable = action.payload;
    return state;
  });
});

export default productNotAvailableReducer;
