import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { currentStoreSelector } from "../../../redux/selectors/current-store";
import { onlineStoreRdc } from "../../../redux/selectors/hdmData";
import { useSite } from "../../../_foundation/hooks/useSite";

import { AhorraMasPromo } from "./promos/ahorra-mas/AhorraMas";
import { ProductoGratis } from "./promos/producto-gratis/ProductoGratis";
import { MesesSinIntereses } from "./promos/msi/MesesSinIntereses";
import { PrecioMayoreo } from "./promos/precio-mayoreo/PrecioMayoreo";
import { Remate } from "./promos/reamate/Remate";
import { NporM } from "./promos/nxm/NporM";
import { Combo } from "./promos/combo/Combo";
import { Divider } from "@material-ui/core";

import { palette } from "../../themes/color-palette";
import { StyledBox } from "../../elements/box";
import { DIY_STORE_ID } from "../../../constants/common";

interface Props {
  catentryData: any;
  product: any;
  brand: any;
  attributes: any;
  partNumber: any;
  shortDescription: any;
  nominalQuantity: any;
  productId: any;
  orderRibbons: any;
  urlRibbons: any;
}

export const AssignedPromo = (props: Props) => {
  //console.log("propsAssignedPromo => ", props)
  let { orderRibbons } = props;
  const {
    catentryData,
    product,
    productId,
    urlRibbons,
    brand,
    attributes,
    partNumber,
    shortDescription,
    nominalQuantity,
  } = props;

  const storeSelector = useSelector(currentStoreSelector);
  const storeConfData = useSelector(onlineStoreRdc);
  const { mySite } = useSite();

  const [conRegalo, setConRegalo] = useState<any>(null);
  const [nXm, setNxM] = useState<any>(null);
  const [precioMayoreo, setPrecioMayoreo] = useState<any>(null);
  const [ahorraMas, setAhorraMas] = useState<any>(null);
  const [articulos, setArticulos] = useState<any>(null);
  const [inicializado, setinicializado] = useState(false);
  const [promotionDesc, setPromotionDesc] = useState<any>("");
  const [baseQty, setBaseQty] = useState<any>(null);
  const [giftQty, setGiftQty] = useState<any>(null);

  useEffect(() => {
    if(storeConfData?.ENABLE_API_CALL_FETCH_PROMO === "true"){
      fetchPromo();
    }
  }, [productId]);

  const fetchPromo = async () => {
    if (!productId) return;
    if (orderRibbons?.includes("PROMOICON_GR")) {
      setinicializado(true);
      return;
    }

    const physicalStoreId = storeSelector.currentStore.stLocId;
    const marketId = storeSelector.currentStore.marketId;
    // const catentryId = product.type === "product" ? product.items[0].id : product.id;
    // const catentryId = product.type === "item"? product.items[0].id:product.id;
    let allPromotions: any = null;

    const URL = `/wcs/resources/store/${DIY_STORE_ID}/promotionFetch/byCatentryId?responseFormat=json
    &currentPhysicalStoreId=${physicalStoreId}&currentMarketId=${marketId}&catentryId=${productId}&profileName=HDM_findDisplayPromotionByCatentryId&langId=-5`;
    await axios
      .get<any>(URL)
      .then((response) => {
        if (response && response.status === 200) {
          allPromotions = response.data;
        }
      })
      .catch(function (e) {
        console.log("another error happened:" + e.message);
      });

    // console.log("allPromotions => ", allPromotions)

    if (allPromotions?.promotionData) {
      if (allPromotions.promotionData.adminDesc === "Artículo de Regalo") {
        const articulosIds = allPromotions.promotionData.giftCatentries.slice(0, 3);
        let stringIds = "?";
        articulosIds.map((art) => {
          stringIds = `${stringIds}id=${art}&`;
        });

        await axios
          .get<any>(`/search/resources/store/${mySite.storeID}/productview/byIds${stringIds}`)
          .then((response) => {
            if (response && response.status === 200 && response?.data?.catalogEntryView) {
              setConRegalo(response.data.catalogEntryView);
              setPromotionDesc(allPromotions?.promotionData?.field2);
              setBaseQty(allPromotions?.promotionData?.baseQty);
              setGiftQty(allPromotions?.promotionData?.giftQty);
            }
          })
          .catch(function (e) {
            console.log("another error happened:" + e.message);
          });
      }

      if (allPromotions.promotionData.adminDesc === "NxM") {
        setNxM(allPromotions.promotionData);
      }
    }

    if (allPromotions?.savingsPackData) {
      setPrecioMayoreo(allPromotions.savingsPackData);
    }

    if (allPromotions?.promotionDataPercent) {
      allPromotions.promotionDataPercent.tipoAhorro = "porcentaje";
      setAhorraMas(allPromotions.promotionDataPercent);

      const articulosIds = allPromotions.promotionDataPercent.giftCatentries.slice(0, 3);

      let stringIds = "?";
      articulosIds.map((art) => {
        stringIds = `${stringIds}id=${art}&`;
      });

      await axios
        .get<any>(`/search/resources/store/${mySite.storeID}/productview/byIds${stringIds}`)
        .then((response) => {
          if (response && response.status === 200 && response?.data?.catalogEntryView) {
            setArticulos(response.data.catalogEntryView);
          }
        })
        .catch(function (e) {
          console.log("another error happened:" + e.message);
        });
    }

    if (allPromotions?.promotionDataFixedAmount) {
      allPromotions.promotionDataFixedAmount.tipoAhorro = "monto";

      setAhorraMas(allPromotions.promotionDataFixedAmount);

      const articulosIds = allPromotions.promotionDataFixedAmount.giftCatentries.slice(0, 3);

      let stringIds = "?";
      articulosIds.map((art) => {
        stringIds = `${stringIds}id=${art}&`;
      });

      await axios
        .get<any>(`/search/resources/store/${mySite.storeID}/productview/byIds${stringIds}`)
        .then((response) => {
          if (response && response.status === 200 && response?.data?.catalogEntryView) {
            setArticulos(response.data.catalogEntryView);
          }
        })
        .catch(function (e) {
          console.log("another error happened:" + e.message);
        });
    }

    setinicializado(true);
  };

  if (!inicializado) return null;

  const promoSelected: any[] = [];

  if (orderRibbons?.includes("PROMOICON_GR")) {
    return (
      <>
        <Remate />
        <Divider style={{ color: palette.gray["50"], marginTop: "10px" }} />
      </>
    );
  }

  if (orderRibbons?.includes("PROMORIBBON_COMBO")) {
    promoSelected.push(<Combo />);
  }

  if (orderRibbons?.includes("PROMOICON_FP") && conRegalo) {
    //console.log("conRegalo => ", conRegalo)
    //console.log("product => ", product)
    promoSelected.push(
      <ProductoGratis
        articulos={conRegalo}
        product={product}
        partNumber={partNumber}
        promotionDesc={promotionDesc}
        baseQty={baseQty}
        giftQty={giftQty}
      />
    );
    orderRibbons = orderRibbons?.filter((orderRibb) => !orderRibb.includes("PROMORIBBON_NXM"));
  }

  const promoNxM = orderRibbons?.filter((orderRibb) => orderRibb.includes("PROMORIBBON_NXM"));

  if (promoNxM && promoNxM.length !== 0 && nXm) {
    promoNxM.map((promo) => {
      if (promo.includes(`PROMORIBBON_NXM_${nXm?.baseQty}BY`)) {
        return promoSelected.push(<NporM promoribbon={promo} nXm={nXm} urlRibbons={urlRibbons} />);
      }
    });
  }

  if (orderRibbons?.includes("PROMORIBBON_AHP") && precioMayoreo) {
    promoSelected.push(<PrecioMayoreo precioMayoreo={precioMayoreo} />);
  }

  if (orderRibbons?.includes("PROMOICON_CP") && ahorraMas) {
    promoSelected.push(
      <AhorraMasPromo
        brand={brand}
        attributes={attributes}
        partNumber={partNumber}
        shortDescription={shortDescription}
        nominalQuantity={nominalQuantity}
        ahorraMas={ahorraMas}
        articulos={articulos}
      />
    );
  }

  if (orderRibbons?.includes("PROMOICON_MSI")) {
    promoSelected.push(<MesesSinIntereses catentryData={catentryData} />);
  }

  return (
    <>
      {promoSelected &&
        promoSelected.map((promo, index) => {
          const isLast = index === promoSelected.length - 1;
          return (
            <StyledBox key={index} className={isLast ? "" : "bottom-margin-2"}>
              {promo}
              <Divider style={{ color: palette.gray["50"], marginTop: "10px" }} />
            </StyledBox>
          );
        })}
    </>
  );
};
